  <template>
      <div class="toast-container" id="toastAlert">
        <div :class="['toast show border-0', alertType ? 'text-bg-success' : 'text-bg-danger']" role="alert" ref="toast">
          <div class="d-flex px-4 py-3">
            <div class="toast-body fs-6 fst-italic">
              {{ message }}
            </div>
            <button type="button" class="btn-close me-2 m-auto shadow-none btn-close-white" @click="hideToast"></button>
          </div>
        </div>
      </div>
  </template>
  
  <script>
  export default {
    props: {
      message: String,
      alertType: Boolean
    },
    methods: {
      hideToast() {
        console.log(this.alertType);
        this.$emit("update:alertType", false);
        this.$emit("update:message", ""); // Очищаем сообщение
      },
      handleClickOutside(event) {
        if (!event.target.closest('.toast')) {
          this.hideToast();
        }
      }
    },
    mounted() {
      setTimeout(() => {
        document.addEventListener('click', this.handleClickOutside);
      }, 0);
      setTimeout(() => {
        this.hideToast();
      }, 3000);
    },
    beforeDestroy() {
      document.removeEventListener('click', this.handleClickOutside);
    },
  };
  </script>
  
  <style scoped>
  .toast {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
  }
  
  @media (max-width: 768px) {
    .toast {
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      max-height: fit-content;
  }
}
  </style>
  