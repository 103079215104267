import { defineStore } from 'pinia';
import { API_ADDRESS } from '@/config';

export const useDepositCreateStore = defineStore('depositCreate', {
  state: () => ({
    createdDeposit: null,
  }),
  actions: {
    async createDeposit(payment_method_id, amount) {
      try {
        const response = await fetch(`${API_ADDRESS}/operations/deposits`, {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
          },
          body: JSON.stringify({ payment_method_id, amount }),
          method: "POST",
        });
        const actualData = await response.json();
        if (response.ok) {
          this.createdDeposit = actualData.data;
        }
        else {
          if (response.status === 401) {
            eventBus.$emit('logout');
          } else {
          console.log('ошибка');
          const errors = actualData.errors || {};
          let errorData = false;
          for (const field in errors) {
            errorData = errors[field][0]; // сохраняем только первую ошибку
          }
          let message = errorData || actualData.message || 'Операция не удалась';
          eventBus.$emit('errorBus', message);
          this.createdDeposit = null;
        }
      }
      } catch (e) {
        console.error('Ошибка:', e);
      }
    },
  },
});
