<template>

  <div id="app">

    <alert :message.sync="message" :alertType.sync="alertType" v-if="message"></alert>
    
    <div v-if="isLoading" class="loader w-100 h-100 d-flex justify-content-center align-items-center position-fixed">
      <img width="100" height="100" src="/static/img/preloader.gif" alt="Loading...">
    </div>
    <!-- <AppSpinner /> -->
    <Header :user="user" @logout="logout" :v-if="!isLoading"/>
    <Courses :courses="courses" :v-if="!isLoading" v-if='this.$route.name !== "registration" && this.$route.name !== "login" && this.$route.name !== "recovery"'/>
    <router-view
      :v-if="!isLoading"
      @verify="verify"
      @del_wallet="delete_wallet"
      @login_user="login_user"
      :courses="courses"
      :user="user"
      :cards="cards"
      :wallets="wallets"
      :cards_count="cardsCount"
      :wallets_count="walletsCount"
      :user_load="onLoadUser"
      :balances="balances"
      :cryptos="crypto"
      :for_buy="forBuyCrypto"
      :for_deposit="forDeposit"
      :history="history"
      :invoice="invoice"
      :currencies="currencies"
      :courses_list="courses"
      :crypto="crypto"
      />
    <Footer v-if="this.$route.name !== 'registration' && this.$route.name !== 'recovery' && this.$route.name !== 'login'"/>
    <!-- <section class="account_menu" v-if="isAccountPage" :v-if="!isLoading">
      <div class='mobile-bottom-menu'>
          <a href="#MyActives">Активы</a>
          <a href="#conversion-id">Обмен</a>
          <a href="#cardsList">Карты</a>
          <a href="#WalletsList">Кошельки</a>
          <router-link to="/history">История</router-link>
      </div>
    </section> -->
    <EnterModal @login_user="login_user" />
    <RegistrationModal @login_user="login_user" />
    <Supportmodal/>

  </div>
</template>

<script>
import "./css/main.css";
import AppSpinner from "@/components/General/AppSpinner.vue";
import Courses from "@/components/CoursesComponent.vue";
import Header from "@/components/General/HeaderComponent.vue";
import Footer from "@/components/General/FooterComponent.vue";
import RegistrationModal from "@/components/Index/RegistrationComponent.vue";
import EnterModal from "@/components/Index//EnterComponent.vue";
import Supportmodal from '@/components/Support-modal.vue'
import Alert from "@/components/Alert.vue";
import {eventBus} from "@/eventBus";

export default {
  name: "App",
  components: {
    AppSpinner,
    Courses,
    Header,
    Footer,
    RegistrationModal,
    EnterModal,
    Supportmodal,
    Alert
  },

  data: () => ({
    isLoading: true,
    user: [],
    history: [],
    page: 1,
    forBuyCrypto: {
      transaction_id: 0,
      crypto_id: 0,
      amount: 0,
      status: "",
    },
    forDeposit: {
      deposit_id: 0,
      crypto_id: 0,
      amount: 0,
      status: "",
    },
    historyCount: 0,
    courses: [],
    cards: [],
    balances: [],
    crypto: [],
    invoice: "",
    cardsCount: 0,
    wallets: [],
    walletsCount: 0,
    onLoadCards: false,
    onLoadWallets: false,
    onLoadUser: false,
    onLoadCourses: false,
    onLoadHistory: false,
    message: false,
    alertType: false,
    currencies: [],
  }),
  methods: {
    initPopover() {
      this.$nextTick(() => {
        const tooltipElements = [].slice.call(this.$el.querySelectorAll('[data-bs-toggle="tooltip"]'));
        tooltipElements.forEach(el => new bootstrap.Tooltip(el, { container: 'body' }));
      });
    },
    login_user() {
      this.getInfo();
      this.$router.push("/account");
    },
    logout() {
      this.user = null;
      localStorage.clear();
      this.$router.push("/");
      document.body.style.overflow = 'auto';
      const CoursesBlock = document.getElementById('app');
      if (CoursesBlock) {
        CoursesBlock.scrollIntoView({ behavior: 'smooth' });
      }
},
    async getInfo() {
      this.isLoading = true;
      document.body.classList.add('no_scroll');
      this.$user_ID.value = localStorage.getItem('user_ID');
      this.$status_id.value = localStorage.getItem('status_id');
      this.$user_token.value = localStorage.getItem('user_token');
      // await this.getHistory(this.page);
      await this.getCourses();
      await this.getUser();
      eventBus.$emit('turnOnPusher', true);
      document.body.classList.remove('no_scroll');
      this.isLoading = false;
    },

    //getUser
    async getUser() {
      if (localStorage.getItem('user_ID')) {
        this.onLoadUser = true;
        await axios
          .get(this.$api_address + "/users/token", {
            headers: {
              "Authorization": `Bearer ${localStorage.getItem('user_token')}`,
            },
          })
          .then((response) => {
            this.user = response.data.data;
            this.$status_id.value = this.user.status.id;
            this.balances = response.data.data.balances;
            this.crypto = this.balances?.filter((c) => c.currency?.currency_type?.name === 'Cryptocurrency').sort((a, b) => a.id - b.id);
            this.cards = response.data.data.cards;
            this.cardsCount = this.cards.length;
            this.wallets = response.data.data.wallets;
            this.walletsCount = this.wallets.length;
          })
          .catch((error) => {
            console.error("Error fetching user:", error);
          }).finally(() => {
            this.onLoadUser = false;
          })
      }
    },

    //getCourses
    async getCourses() {
      this.onLoadCourses = true;
      await axios
        .get(this.$api_address + "/courses/service/1")
        .then((response) => {
            this.courses = response.data.data.sort((a, b) => a.id - b.id);;
        });
        this.onLoadCourses = false;
    },

    updCourses() {
      this.getCourses();
    },
 
  delete_wallet(index) {
    this.onLoadWallets = true;
    axios
    .delete(this.$api_address + "/wallets/"+this.wallets[index].id, {
      headers: {
          "Authorization": `Bearer ${localStorage.getItem('user_token')}`,
      },
      validateStatus: function (status) {
        return status < 500;
      },
    })
    .then((response) => {
      this.wallets.splice(index, 1);
      this.walletsCount--;
      this.onLoadWallets = false;
    })
    .catch((error) => {
      console.error(error);
      this.onLoadWallets = false;
    });
  },
    verify() {
      this.getUser();
    },
  },

  computed: {
    isAccountPage() {
        return this.$route.path === '/account';
    }
  },

  updated() {
    this.initPopover();
  },

  //created
  created() {
    this.$watch(
      () => this.$user_ID.value,
      (newValue) => {
        localStorage.setItem("user_ID", newValue);
      },
      () => this.$status_id.value,
      (newValue) => {
        localStorage.setItem("status_id", newValue);
      },
      () => this.$user_token.value,
      (newValue) => {
        localStorage.setItem("user_token", newValue);
      },
      () => this.$session_id.value,
      (newValue) => {
        localStorage.setItem("session_id", newValue);
      },

    );

    if(!localStorage.getItem('user_token') && this.$route.name !== 'invoice' && this.$route.name !== 'login' && this.$route.name !== 'registration' && this.$route.name !== 'recovery'
    && this.$route.name !== 'cash' && this.$route.name !== 'api' && this.$route.name !== 'invoices' && this.$route.name !== 'payment-link'  && this.$route.name !== 'contacts' && this.$route.name !== 'terms_of_use' && this.$route.name !== 'aml-kyc' && this.$route.name !== 'pay-link' && this.$route.name !== 'privacy-police' && this.$route.name !== 'cash-exchange') {
      this.logout();
    }

    if(localStorage.getItem('user_token')) {
      this.getInfo();
    } else {
      this.getCourses();
      setTimeout(() => {
        this.isLoading = false;
      }, 1500);
    }

    //Обновление баланса
    eventBus.$on("balanceUpdate", (data) => {
      if(data.userId == this.$user_ID.value) {
        this.getUser();
      }
    });

    eventBus.$on("logout", () => {
      this.logout();
    })

    eventBus.$on("coursesUpdate", (courses) => {
      this.getCourses();
    });

    //Добавление карты
    eventBus.$on("add_card", (data) => {
      this.onLoadCards = true;
      this.cards.push(data);
      this.cardsCount++;
      this.onLoadCards = false;
    });

    //Добавление кошелька
    eventBus.$on("add_wallet", (data) => {
      this.onLoadWallets = true;
      this.wallets.push(data);
      this.walletsCount++;
      this.onLoadWallets = false;
    });

    eventBus.$on("userUpdate", (data) => {
      if(!this.user) return
      if(data.id == this.user?.id) {
        if(data.status?.id == 5) {
          this.logout();
          return;
        }
        if(data.status?.id && data.status?.title) {
            this.user.status.id = data.status.id;
            this.user.status.title = data.status.title;
        }
      }
    });

    //Удаление карты
    eventBus.$on("card_delete", (cardId) => {
      this.onLoadCards = true;
      axios.delete(this.$api_address + "/cards/"+cardId, {
        headers: {
              "Authorization": `Bearer ${localStorage.getItem('user_token')}`,
          },
      validateStatus: function (status) {
        return status < 500; // Resolve only if the status code is less than 500
      }})
      .then((response) => {
        // Найти индекс карты с данным id в массиве cards
        const index = this.cards.findIndex((card) => card.id === cardId);
        if (index !== -1) {
          // Удалить карту из массива cards
          this.cards.splice(index, 1);
          this.cardsCount--;
        }
        this.onLoadCards = false;
      })
      .catch(function (error){
        if (error.response?.status !== 401) {
            eventBus.$emit('errorBus', 'Не удалось получить методы');
        }
        else {
            eventBus.$emit('logout');
        }
      });

    });

    eventBus.$on('errorBus', (msg, type) => {
      this.message = msg;
      this.alertType = type;
    });

  },
};
</script>

<style>
.account_menu {
    position: sticky;
    bottom:0;
    display: none;
    z-index:3;
    border-radius: 5px;
    -webkit-box-shadow: 0px -5px 15px -5px rgba(34, 60, 80, 0.6);
    -moz-box-shadow: 0px -5px 15px -5px rgba(34, 60, 80, 0.6);
    box-shadow: 0px -5px 15px -5px rgba(34, 60, 80, 0.6);
    overflow: hidden;
}
.account_menu .mobile-bottom-menu {
  display: grid;
  grid-gap: 1px;
  grid-template-columns: repeat(5, 1fr);
  background-color: black;
}
.account_menu .mobile-bottom-menu a {
    font-size: 11px;
    padding: 15px 5px;
    color: white;
    text-align: center;
    text-decoration: none;
    background-color: #4AA485;
}

@media(max-width:991px) {
    .account_menu {
        display: block;
    }
}
@media(max-width:320px) {
    .account_menu .mobile-bottom-menu {
        grid-template-columns: repeat(4, 1fr);
    }
    .account_menu .mobile-bottom-menu a:last-child {
        grid-column: 1 / -1;
    }
}
.no_scroll {
  overflow: hidden;
}
.loader {
  background:rgb(0, 0, 0);
  z-index: 999999;
}
</style>
