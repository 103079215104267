<template>
    <transition name="modal-fade">
        <div class="modal fade new_custom_modal" style="display: block; opacity: 1; background: rgba(29, 28, 43, 0.9)"
            aria-hidden="true" tabindex="-1">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">

                    <div class="modal-header flex-wrap">
                        <div class="d-flex justify-content-between align-items-center w-100">
                            <h5 class="modal-title w-100 fs-4">{{currentDepositMethod  ? 'Депозит ' + crypto.currency?.code : 'Этот депозит временно недоступен' }}</h5>
                            <button class="btn-close btn-close-white shadow-none z-index-2" type="button" data-bs-dismiss="modal"
                                aria-label="Close" @click="close"></button>
                        </div>
                    </div>

                    <template v-if="step == 1 && currentDepositMethod ">
                        <div class="modal-body">
                            <!-- <div class="error-block-text" v-show="errorText != ''">
                                <p>{{ errorText }}</p>
                            </div> -->

                            <div class="d-flex flex-wrap gap-lg-5 gap-3 align-items-center mb-5">
                                <div class="input-wrapper">
                                    <h3 class="mb-3 fs-4">Введите сумму депозита</h3>
                                    <div class="input-group mb-1">
                                        <input id="deposit_amount_input" v-model="amount" type="number" class="form-control shadow-none border-0" placeholder="Количество" aria-label="Сумма депозита">
                                        <span class="input-group-text bg-white border-0" id="basic-addon2">{{crypto.currency?.code}}</span>
                                      </div>
                                </div>
                                <balance-card :crypto="crypto"></balance-card>
                            </div>
                            <h3 class="mb-4 fs-4">Выберите сеть кошелька</h3>
                            <methods-for-pop-up :methods="methods" :method.sync="method" :crypto="crypto" :action="'Депозит'"></methods-for-pop-up>
                        </div>
                        <div class="rounded bg-white p-4 d-flex justify-content-between gap-3 text-dark flex-wrap">
                            <div class="mb-4 mb-md-0">
                                <span class="badge u-bg-secondary fs-6 p-2 text-wrap text-center">
                                    Комиссия платежной системы: 0%
                                </span>
                            </div>
                            <div class="d-flex flex-column justify-content-between gap-lg-5 gap-3 button_wrapper">
                                <div>
                                    <h6 class="d-block text-dark fs-5 fw-bold mb-2">
                                        Сумма депозита
                                    </h6>
                                    <span class="amount fs-1 fw-bold text-break">
                                        {{ amount || 0 }} {{ method?.currency_to?.code }}
                                    </span>
                                </div>
                                <div>
                                    <button class="button mw-100" @click="sendDeposit()" :disabled="!method ||
                                        !amount ||
                                        amount < minAmount ||
                                        amount > maxAmount
                                        ">
                                        Получить адрес кошелька
                                    </button>
                                </div>
                            </div>

                        </div>
                    </template>

                    <div class="modal-body" v-if="step == 2 || (step == 3 && deposit.status == 'Подтвержден')">
                        <div class="mb-2 text-center">
                            Сумма депозита: {{ amount }} {{ crypto.currency?.code }}
                        </div>
                        <div class="custom-modal__text mb-4 d-flex justify-content-center align-items-center">
                            <qr-code :text="deposit.wallet.wallet"></qr-code>
                        </div>
                        <div v-if="step == 3 && deposit.status == 'Подтвержден'" class="custom-modal__text mb-2"
                            style="text-align: left">
                            <h3 class="mb-2">Ваш депозит подтвержден</h3>
                            <p>
                                Пока депозит обрабатывается вы можете совершать другие операции
                            </p>
                        </div>
                        <div v-else>
                            <div class="custom-modal__text mb-4" @click="copyWallet">
                                <span class="for-cut">{{ deposit.wallet.wallet }}</span>
                                <img style="cursor: pointer" class="ms-2 js-copy" src="/img/ico/ico-copy.svg" />
                            </div>
                            <input @change="sentProof" id="js-file-1" @input="addChek" class="custom-file__input d-none" type="file" name="uploadFile" />
                            <label for="js-file-1" class="button button--full-width mb-3">
                                <img src="/img/ico/ico-upload.svg" alt="upload" class="me-1" />
                                <span>Подтвердить депозит</span>
                            </label>
                            <span @click="changeNetwork" class="fs-4 light_link underline-offset mb-5 d-block text-center">
                                Получить кошелек в другой сети
                            </span>
                            <div class="custom-modal__text mb-2">
                                Обратите внимание, данный кошелек не принадлежит Вам. При каждом
                                депозите необходимо получать новый адрес кошелька.
                            </div>
                            <div class="custom-modal__text d-flex align-items-center justify-content-center">
                                Время на совершение депозита:
                                <span class="d-flex align-items-center gap-1 ms-1"><img
                                        src="/img/ico/ico-time.svg" /><span class="time">{{
                                            formattedTime
                                        }}</span></span>
                            </div>
                        </div>
                    </div>

                    <div class="modal-body" v-if="step == 3 && deposit.status == 'Успех'">
                        <div style="text-align: left; margin-bottom: 20px">
                            <span class="me-3" style="font-size: 16px">Депозит на {{ this.actual_amount }} {{
                                crypto.currency?.code }}
                                совершен
                                успешно</span>
                        </div>
                        <button class="button button--full-width mb-3" @click="lastStep()">
                            Новый депозит
                        </button>
                    </div>

                    <div class="modal-body" v-if="step == 3 && deposit.status == 'Отмена'">
                        <div style="text-align: left; margin-bottom: 20px">
                            <span class="me-3" style="font-size: 16px">Депозит на {{ this.amount }} {{
                                crypto.currency?.code }}
                                завершился
                                неудачей</span>
                        </div>
                        <button class="button button--full-width mb-3" @click="lastStep()">
                            Новый депозит
                        </button>
                    </div>
                    <div class="position-fixed w-100 h-100 d-flex justify-content-center align-items-center bg-white bg-opacity-50 rounded" v-if="loading">
                        <div class="spinner-border text-success" role="status">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useDepositCreateStore } from '@/stores/deposit/useDepositCreateStore';
import { useDepositDeleteStore } from '@/stores/deposit/useDepositDeleteStore';
import MethodsForPopUp from "../MethodsForPopUp.vue";
import BalanceCard from "../BalanceCard.vue";

export default {
    components: { MethodsForPopUp, BalanceCard },
    props: ["balance", "cards", "courses", "crypto", "methods", 'course', 'myCourse'],
    data() {
        return {
            step: 1,
            amount: null,
            wallets: [],
            status: "",
            proof: "",
            deposit_id: 0,
            remainingTime: 30 * 60, // 30 минут в секундах
            intervalId: null,
            maxSize: 5242880,
            maxAmount: 10000000,
            minAmount: 0,
            method: false,
            loading: false,
            deposit: [],
            actual_amount: 0
        };
    },
    computed: {
        ...mapState(useDepositCreateStore, ['createdDeposit']),
        ...mapState(useDepositDeleteStore, ['deletedDeposit']),
        formattedTime() {
            const minutes = Math.floor(this.remainingTime / 60);
            const seconds = this.remainingTime % 60;
            return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
        },
        currentDepositMethod() {
            return this.methods.find(d => d.payment_operation?.name === 'Депозит' && d.currency_from?.code === this.crypto?.currency?.code);
        },
        cryptoCourse() {
            return this.crypto?.currency?.code === 'USDT' ? (this.amount || 0) : parseInt((this.amount || 0) * this.myCourse) / this.course?.parser?.currency_to?.delimiter;
        },
        // fixedNumber() {
        //     return (this.method?.currency_from?.delimiter).toString().split('0').length - 1;
        // },
    },

    watch: {
        amount() {
            if (this.amount === "") return;
            if (this.amount > this.maxAmount) this.amount = this.maxAmount;
            if (this.amount < 0) this.amount = 0;
        },
    },

    methods: {
        ...mapActions(useDepositCreateStore, ['createDeposit']),
        ...mapActions(useDepositDeleteStore, ['deleteDeposit']),

        // putMethod(data) {
        //     this.method = data.data;
        // },

        addChek(event) {
            if (
                (event.target.files[0].type == "image/png" ||
                    event.target.files[0].type == "image/jpeg") &&
                    event.target
                    .files[0].size < this.maxSize
            ) {
                this.proof = event.target.files[0];
            } else {
                    eventBus.$emit('errorBus', 'Неверный формат (нужен jpeg или png) или размер файла превышает 5Мб');
                this.proof = "";
                event.target.value = "";
            }
        },

        async sendDeposit() {
            console.log('this.cryptoCourse', this.cryptoCourse);
            if(this.cryptoCourse && this.cryptoCourse > 50000) {
                eventBus.$emit('errorBus', 'Слишком большая сумма, максимум это 50 000$ в эквиваленте');
                return;
            }
            this.loading = true;
            await this.createDeposit(this.method.id, this.amount);
            if (this.createdDeposit) {
                this.deposit = this.createdDeposit;
                this.step = 2;
                this.$emit("deposit");
            }
            setTimeout(() => (this.loading = false), 10);
        },

        startTimer() {
            if (!this.intervalId) {
                this.intervalId = setInterval(() => {
                    if (this.remainingTime > 0) {
                        this.remainingTime--;
                    } else {
                        clearInterval(this.intervalId);
                        this.intervalId = null;
                        this.step = 3;
                        this.status = "Отмена";
                    }
                }, 1000);
            }
        },

        close(event) {
            this.$emit("close");
        },
        sale(data) {
            this.$emit("concl", data);
        },
        copyWallet() {
            navigator.clipboard.writeText(this.wallet.wallet);
        },
        sentProof() {
            if (!this.proof) {
                eventBus.$emit('errorBus', "Загрузите документ");
                return;
            };
            let formData = new FormData();
            formData.append("proof", this.proof);
            formData.append("_method", "PATCH");
            axios
                .post(
                    this.$api_address + "/operations/deposits/" + this.deposit.id + "/proof",
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
                        },
                    }
                )
                .then((response) => {
                    this.deposit = response.data.data;
                    this.step = 3;
                    this.$emit("deposit");
                });
        },
        async changeNetwork() {
            this.loading = true;
            await this.deleteDeposit(this.deposit.id);
            console.log('1',this.deletedDeposit);
            if (this.deletedDeposit) {
                console.log(this.deletedDeposit);
                this.$emit("deposit");
                this.step = 1;
            }
            this.remainingTime = 30 * 60;
            this.loading = false;
        },
        lastStep() {
            Object.assign(this, this.$options.data());
            this.$emit("new_deposit");
        },
    },
    created() {
        eventBus.$on("depositUpdate", (pusherInfo) => {
            if (this.deposit?.id == pusherInfo.id) {
                this.step = 3;
                this.deposit.status = pusherInfo.status;
                this.actual_amount = pusherInfo.actual_amount / this.method?.currency_from?.delimiter;
            }
        });
    },

};
</script>

<style scoped>
.time {
    width: 40px;
}
</style>
