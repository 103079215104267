<template>
    <div class="custom-modal__select-card mb-4 grid-cards">
        <div class='cardElement' :key="index" v-for="(data, index) in filterdWallets">
            <input class='bankListElement' type='radio' :id='crypto.currency.code + "wallet" + data.id' :checked="data.id === method?.id">
            <label :class="{'cardLabel': ((index + 1) % 4 != 0)}" style=" font-size: 10px;" :for='crypto.currency.code + "wallet" + data.id' @click="putMethod(data)">
                <div class="custom-modal__select-card-wrapper ">
                    <div class="custom-modal__select-card-container">
                    <img class="custom-modal__select-card-ico" :src="imgTitle(data)" width="16px" height="16px" />
                        <div class="custom-modal__select-card-right">
                            <span>{{ data.cryptonetwork?.name }}</span>
                            <span>{{ data.description }}</span>
                        </div>
                    </div>
                </div>
            </label>
        </div>
        <div class="custom-modal__select-card-wrapper">
            <div @click="newWallet" class="custom-modal__select-card-container align-items-center d-flex justify-content-center">
                <span id="addWallet-btn">+ Добавить кошелек</span>
            </div>
        </div>
    </div>
</template>

<script>
import noImg from '/public/img/banks/no_title.png';

export default {
    props: ['wallets', 'crypto', 'method'],
    filters: {
        format: val => `${val}`.replace(/(\d)(?=(\d{4})+([^\d]|$))/g, '$1 '),
    },
    computed: {
        filterdWallets() {
            return this.wallets.filter(w => w.cryptonetwork.cryptocurrency.code === this.crypto.currency.code);
        }
    },
    methods: {
        wallet_delete(data){
            this.$emit('wallet_delete', data)
        },
        newWallet(){
            this.$emit('new_wallet');
        },
        putMethod(data) {
            this.$emit("update:method", data);
        },
        imgTitle(data) {
            if (!data.cryptonetwork?.cryptocurrency?.name) return noImg;

            try {
                return require(`/public/img/ico/ico-${data?.cryptonetwork?.cryptocurrency?.name.toLowerCase()}--color.png`);
            } catch (e) {
                return noImg; // Если файла нет, используем заглушку
            }
        },
        initMethodIfNeeded(methods) {
            if (!this.method && methods.length) {
                this.putMethod(methods[0]);
            }
        },
    },
    watch: {
        filterdWallets(newVal) {
            this.initMethodIfNeeded(newVal);
        },
        method: {
            immediate: true,
            handler() {
                this.initMethodIfNeeded(this.filterdWallets);
            }
        }
    }
}
</script>

<style scoped>
#addWallet-btn {
    font-size:12px;
    color: #3EA075;
    text-align: center;
}

</style>

