<template>
    <fragment>
        <div class="row mt-4 mb-3">
            <div class="col d-flex align-items-end">
                <h3 class="mb-0">Вы оплачиваете счет №{{invoice.user_id}}-{{invoice.id}}</h3>
            </div>
        </div>

        <div class="row mb-2">
            <div class="row mb-2">
                <div class="col-12 col-sm-3">
                    <span>Описание</span>
                </div>
                <div class="col-12 col-sm-6">
                    <div class="col">
                        <span>{{invoice.description}}</span>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-3">
                <span>Способ оплаты</span>
            </div>
            <div class="col-12 col-sm-6">
                <div class="col d-flex align-items-center">
                    <img v-if="selected_method.logo != null || selected_method.logo != undefined" :src="selected_method.logo" width="100px">
                    <img v-if="selected_method.logo == null || selected_method.logo == undefined" :src="'/img/ico/ico-' + crypto.find(c => c.id == selected_method.crypto_id).title.toLowerCase() + '--color.png'" width="20px">
                    <span v-if="selected_method.logo == null || selected_method.logo == undefined">{{crypto.find(c => c.id == selected_method.crypto_id).code}}</span>
                    <span class="ms-3" style="font-size: 14px; font-weight: 100" v-if="selected_method.logo == null || selected_method.logo == undefined">{{selected_method.title}}</span>
                    <span v-if="step != 3" @click="changeMethod" class="text-decoration-underline color-ACAFFF ms-3 cursor-pointer">Изменить способ оплаты</span>
                </div>
            </div>
        </div>
        <div class="row mb-4">
            <div v-if="!payment.currency_amount && !payment.proposed_amount" class="col-12 col-sm-9 background-2E366D py-3 brs-5">
                <span>Данный способ оплаты на данный момент недоступен, попробуйте другой или повторите попытку</span>
            </div>
            <div v-else class="col-12 col-sm-9 background-2E366D pt-3 brs-5">
                <span class="fw-bold">Реквизиты для оплаты</span>
                <div class="position-relative">
                    <div class="clock_block">
                        <img src="/static/img/ico/ico-clock.svg">
                        <span class="ms-2">{{minutes}}:{{seconds}}</span>
                    </div>
                </div>
                <div class="row pt-4">
                    <div class="col-12 u-font-size-14 col-sm-4 fz-20">
                        <span>Сумма к оплате</span>
                    </div>
                    <div class="col-12 col-sm-8 d-flex align-items-center">
                        <span v-if="!this.selected_method.crypto_id">{{payment.currency_amount}} {{!this.selected_method.crypto_id ? currency : invoice.currency}}</span>

                        <span v-if="this.selected_method.crypto_id">{{parseFloat(payment.proposed_amount).toFixed(crypto.find(c => c.id == selected_method.crypto_id).count_after_point)}} {{crypto.find(c => c.id == selected_method.crypto_id).code}}</span>

                        <span class="ms-2 copy" @click="copyCard(!selected_method.crypto_id ? payment.currency_amount : parseFloat(payment.proposed_amount).toFixed(crypto.find(c => c.id == selected_method.crypto_id).count_after_point))">
                            <img src="/img/ico/ico-copy.svg" width="12" height="15"> <span @click="changeText">Скопировать</span>
                        </span>
                    </div>
                </div>
                <div class="row pt-2">
                    <div class="col-12 u-font-size-14 col-sm-4 fz-20">
                        <span v-if="!this.selected_method.crypto_id">Номер карты</span>
                        <span v-else>Номер кошелька</span>
                    </div>
                    <div class="col-12 col-sm-8 d-flex align-items-center">
                        <span v-if="!this.selected_method.crypto_id">{{payment.card_number}}</span>
                        <span v-else>{{selected_method.wallet.length > 20 ? selected_method.wallet.substr(0,16) + '...' + selected_method.wallet.substring(selected_method.wallet.length - 4) : selected_method.wallet}}</span>
                        <span @click="copyCard(!selected_method.crypto_id ? payment.card_number : selected_method.wallet)" class="ms-2 copy">
                            <img src="/img/ico/ico-copy.svg" width="12" height="15"> <span @click="changeText">Скопировать</span>
                        </span>
                    </div>
                </div>
                <div v-if="!this.selected_method.crypto_id" class="row pt-2">
                    <div class="col-12 u-font-size-14 col-sm-4 fz-20">
                        <span>Получатель</span>
                    </div>
                    <div class="col-12 col-sm-8 d-flex align-items-center">
                        <span>{{payment.card_owner}}</span>
                    </div>
                </div>
                <div class="row pt-2">
                    <div class="col-12 u-font-size-12">
                        <span v-if="step == 2">Совершите перевод по указаным реквизитам и подтвердите перевод. Ваш платеж будет зачислен в течении 1-2 минут</span>
                        <span v-else>Проверяем Ваш платеж. Если подтверждение идет долго, загрузите чек перевода.</span>
                    </div>
                </div>
                <div class="row pt-2 pb-3">
                    <div class="col-12">
                        <button v-if="step == 2" @click="confirm" class="button button--full-width">Я совершил платеж</button>
                        <div v-else class='custom-file'>
                            <input id='js-file-1' style='cursor: pointer' slot='' @input="addFile" class='image-file js-file custom-file__input' type='file' name='uploadFile'/>
                            <label class='custom-file__label' for='js-file-1'>
                                <i class='custom-file__icon'></i>
                                <span class='custom-file__text'>
                                    <img src="/img/ico/ico-upload.svg" width="20" height="20" class="me-2">
                                    {{file == '' ? 'Загрузить чек перевода (jpeg, png до 5мб)' : file.name}}
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
                <div v-if="this.selected_method.crypto_id && step == 3" class="row pt-2 pb-3">
                    <div class="col-12 col-sm-6">
                        Хэш транзакции
                        <div class="d-flex align-items-center">
                            <input class="inputAmount w-100 mt-0" type="text" v-model="hash">
                            <button @click="sentHash" class="button ms-3" style="height: 40px; width:60px;">ОК</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </fragment>
</template>

<script>

export default {
    props: ['invoice', 'crypto', 'crypto_id', 'selected_method', "payment", 'step', 'country', 'minutes', 'seconds'],
    data () {
        return {
            file: '',
            hash: '',
            maxSize: 5242880,
        }
    },
    computed: {
        currency : function () {
            if(this.country == 'kz') {
                return 'KZT'
            } else {
                if(this.country == 'uz') {
                    return 'UZS'
                } else {
                    return 'RUB'
                }
            }
        },
    },
    methods: {
        changeMethod(){
            this.$emit('change_method');
        },
        confirm() {
            this.$emit('confirm_payment');
            //Записываем в storage номер карты/кошелька
            localStorage.setItem('cardOrWalletStorage', !this.selected_method.crypto_id ? this.payment.card_number : (this.selected_method.wallet.length > 20 ? this.selected_method.wallet.substr(0,16) + '...' + this.selected_method.wallet.substring(this.selected_method.wallet.length - 4) : this.selected_method.wallet));
            //записываем в storage владельца карты/кошелька
            localStorage.setItem('ownerStorage', !this.selected_method.crypto_id ? this.payment.card_owner : null);
        },
        addFile(event){
            if((event.target.files[0].type == 'image/png' || event.target.files[0].type == 'image/jpeg' /*|| event.target.files[0].type == 'application/pdf'*/)
                && event.target.files[0].size < this.maxSize){
                this.file = event.target.files[0];
                
                let formData = new FormData();
                formData.append("proof", this.file);
                formData.append("token", this.invoice.token);
                formData.append("_method", 'PATCH');
                if(this.selected_method.crypto_id) {

                    axios.post(this.$api_address + '/deposits/' + this.payment.id + '/proof', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                        this.payment = response.data.data;
                        alert('Файл успешно отправлен');
                    });
                } else {
                    axios.post(this.$api_address + "/transactions/" + this.payment.id + '/proof', formData,{
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                        this.payment = response.data.data;
                        alert('Файл успешно отправлен');
                    });
                }


            } else {
                alert('Неверный формат или размер файла превышает 5Мб');
                this.file = '';
            }
        },
        sentHash() {
            let formData = new FormData();
            formData.append("hash", this.hash);
            formData.append("token", this.invoice.token);
            formData.append("_method", 'PATCH');
            axios.post(this.$api_address + '/deposits/' + this.payment.id + '/proof', formData)
            .then(response => {
                alert('Хэш успешно отправлен');
            });
        },
        copyCard(info) {
            navigator.clipboard.writeText(info);
        },
        changeText(event) {
            const originalText = event.target.textContent;
            event.target.textContent = 'Скопировано!';
            setTimeout(() => {
                event.target.textContent = originalText;
            }, 1000);
        }
    }
}
</script>

<style scoped>
 .clock_block {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 80px;
    display: flex;
    align-items: center;
 }
 .copy {
    cursor: pointer;
    font-size: 14px;
    font-weight:300;
    display: flex;
    align-items: center;
    gap: 5px;
 }
 .brs-5 {
    border-radius: 5px;
 }
 .fz-20 {
    font-size: 20px;
 }
 .custom-file__text {
    text-decoration: underline;
    text-underline-offset: 5px;
 }
</style>
