import { defineStore } from 'pinia';
import { API_ADDRESS } from '@/config';

export const useCardMethodsStore = defineStore('card_methods', {
  state: () => ({
    card_methods: [],
  }),
  actions: {
    async fetchCardMethods() {
      try {
        const response = await fetch(`${API_ADDRESS}/card_methods`, {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
          },
          method: "GET",
        });
        if (response.ok) {
          const actualData = await response.json();
          this.card_methods = actualData.data;
        }
        else {
          // alert('Не удалась получить банки');
          console.log('Не удалась получить методы карт');
        }
      } catch (e) {
        console.error('Ошибка:', e);
      }
    },
  },
});
