<template>
  <transition name="modal-fade">
    <div class="modal fade new_custom_modal" style="display: block; opacity: 1; background: rgba(29, 28, 43, 0.9)"
      id="addCard" aria-hidden="true" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content w-500">
          <div class="modal-header">
            <h5 class="modal-title">Добавить карту</h5>
            <button class="btn-close btn-close-white shadow-none" type="button" data-bs-dismiss="modal"
              aria-label="Close" @click="close"></button>
          </div>
          <div class="modal-body">
              <div class="mb-3">
                <label class="form-label">Регион выпуска карты</label>
                <select class="form-select shadow-none border-0" aria-label="Выбор страны" v-model="country" id="cards_country_selector" @change="bank = false">
                  <option v-for="(item, index) in regions" :key="index" :value="item">
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <template v-if="country">
                <div class="mb-3" v-if="country.code !== 'kz' && filteredMethods.length !== 0">
                  <label class="form-label">Метод</label>
                  <div class='radio-wrapper'>
                    <div :key="index" v-for="(data, index) in filteredMethods">
                        <label  class='control control-radio'>
                            <span>{{ data.name }}</span>
                            <input :id="data.name+'_id'" type='radio' v-model='method' name='radio' :value="data"/>
                            <div class='control_indicator'></div>
                        </label>
                    </div>
                </div>
                </div>
                <div class="mb-3" v-if="filteredBanks.length !== 0">
                  <label class="form-label required">{{ country.code === 'uz' || country.code === 'eu' ? 'Платёжная система' : 'Банк' }}</label>
                  <select class="form-select shadow-none border-0" v-model="bank" id="cards_bank_selector">
                    <option v-for="(item, index) in filteredBanks" :key="index" :value="item">
                      {{ item.bank_name }}
                    </option>
                  </select>
                </div>
                <div class="mb-3">
                  <label class="form-label required" for="cards_requisites_field">
                    <span>
                      {{
                        method?.name === 'По номеру карты' || country.code === 'kz'
                          ? 'Номер карты'
                          : method?.name === 'СБП'
                            ? 'Номер телефона'
                            : 'Реквизиты'
                      }}
                    </span>
                    <img src="/img/ico/required.svg" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-html="true" v-if="country.code !== 'uz'"
                    title="<p>Для платёжных систем HUMO, UZCARD и VISA вбейте номер карты</p><p>Для CLICK номер карты или номер кошелька</p>">
                    <img src="/img/ico/required.svg" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-html="true" v-if="bank?.bank_name == 'PayPal'"
                    title="Введите email к которому привязан PayPal">
                  </label>
                  <input class="form-control shadow-none border-0" type="text" v-model="requisites" v-mask="mask" id="cards_requisites_field" />
                </div>
                <div class="mb-3">
                  <label class="form-label" for="cards_fio_field">
                    <span>ФИО получателя</span>
                    <img src="/img/ico/required.svg" data-bs-toggle="tooltip" data-bs-placement="top" title="Укажите ФИО держателя карты">
                  </label>
                  <input class="form-control shadow-none border-0" type="text" v-model="fio" id="cards_fio_field" />
                </div>
                <div class="mb-3">
                  <label class="form-label" for="cards_description_field">
                    <span>Описание</span>
                    <img src="/img/ico/required.svg" data-bs-toggle="tooltip" data-bs-placement="top" title="Поле для дополнительной информации о карте">
                  </label>
                  <input class="form-control shadow-none border-0" type="text" v-model="description" id="cards_description_field" />
                </div>
                <div class="mb-3" v-if="bank?.bank_name === 'IBAN'">
                  <label for="cards_comment_field" class="form-label">
                    <span>Комментарий к платежу</span>
                    <img src="/img/ico/required.svg" data-bs-toggle="tooltip" data-bs-placement="top" title="Напишите все дополнительные поля для совершения платежа">
                  </label>
                  <textarea class="form-control" id="cards_comment_field" rows="3"></textarea>
                </div>
              </template>

            <button class="button button--full-width mb-3 mt-4" @click="add" :disabled="!requisites || !fio || !description">
              Добавить карту
            </button>
            <div class="error-block-text" v-if="error_text">
              <p>{{ error_text }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Card from "./Card.vue";
import { mapState, mapActions } from 'pinia';
import { useBanksStore } from '@/stores/cards/useBanksStore';
import { useRegionsStore } from '@/stores/cards/useRegionsStore';
import { useCardMethodsStore } from '@/stores/cards/useCardMethodsStore';

export default {
  components: {
    Card,
  },
  data() {
    return {
      country: false,
      method: false,
      // filtered_methods: null,
      filtered_banks: null,
      bank: null,
      description: "",
      error_text: "",
      click: false,
      id: "",
      fio: "",
      requisites: "",
      loading: false,
    };
  },
  computed: {
    ...mapState(useBanksStore, ['banks']),
    ...mapState(useRegionsStore, ['regions']),
    ...mapState(useCardMethodsStore, ['card_methods']),
    filteredMethods(){
      return this.card_methods?.filter(m => m.region?.id === this.country?.id) || [];
    },
    filteredBanks(){
      return this.banks?.filter(b => b.region?.id === this.country?.id) || [];
    },
    mask() {
          return (['uz', 'eu', 'kz'].includes(this.country.code) || this.method?.name === 'По номеру карты') ? "#### #### #### ####" : "+#(###)###-##-##";
    },
  },
  watch: {
    country: {
      immediate: true,
      handler(newVal) {
        if (newVal?.code === 'ru' && !this.method) {
          // const methods = this.card_methods?.filter(m => m.region?.id === newVal.id) || [];
          if (this.filteredMethods.length) {
            this.method = this.filteredMethods[0];
          }
        } else {
          this.method = false;
        }
      }
    }
  },
  methods: {
    ...mapActions(useBanksStore, ['fetchBanks']),
    ...mapActions(useRegionsStore, ['fetchRegions']),
    ...mapActions(useCardMethodsStore, ['fetchCardMethods']),
    close() {
      this.$emit("close");
      setTimeout(() => (Object.assign(this, this.$options.data())), 500);
    },
    setErrors(response) {
            const errors = response?.data?.errors || {};
            let message;
            for (const field in errors) {
                message = errors[field][0];
            }
            let text = message || response?.data?.message || 'Что-то пошло не так';
            eventBus.$emit('errorBus', text);
    },
    initPopover() {
      this.$nextTick(() => {
        const tooltipElements = [].slice.call(this.$el.querySelectorAll('[data-bs-toggle="tooltip"]'));
        tooltipElements.forEach(el => new bootstrap.Tooltip(el, { container: 'body' }));
      });
    },
    async add() {
      this.loading = true;
      let data = {
        bank_id: this.bank?.id,
        description: this.description,
        holders_name: this.fio,
        requisites: this.requisites
      };
      if(this.method){
        data.card_method_id = this.method.id
      }
      await axios
        .post(this.$api_address + "/cards", data, {
          headers: {
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`,
          },
        })
        .then((response) => {
            if (this.$metrika) {
              this.$metrika.reachGoal("adding_card_success");
            }
            eventBus.$emit("add_card", response.data);
            this.close();
        })
        .catch((error) => {
          if (error.response?.status !== 401) {
            this.setErrors(error.response);
            this.click = false;
          } else {
              eventBus.$emit('logout');
          }
        }).finally(() => {
            this.loading = false;
        });
    },
  },
  updated() {
    this.initPopover();
  },
  created() {
    this.fetchRegions();
    this.fetchBanks();
    this.fetchCardMethods();
  },
};
</script>

<style scoped>
.form-label {
  display: flex;
  gap: 5px;
  align-items: center;
}
/*.form-label.required::after {
  content: "";
  display: block;
  width: 11px;
  height: 11px;
  background: url(/public/img/ico/required.svg) no-repeat;
  background-size: contain;
}*/
.control {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  padding-top: 0;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 16px;
}
.radio-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 30px;
  row-gap: 12px;
  flex-wrap: wrap;
}

</style>
