import { defineStore } from 'pinia';
import { API_ADDRESS } from '@/config';

export const useDepositDeleteStore = defineStore('depositDelete', {
  state: () => ({
    deletedDeposit: false,
  }),
  actions: {
    async deleteDeposit(deposit) {
      try {
        const response = await fetch(`${API_ADDRESS}/operations/deposits/${deposit}`, {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
          },
          method: "DELETE",
        });
        const actualData = await response.json();
        if (response.ok) {
          this.deletedDeposit = true;
          console.log(this.deletedDeposit);

        } else {
          if (response.status === 401) {
            eventBus.$emit('logout');
          } else {
            this.deletedDeposit = false;
            const errors = actualData.errors || {};
            let errorData = false;
            for (const field in errors) {
              errorData = errors[field][0];
            }
            let message = errorData || actualData.message || 'Операция не удалась';
            eventBus.$emit('errorBus', message);
          }
        }
      } catch (e) {
        console.error('Ошибка:', e);
      }
    },
  },
});
