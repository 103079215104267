<template>
    <fragment>
        <div class="modalCards mb-3">
            <div @click="putMethod(data)" v-for="data in cards" :key="data.id" class="custom-modal__select-card-wrapper"
             :class="data.id == method.id ? 'custom-modal__select-card-wrapper--selected' : ''">
                <div class="custom-modal__select-card-container justify-content-start flex-column">
                    <div class="d-flex align-items-start">
                        <img class="custom-modal__select-card-ico" :src="imgTitle(data)" width="16px" style="object-fit: contain;" />
                        <div class="custom-modal__select-card-right">
                          <span style="font-size: 10px;">{{ '**** ' + data?.requisites?.substr(data.requisites.length - 4) }}</span>
                        </div>
                    </div>
                    <span class='o-text-small mt-auto' v-if="data.bank.bank_name">
                        {{data?.card_method?.name == 'СБП' ? data.card_method.name+' / ' : ''}} 
                        {{ data.bank.bank_name }}
                    </span>
                </div>
            </div>
            <div class="custom-modal__select-card-wrapper">
                <div @click="newCard"
                    class="custom-modal__select-card-container align-items-center d-flex justify-content-center">
                    <span style="font-size: 12px; color: #3EA075;">+ Добавить карту</span>
                </div>
            </div>
        </div>
    </fragment>
</template>

<script>
import noImg from '/public/img/banks/no_title.png';
export default {
    props: ['cards', 'method',],
    methods: {
        newCard() {
            this.$emit('new_card');
        },
        imgTitle(data) {
            if (!data?.bank?.region?.code) return noImg;

            try {
                return require(`/public/img/banks/flag/${data.bank.region.code.toUpperCase()}.svg`);
            } catch (e) {
                return noImg;
            }
        },
        putMethod(data) {
            this.$emit("update:method", data);
        },
        initMethodIfNeeded(methods) {
            if (!this.method && methods.length) {
                this.putMethod(methods[0]);
            }
        },
    },

    watch: {
        cards(newVal) {
            this.initMethodIfNeeded(newVal);
        },
        method: {
            immediate: true,
            handler() {
                this.initMethodIfNeeded(this.cards);
            }
        }
    }

}
</script>

<style scoped>
.modalCards {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(5, 1fr);
}

@media(max-width:992px) {
    .modalCards {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media(max-width:428px) {
    .modalCards {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media(max-width:300px) {
    .modalCards {
        grid-template-columns: repeat(1, 1fr);
    }
}
</style>


