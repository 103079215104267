<template>
  <main class="d-flex flex-column flex-grow-1" id='tech'>
    <div class="maintenance-container" >
      <video autoplay muted loop playsinline class="video-background">
        <source src="@/assets/gears.webm" type="video/webm" />
        <source src="@/assets/gears.mp4" type="video/mp4" />
        Ваш браузер не поддерживает видео.
      </video>

      <div class="overlay">

        <div class="container">
          <picture>
            <source media="(min-width:426px)" srcset="/static/img/logo.svg">
            <img src="/static/img/mobile_logo.png" alt="logo" class="mb-4 position-relative z-3 pt-4">
          </picture>

          <div class="row mygap">
  
            <div class="col-10 col-lg-5">
              <h1>Ведутся технические работы</h1>              
            </div>
            <div class="col-12 col-lg-5 ms-auto">

              <div class="">
                <div class="sub">
                  Если возникли вопросы обратитесь в 
                  <a class="u-color-pall" target="_blank" href="https://t.me/valutix_bot">поддержку 
                    <img class="ms-2" src="/static/img/logos_telegram.svg">
                  </a>
                </div>

                <div class="sub">
                  Для проведения операций с наличными напишите нашему 
                  <a target="_blank" class="u-color-pall" href="https://t.me/Valutix_manager">конcьержу 
                    <img class="ms-2" src="/static/img/logos_telegram.svg">
                  </a>
                </div>

                <div class="sub">
                  Следите за новостями в нашем 
                  <a class="u-color-pall" target="_blank" href="https://t.me/valutix">канале 
                    <img class="ms-2" src="/static/img/logos_telegram.svg">
                  </a>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "404-teh",
};
</script>

<style>
#app *:not(#tech):not(#tech *) {
  display: none !important;
}
.maintenance-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.video-background {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  z-index: 0;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  background-image: url(/public/static/img/gradient-teh-page.png);
  background-repeat: no-repeat;
  background-position: center, right bottom;
  background-size: cover, clamp(400px, 50vw, 1000px);
  z-index: 1;

}

.mygap {
  padding-top: 100px;
  padding-bottom: 100px;
}

.button {
  max-width: fit-content;
  padding-right: 40px;
  padding-left: 40px;
}
h1 {
  margin-bottom: 30px;
  font-size: 70px;
  font-weight: 700;
  text-transform: capitalize;
}

.sub {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 20px;
}

.u-color-pall {
  color: #69e278 !important;
}

@media (max-width: 1200px) {
  h1 {
    font-size: 60px;
  }
}

@media (max-width: 992px) {
  h1 {
    font-size: 50px;
  }

  .sub, .sub a {
    font-size: 20px;
  }
}

@media (max-width: 500px) {
  h1 {
    font-size: 30px;
  }

  .sub, .sub a {
    font-size: 16px;
  }
}
</style>
