  <template>
    <main class="d-flex flex-column flex-grow-1">
      <div class="container my-4">
        <div class="row">

          <div class="col-10 col-md-9 ">
            <h1 v-if='this.$route.name == "cash-exchange"'>Раздел наличные временно недоступен</h1>
            <h1 v-if='this.$route.name == "pay-link"'>Раздел оплата по ссылке временно недоступен</h1>

            <div class="row">
              <div class="col-11 col-md-7">
                <div class="sub" v-if='this.$route.name == "pay-link" || this.$route.name == "cash-exchange"'>
                  Для проведения операций с наличными напишите нашему <a target="_blank" class="u-color-pall"
                      href="https://t.me/Valutix_manager">коньержу <img class="ms-2" src="/static/img/logos_telegram.svg"></a>
                </div>

                <div class="sub" v-else>
                  Если возникли вопросы обратитесь в <a class="u-color-pall" target="_blank" href="https://t.me/valutix">поддержку <img class="ms-2" src="/static/img/logos_telegram.svg"></a>
                     
                </div>
              </div>

            </div>

            <router-link class="button text-nowrap" to="/account">Вернуться в активы</router-link>

          </div>
        </div>
      </div>
    </main>

  </template>

<script>
export default {
  props: {
    title: String,
    standart: Boolean
  },
};
</script>

<style scoped>
.button {
  max-width: fit-content;
  padding-right: 40px;
  padding-left: 40px;
}
h1 {
  margin-bottom: 15px;
  font-size: 70px;
  font-weight: 700;
}

main {
  padding: 30px 0 132px 0;
  background: url(/public/static/img/gradient-teh-page.png) no-repeat right bottom;
  background-size: clamp(400px, 50vw, 1000px);
}

.sub {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 20px;
}

@media (max-width: 992px) {
  h1 {
    font-size: 50px;
  }

  .sub {
    font-size: 20px;
  }
}

@media (max-width: 500px) {
  h1 {
    font-size: 30px;
  }

  .sub {
    font-size: 16px;
    margin-bottom: 193px;
  }
}
</style>
