<template>
    <transition name='modal-fade'>
        <div class='modal fade new_custom_modal' id='verificationModal' style='display: block; opacity: 1; background: rgba(29, 28, 43, 0.9);' aria-hidden='true' tabindex='-1'>
            <div class='modal-dialog modal-dialog-centered'>
                <div v-if="isChangeMail == false && isChangePhone == false && step < 5" class='modal-content'>
                    <div class='modal-header' >
                        <h4 class='modal-title'>Верификация</h4>
                        <button class="btn-close btn-close-white shadow-none" type="button" data-bs-dismiss="modal" aria-label="Close" @click="close"></button>
                    </div>
                    <div v-if="step < 3" class="modal-body register_block">
                        <p style="font-size: 13px; line-height: 19px; font-weight: 400; font-family: Montserrat;">
                            Все данные, отправленные в данном окне, будут зашифрованы и надежно хранится на наших распределенных серверах, данные никогда не будут переданы третьим лицам
                        </p>
                        <input id="ver_fio" @change="checkALlFields" class='o-input text-center' v-model='fio' type='text' placeholder='ФИО' />
                        <div class="d-flex justify-content-between align-items-center w-100 pb-3 mt-4 flex-wrap">
                                <span v-if="mail" class='verification_file'>{{mail}}</span>
                                <span v-else class='verification_file'>{{user ? user.email : ''}}</span>
                                <span @click='switchBlock' class='link'>Изменить</span>
                        </div>
                        <div class="d-flex justify-content-between align-items-center w-100 pb-3 flex-wrap" v-if="user?.phone_number">
                                <span class='verification_file_2'>{{ user?.phone_number }}</span>
                                <span @click='switchPhoneBlock' class='link'>Изменить</span>
                        </div>
                        <div class="error-block-text" v-if="step == 2 && isCode !== true"><p>На этот номер придет звонок, введите последние 4 цифры входящего номера</p></div>

                        <div v-if="step == 2 && isCode ==  false" class="d-flex justify-content-between align-items-center w-100">
                            <input id="ver_codeForPhone text-center" style='width: 60%;' v-model='codeForPhone'  class='o-input mb-2' type='text' placeholder='код из номера' />
                            <div class="d-flex flex-column">
                                <span @click='checkCode' class='link mt-3'>Подтвердить</span>
                                <span v-if="countDown == 30" @click='countDownTimer' class="link" style="font-size:12px;">Новый звонок</span>
                                <span v-else class="custom-file__text" style="font-size: 12px; color: #69E278;">Будет доступно через:<br> {{ countDown }} секунд</span>
                            </div>
                        </div>
                        <div class="error-block-text" v-if="errorText.phone"><p>{{errorText.phone}}</p></div>
                        <button :disabled="!fio" style="margin-top: 20px;" class='button button--full-width' @click='next'>Далее</button>

                    </div>
                    <div v-else-if="step === 3" class="modal-body">
                        <p>Прикрепите фотографии подтверждающие вашу личность</p>

                        <input @change="checkALlFields" id='js-file-2' style='cursor: pointer; display: none;' 
                        @input='addPass1' slot=''  class='image-file js-file custom-file__input' type='file' name='uploadFile'/>
                        <label class='custom-file__label w-100 light_link_300 mb-3' for='js-file-2'>
                            <i class='custom-file__icon'></i>
                            <span class='light_link text-wrap text-break align-items-center'>
                                <img src="/img/ico/ico-upload.svg" alt="фото" width="23" height="19">
                                <span class="w-50 text-truncate" v-if="pass1">{{pass1.name}}</span>
                                <span v-else>Фото паспорта (лицевая сторона)</span>
                            </span>
                        </label>

                        <input @change="checkALlFields" id='js-file-3' style='cursor: pointer; display: none;' 
                        @input='addPass2' slot='' class='image-file js-file custom-file__input' type='file' name='uploadFile'/>
                        <label class='custom-file__label w-100 light_link_300 mb-3' for='js-file-3'>
                            <i class='custom-file__icon'></i>
                            <span class='light_link text-wrap text-break align-items-center'>
                                <img src="/img/ico/ico-upload.svg" alt="фото" width="23" height="19">
                                <span class="w-50 text-truncate" v-if="pass2">{{pass2.name}}</span>
                                <span v-else>Фото паспорта(прописка)</span>
                            </span>
                        </label>

                        <input @change="checkALlFields" id='js-file-4' style='cursor: pointer; display: none;' 
                        @input='addPass3' slot='' class='image-file js-file custom-file__input' type='file' name='uploadFile'/>
                        <label class='custom-file__label w-100 mb-4 light_link_300' for='js-file-4'>
                            <i class='custom-file__icon'></i>
                            <span class='light_link text-wrap text-break align-items-center'>
                                <img src="/img/ico/ico-upload.svg" alt="фото" width="23" height="19">
                                <span class="w-50 text-truncate" v-if="pass3">{{pass3.name}}</span>
                                <span v-else>Селфи с паспортом (лицевая сторона)</span>
                            </span>
                        </label>


                        <div style="display: flex;">
                            <span style="font-size: 16px; color: #3EA075;">*</span>
                            <span style="color: #3EA075; margin-bottom:0;font-size: 12px; line-height: 19px; font-weight: 400; font-family: Montserrat;">При загрузке документов используйте файлы форматов: png, jpeg, pdf.
                            Максимальный размер файла - 5 мб.</span>
                        </div>
                        <div class="mt-4 d-flex flex-wrap justify-content-evenly">
                            <button style='width: 45%' class='button button--full-width' @click='prev'>Назад</button>
                            <button :disabled='ALlFields == true ? false : true' style='width: 45%' class='button button--full-width' @click='send'>Отправить</button>
                        </div>
                    </div>

                    <div v-else class='modal-body pe-4'>
                        <p style="font-size: 13px; line-height: 19px; font-weight: 400; font-family: Montserrat;">Данные успешно отправлены</p>
                        <h3>Срок верификации от 30 до 60 минут
                        </h3>
                        <button style='margin-top: 20px;' class='button button--full-width' @click='close'>Окей</button>
                    </div>
                </div>

                <div v-show="isChangeMail" class='modal-content'>
                    <div class='modal-header'>
                        <h5 class='modal-title'>Изменить Email</h5>
                        <button class='btn-close btn-close-white shadow-none' type='button' data-bs-dismiss='modal' @click='back' aria-label='Close'></button>
                    </div>
                    <div class='modal-body register_block'>
                        <div class="d-flex justify-content-between align-items-center w-100">
                            <input id="ver_checkMail" class='o-input mb-2' type='text' style="width: 60%;" v-model='newMail' placeholder='Email' />
                            <span @click='checkMail' class='link'>Подтвердить</span>
                        </div>
                        <div class="error-block-text" v-if="errorText.mail"><p>{{errorText.mail}}</p></div>
                        <input id="ver_codeForMail" v-if="stepMail == 2" class='o-input mb-2' type='text' v-model='codeForMail' placeholder='Код из Email' />
                        <button style="margin-top: 20px;" class='button button--full-width' :disabled='stepMail == 2 ? false : true' @click='changeMail()'>Изменить</button>
                    </div>
                </div>

                <div v-show="isChangePhone" class='modal-content'>
                    <div class='modal-header'>
                        <h5 class='modal-title'>Изменить телефон</h5>
                        <button class='btn-close btn-close-white shadow-none' type='button' data-bs-dismiss='modal' @click='back' aria-label='Close'></button>
                    </div>
                    <div class='modal-body register_block'>
                        <div class="d-flex justify-content-between align-items-center w-100">
                            <vue-tel-input v-if="isCode == false && step < 2" @change="checkALlFields" :autoFormat="false" :styleClasses="'text-dark  w-60'" v-model="phone" :dropdownOptions="dropdownOptions" :inputOptions="inputOptions">
                                <template slot="arrow-icon">
                                    <span></span>
                                </template>
                            </vue-tel-input>
                            <span @click='checkPhone' class='link'>Подтвердить</span>
                        </div>
                        <div class="error-block-text" v-if="errorText.phone"><p>{{errorText.phone}}</p></div>
                        <input id="ver_codeForPhone" v-if="stepPhone == 2" class='o-input mb-2' type='text' v-model='codeForPhone' placeholder='Код из номера' />
                        <button style="margin-top: 20px;" class='button button--full-width' :disabled='stepPhone == 2 ? false : true' @click='changePhone()'>Изменить</button>
                    </div>
                </div>

                <div class="position-fixed w-100 h-100 d-flex justify-content-center align-items-center bg-white bg-opacity-50 rounded" v-if="loading">
                    <div class="spinner-border text-success" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                </div>

                </div>

            </div>
    </transition>
</template>
<script>

import { VueTelInput } from 'vue-tel-input';

export default {
    props:['user'],
    components: {
        VueTelInput,
    },
    data () {
        return {
            step: 1,
            stepMail: 1,
            stepPhone: 1,
            selfi: '',
            pass1: '',
            pass2: '',
            pass3: '',
            reader: '',
            fio: '',
            mail: '',
            phone: '',
            codeForPhone: '',
            codePhone: '123',
            codeForMail: '',
            codeMail: '123',
            newMail:'',
            newPhone: '',
            maxSize: 5242880,
            ALlFields: false,
            isCode: false,
            isCodeMail: false,
            isCodePhone: false,
            isChangeMail: false,
            isChangePhone: false,
            errorText: {
                phone: '',
                mail: '',
            },
            new_call: false,
            countDown: 30,
            dropdownOptions: {
                disabled: false,
                showDialCodeInSelection: false,
                showDialCodeInList: true,
                showSearchBox: false,
                showFlags: true,
            },
            inputOptions: {
                placeholder: 'Номер телефона',
                showDialCode: true,
                styleClasses: 'mt-0 p-all-5px',
            },
            session_id: null,
            loading: false
        }
    },
    watch: {
        'user.status': function(newValue, oldValue) {
            if (newValue === 4) {
                this.step = 1;
            }
        }
    },
    methods: {
        setErrors(response) {
            const errors = response?.data?.errors || {};
            let message;
            for (const field in errors) {
                message = errors[field][0];
            }
            let text = message || response?.data?.message || 'Что-то пошло не так';
            eventBus.$emit('errorBus', text);
        },
        countDownTimer() {
            if(this.new_call == false) {
                this.new_call = true;
                this.checkPhone();
            }
            if(this.countDown > 0) {
                setTimeout(() => {
                    this.countDown -= 1
                    this.countDownTimer()
                }, 1000)
            }
            if(this.countDown == 0) {
                this.countDown = 30;
                this.new_call = false;
            }
        },
        next(){
          if(this.step < 3) {
              if(this.$metrika !== undefined) {
                  this.$metrika.reachGoal('verification_2nd_step');
              }
              this.step = 3;
          }  else {
              this.step++;
          }
        },
        prev(){
            if(this.phone != '' && this.isCode == false) {
                this.step = 2;
            } else {
                this.step = 1;
            }
        },
        addSelfi(event){
            if((event.target.files[0].type == 'image/png' || event.target.files[0].type == 'image/jpeg' || event.target.files[0].type == 'application/pdf')
                && event.target.files[0].size < this.maxSize){
                    this.selfi = event.target.files[0];
            } else {
                eventBus.$emit('errorBus', 'Неверный формат или размер файла превышает 5Мб');
                this.selfi = '';
            }
        },
        addPass1(event){
            if((event.target.files[0].type == 'image/png' || event.target.files[0].type == 'image/jpeg' || event.target.files[0].type == 'application/pdf')
                && event.target.files[0].size < this.maxSize){
                this.pass1 = event.target.files[0];
            } else {
                eventBus.$emit('errorBus', 'Неверный формат или размер файла превышает 5Мб');
                this.pass1 = '';
            }
        },
        addPass2(event){
            if((event.target.files[0].type == 'image/png' || event.target.files[0].type == 'image/jpeg' || event.target.files[0].type == 'application/pdf')
                && event.target.files[0].size < this.maxSize){
                this.pass2 = event.target.files[0];
            } else {
                eventBus.$emit('errorBus', 'Неверный формат или размер файла превышает 5Мб');
                this.pass2 = '';
            }
        },
        addPass3(event){
            if((event.target.files[0].type == 'image/png' || event.target.files[0].type == 'image/jpeg' || event.target.files[0].type == 'application/pdf')
                && event.target.files[0].size < this.maxSize){
                this.pass3 = event.target.files[0];
            } else {
                eventBus.$emit('errorBus', 'Неверный формат или размер файла превышает 5Мб');
                this.pass3 = '';
            }
        },
        send() {
                let formData = new FormData();
                formData.append("full_name", this.fio);
                formData.append("main_passport_image", this.pass1);
                formData.append("registration_passport_image", this.pass2);
                formData.append("selfie_image", this.pass3);
                axios.post(this.$api_address + '/users/verification', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${localStorage.getItem('user_token')}`
                    }
                }).then(response => {
                    this.step = 4;
                    this.$emit('verify');
                    if(this.$metrika !== undefined) {
                        this.$metrika.reachGoal('verif_application');
                    }
                }).catch((error) => {
                    if (error.response?.status !== 401) {
                        this.setErrors(error.response);
                    }
                    else {
                        eventBus.$emit('logout');
                    }
                });
        },

        //Проверка кода
        checkCode(type) {
            let data = {
                code: type == 'phone' ? this.codeForPhone : this.codeForMail,
                type: type == 'phone' ? "phone" : "email",
                value: type == 'phone' ? this.phone : this.mail,
            }
            axios.post(this.$api_address + '/users/check_code', data, {
            }).then(response => {
                    type == 'phone' ? this.changePhone() : this.changeMail();
            }).catch((error) => {
                this.setErrors(error.response);
            });
        },

        /*Регистрируем телефон*/
        checkPhone() {
            let data = { phone: this.phone }
            axios.post(this.$api_address + '/users/phone_verify', data, {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem('user_token')}`,
                },
            }).then(response => {
                this.stepPhone = 2;
            }).catch((error) => {
                if (error.response?.status !== 401) {
                    this.setErrors(error.response);
                }  else {
                    eventBus.$emit('logout');
                }
            });
        },

        checkMail() {
            let data = { email: this.newMail }
            axios.post(this.$api_address + '/users/email_verify', data, {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem('user_token')}`,
                },
            }).then(response => {
                    this.stepMail = 2;
            }).catch((error) => {
                if (error.response?.status !== 401) {
                    this.setErrors(error.response);
                } else {
                    eventBus.$emit('logout');
                }
            });
        },

        checkALlFields() {
            if(this.pass1 != '' && this.pass2 != '' && this.pass3 != '' && this.fio != '') {
                this.ALlFields = true;
            } else {
                this.ALlFields = false;
            }
        },
        close(event) {
            this.$emit('close');
        },
        back() {
            this.isChangeMail = false;
            this.isChangePhone = false;
        },
        switchBlock() {
            if(this.isChangeMail == false) {
                this.isChangeMail = true;
            }
        },
        switchPhoneBlock() {
            if(this.isChangePhone == false) {
                this.isChangePhone = true;
            }
        },
        changePhone() {
            let data = {
                phone: this.newPhone,
            }
            axios.patch(this.$api_address + '/users/change_mail', data, {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem('user_token')}`,
                },
            }).then(response => {
                this.isCodePhone = true;
                this.errorText.phone = '';
                this.phone = this.newPhone;
                this.newPhone  = '';
                this.codeForPhone = '';
                this.stepPhone = 1;
                this.back();
            }).catch((error) => {
                if (error.response?.status !== 401) {
                    this.setErrors(error.response);
                }  else {
                    eventBus.$emit('logout');
                }
            });
        },

        /*Меняем мыло*/
        changeMail() {
            let data = {
                email: this.newMail,
            }
            axios.patch(this.$api_address + '/users/change_mail', data, {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem('user_token')}`,
                },
            }).then(response => {
                this.isCodeMail = true;
                this.errorText.mail = '';
                this.mail = this.newMail;
                this.newMail  = '';
                this.codeForMail = '';
                this.stepMail = 1;
                this.back();
            }).catch((error) => {
                if (error.response?.status !== 401) {
                    this.setErrors(error.response);
                } else {
                    eventBus.$emit('logout');
                }
            });

        }
    }
}
</script>
<style src="vue-tel-input/dist/vue-tel-input.css"></style>
<style>
    .vti__dropdown-item {
        color:#000;
    }
</style>
