<template>
    <div class='row md-mb-5'>
        <div class='col-lg-5 col-12' id='MyActives'>
            <div class='o-text-primary u-font-weight-600 mb-2'>Мои активы</div>
            <div class='mb-4 asset-grid'>
                <CryptoElementComponent v-for="crypto in cryptos"
                  :crypto="crypto"
                  @buy="buy"
                  @sale="sale"
                  @concl="concl"
                  @deposit="deposit"
                  @cash="cash"
                  @new_card="newCard"
                  @new_wallet="newWallet"
                  @showVerification="showModal"
                  :cards="cards"
                  :wallets="wallets"
                  :key="crypto.id"
                  :balances="balances"
                  :user="user"
                  :courses="crypto.currency?.code == 'USDT' ? '1' : courses_BTC2USDT_rate"
                  :courses_list="courses"
                  :for_buy="for_buy"
                  :for_deposit="for_deposit"
                  :bank_list="bank_list"
                  :currencies="currencies"
                  :methods="methods"
                />
            </div>
            <BannerDesktop/>
        </div>

         <div class='col-lg-7 col-md-12 col-12'>
            <div class='row'>
                <conversion-component :courses="courses" :crypto="cryptos" @convert="convert" :balances="balances" :methods="methods"></conversion-component>
                <VerificationBoard @showModal="showModal" :user="user"/>
            </div>
          
            <cards-component
                @close="closeCard"
                :cards="cards"
                :cards_count="cards_count"
                :flag="newCardFlag"
                :bank_list="bank_list"
            ></cards-component>
            <wallets-component
                @delete="delete_wallet"
                @close="closeWallet"
                :crypto="cryptos"
                :wallets="wallets"
                :wallets_count="wallets_count"
                :flag="newWalletFlag"
            ></wallets-component>
            <BannerMobile/>
        </div> 
        <verification v-show='isModalVisible' @close='closeModal' @verify='verify' :user="user"></verification>
    </div>
</template>
<script>
    import CryptoElementComponent from "./CryptoElementComponent.vue";
    import Verification from "./Verification.vue";
    import VerificationBoard from "./VerificationBoard.vue";
    import ConversionComponent from "./ConversionComponent.vue";
    import AppSpinner from "../General/AppSpinner.vue";
    import BannerDesktop from "./BannerDesktop.vue";
    import BannerMobile from "./BannerMobile.vue";
    import Vue from "vue";
    import NewCheck from "./NewCheck.vue";
    import Pagination2 from 'vue-pagination-2';
    import moment from "moment";
    Vue.component('cards-component', require('../Cards/CardsComponent.vue').default);
    Vue.component('wallets-component', require('../Wallets/WalletsComponent.vue').default);

    export default {
        props: [
            'courses',
            'user',
            'cards',
            'wallets',
            'cards_count',
            'wallets_count',
            'user_load',
            'cryptos',
            'balances',
            'checks',
            'for_buy',
            'for_deposit',
            'bank_list',
            'cryptos',
            'currencies'
        ],
        components: {
            NewCheck,
            CryptoElementComponent,
            BannerMobile,
            BannerDesktop,
            VerificationBoard,
            ConversionComponent,
            Verification,
            AppSpinner,
            Pagination2,
        },
        data: () => ({
                isModalVisible: false,
                isNewCheckVisible: false,
                usdtAmount:0,
                btcAmount:0,
                loadTether: false,
                loadBitcoin: false,
                loadEthereum: false,
                loadToncoin: false,
                loadBinancecoin: false,
                loadLitecoin: false,
                checkTypeShow: 'Единоразовый',
                newCardFlag: false,
                newWalletFlag: false,
                check: '',
                page: 1,  // Начальная страница
                perPage: window.innerWidth > 992 ? 10 : 3,
                moment: moment,
                methods: [],
        }),
        watch: {
            checkTypeShow(newVal, oldVal) {
              if (newVal !== oldVal) {
                this.page = 1; // Сброс текущей страницы
                this.clickCallback(this.page); // Обновление списка элементов
              }
            },
        },
        computed: {
            totalOneChecksCount() {
                    return Object.values(this.checks['one']) ? Object.values(this.checks['one']).reverse().length : 0;
                },
            totalLotChecksCount() {
                    return Object.values(this.checks['lot']) ? Object.values(this.checks['lot']).reverse().length : 0;
                },
            totalChecksCount() {
                    return this.checkTypeShow == 'Единоразовый' ? this.totalOneChecksCount : this.totalLotChecksCount;
                },
            totalPages() {
                    return Math.ceil(this.totalChecksCount / this.perPage);
            },     
            checksList: function() {
                const checksArray = this.checkTypeShow === 'Единоразовый'
                ? Object.values(this.checks['one'])
                : Object.values(this.checks['lot']);
                const start = (this.page - 1) * this.perPage;
                const end = start + this.perPage;
                let arrayCheks = checksArray.slice(start, end);
                if(typeof(this.check) != "undefined" && this.check !== null) {
                    let chekk = arrayCheks.find(c => c.id == this.check.id);
                    if(chekk !== undefined) {
                        this.check = chekk;
                    }
                }
                return arrayCheks;
            },
            courses_BTC2USDT_rate() {
                return this.courses.find(c => (c.pair == 'BTC-USDT'))?.rate ?? 0;
            },
        },
        methods: {
            changeCheckTypeShow() {
              if(this.checkTypeShow == 'Единоразовый') {
                  this.checkTypeShow = 'Многоразовый';
              }  else {
                  this.checkTypeShow = 'Единоразовый';
              }
              this.$forceUpdate();
            },
            convert(data){
                this.$emit('convert');
            },
            newCard() {
                this.newCardFlag = true;
            },
            closeCard(){
              this.newCardFlag = false;
            },
            closeWallet(){
                this.newWalletFlag = false;
            },
            newWallet() {
                this.newWalletFlag = true;
            },
            clickCallback(page)  {
                this.page = page
                            },
            buy(data) {
                this.$emit('buy', data);
            },
            sale(data) {
                this.$emit('sale', data);
            },
            concl(data) {
                this.$emit('concl', data);
            },
            deposit() {
                this.$emit('deposit');
            },
            cash() {
                this.$emit('cash');
            },
            newCheck(data){
                this.check = data;
                this.$emit('check');
            },
            verify(){
                this.$emit('verify');
            },
            showModal() {
                if(this.$metrika !== undefined) {
                    this.$metrika.reachGoal('pop_up_verification');
                }
                this.isModalVisible = true;
                document.body.style.overflow = 'hidden';
            },
            closeModal() {
                this.isModalVisible = false;
                document.body.style.overflow = 'auto';
            },
            showCheck(id) {
                
                this.check = this.checksList.find(check => check.id == id)

                if(this.$metrika !== undefined) {
                    this.$metrika.reachGoal('invoice_pop_up');
                }
                this.isNewCheckVisible = true;
                var modalElement = document.getElementById('newCheckModal');
                if (modalElement) {
                    var myModal = new bootstrap.Modal(modalElement);
                    myModal.show();
                }
            },
            showNewCheck() {
                this.isNewCheckVisible = true;
                this.check = '';
            },
            closeNewCheck() {
                this.isNewCheckVisible = false;
                this.check = '';
            },
            delete_wallet(data) {
                this.$emit('del_wallet', data)
            },
            async fetchMethods() {
                try {
                    const response = await axios.get(`${this.$api_address}/payment_methods/service/1`, {
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            "Authorization": `Bearer ${localStorage.getItem('user_token')}`,
                        }
                    });

                    const actualData = response.data;
                    this.methods = actualData.data
                        .filter(item => item.active?.find(c => c.service?.name === "Valutix")?.active == 1)
                        .sort((a, b) => a.id - b.id);

                } catch (error) {
                    if (error.response?.status !== 401) {
                        eventBus.$emit('errorBus', 'Не удалось получить методы');
                    }
                    else {
                        eventBus.$emit('logout');
                    }
                }
            }
        },
        created() {
            // eventBus.$on("userUpdate", (data) => {
            //     if(data.id == this.user.id) {
            //         if(data.status?.id == 5) {
            //             eventBus.$emit('logout');
            //             return
            //         };
            //         if(data.status?.id && data.status?.title) {
            //             this.user.status.id = data.status.id;
            //             this.user.status.title = data.status.title;
            //         }

            //     }
            // });
            this.fetchMethods();
        },
    }
</script>

<style scoped>
    .lk__list p {
        font-size: 12px;
        font-family: Montserrat;
        Line-height: 17px;
    }
    .lk__list span {
        font-size: 15px;
    }

    .f14, .f14 span {
        font-size: 14px;
    }

    .f15, .f15 span {
        font-size: 15px;
    }
    .f17 .f17 span {
        font-size: 17px;
    }
    .ver_btn {
        height: 40px;
        font-size: 16px;
    }
    .user_block span{
        display: block;
    }

    .no_min_height {
        min-height: unset !important;
    }
</style>
