import Vue from 'vue';
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueYandexMetrika from 'vue-yandex-metrika'
import VueMask from 'v-mask'
import VueQRCodeComponent from 'vue-qrcode-component'
import Fragment from 'vue-fragment'
import Pusher from 'pusher-js'
import { eventBus } from '@/eventBus.js';
import { createPinia, PiniaVuePlugin } from 'pinia';
Vue.use(PiniaVuePlugin);

import { MazPhoneNumberInput } from 'maz-ui'
import 'maz-ui/lib/css/base.css'
import 'maz-ui/lib/css/maz-phone-number-input.css'

Vue.component('MazPhoneNumberInput', MazPhoneNumberInput)

window.Pusher = Pusher;
window.eventBus = eventBus;
window.axios = axios;
window.axios.interceptors.response.use(
    response => response,
    error => {
      if (error.response?.status === 401) {
        eventBus.$emit('errorBus', 'Сессия истекла');
        localStorage.clear();       // очистить localStorage
        router.push('/');           // редирект на главную
      }
  
      return Promise.reject(error);
    }
  );

Vue.use(Fragment.Plugin)

Pusher.logToConsole = true;
var pusher = new Pusher('8615253848a65ab63299', {
    cluster: 'mt1'
});

var channelCourses = pusher.subscribe('courses');
channelCourses.bind('coursesUpdate', function(data) {
    app.$pusherInfo.courses = data;
    eventBus.$emit('coursesUpdate', Vue.prototype.$pusherInfo.courses);
});

eventBus.$on('turnOnPusher', (callback) => {
    var channelUser = pusher.subscribe('user');
    channelUser.bind('userUpdate', function(data) {
        app.$pusherInfo.user = data;
        eventBus.$emit('userUpdate', Vue.prototype.$pusherInfo.user);
    });

    var channelTransaction = pusher.subscribe('transaction');
    channelTransaction.bind('transactionUpdate', function(data) {
        app.$pusherInfo.transaction = data;
        eventBus.$emit('transactionUpdate', Vue.prototype.$pusherInfo.transaction);
        eventBus.$emit('historyUpdate', Vue.prototype.$pusherInfo.transaction);
    });
    var channelDeposit = pusher.subscribe('deposit');
    channelDeposit.bind('depositUpdate', function(data) {
        app.$pusherInfo.deposit = data;
        eventBus.$emit('depositUpdate', Vue.prototype.$pusherInfo.deposit);
        eventBus.$emit('historyUpdate', Vue.prototype.$pusherInfo.deposit);
    });
    var channelPayout = pusher.subscribe('payout');
    channelPayout.bind('payoutUpdate', function(data) {
        eventBus.$emit('historyUpdate', data);
    });
    var channelWithdrawal = pusher.subscribe('withdrawal');
    channelWithdrawal.bind('withdrawalUpdate', function(data) {
        eventBus.$emit('withdrawalUpdate', data);
        eventBus.$emit('historyUpdate', data);
    });
    // var channelCash = pusher.subscribe('cash');
    // channelCash.bind('cashUpdate', function(data) {
    //     app.$pusherInfo.cash = data.cash;
    //     eventBus.$emit('cashUpdate', Vue.prototype.$pusherInfo.cash);
    //     eventBus.$emit('historyUpdate', Vue.prototype.$pusherInfo.cash);
    // });
    var channelBalance = pusher.subscribe('balance');
    channelBalance.bind('balanceUpdate', function(data) {
        eventBus.$emit('balanceUpdate', data);
    });

    var channelpaymentViaLink = pusher.subscribe('paymentViaLink');
    channelpaymentViaLink.bind('paymentViaLinkUpdate', function(data) {
        console.log('paymentViaLink', data);
        app.$pusherInfo.paymentViaLink = data.paymentViaLink;
        eventBus.$emit('paymentViaLinkUpdate', Vue.prototype.$pusherInfo.paymentViaLink);
    });

    // var channelCheck = pusher.subscribe('check');
    // channelCheck.bind('checkUpdate', function(data) {
    //     app.$pusherInfo.check = data.check;
    //     eventBus.$emit('checkUpdate', Vue.prototype.$pusherInfo.check);
    // });
});


Vue.use(VueMask);
Vue.component('qr-code', VueQRCodeComponent)
Vue.component('cards-component', require('./components/Cards/CardsComponent.vue').default);
Vue.component('wallets-component', require('./components/Wallets/WalletsComponent.vue').default);
Vue.component('app-spinner', require('./components/General/AppSpinner.vue').default);
Vue.component('invoice-payment', require('./components/InvoicePayment.vue').default);

Vue.use(VueYandexMetrika, {
    id: 94615955,
    env: window.location.hostname == 'valutix.io' ? 'production' : 'development',
    debug: false,
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
    trackHash: true
});

//Глобальные переменные
Vue.prototype.$api_address = window.location.hostname == 'valutix.io' || window.location.hostname == 'www.valutix.io' ? 'https://valutix.io/api/V1' : 'https://staging.valutix.io/api/V1';

Vue.prototype.$globalCurrencies = [];
Vue.prototype.$globalBanks = [];
Vue.prototype.$globalEnabledCards = [];

//user_ID
Vue.prototype.$user_ID = Vue.observable({ value: null });
if (localStorage.getItem('user_ID')) {
    Vue.prototype.$user_ID.value = localStorage.getItem('user_ID');
}

//status_ID
Vue.prototype.$status_id = Vue.observable({ value: null });
if (localStorage.getItem('status_id')) {
    Vue.prototype.$status_id.value = localStorage.getItem('status_id');
}

//session_id
Vue.prototype.$session_id = Vue.observable({ value: null });
if (localStorage.getItem('session_id')) {
    Vue.prototype.$status_id.value = localStorage.getItem('session_id');
}

//user_token
Vue.prototype.$user_token = Vue.observable({ value: null });
if (localStorage.getItem('user_token')) {
    Vue.prototype.$user_token.value = localStorage.getItem('user_token');
}

Vue.prototype.$close_modal = (ID)=> {
    var modalID = document.getElementById(ID);
    var modalInstance = bootstrap.Modal.getInstance(modalID);
    modalInstance.hide();
}

Vue.prototype.$show_or_clean_error = (field, response, context) => {
    if (response.data.errors[field]) {
      context.errorText[field] = response.data.errors[field].pop();
    } else {
      context.errorText[field] = '';
    }
}

Vue.prototype.$pusherInfo = {
    transaction: null,
    deposit: null,
    payout: null,
    withdrawal: null,
    cash: null,
    balance: null,
    courses: null,
    user: null,
    check: null,
};

const pinia = createPinia();


const app = new Vue({
    el: '#app',
    pinia,
    router,
    render: h => h(App),
}).$mount('#app');


Vue.config.productionTip = false;

