<template>
    <section class="registration_section vh-100">
        <div class="container">
            <div class="row">
                <div class="col-5 d-none d-md-block">

                    <div class="top">
                        <h2 class="list_title">Бонусы от регистрации</h2>
                        <ul>
                            <!-- <li>Работа с инвойсами (многоразовые счета)</li> -->
                            <li>Более выгодные условия обмена</li>
                            <li>Мгновенная конвертация</li>
                            <li>Приоритетная поддержка</li>
                        </ul>
                    </div>

                    <div class="d-flex flex-column align-items-center flex-lg-row">
                        <div class="left w-100">
                            <h2>Подписывайся <br> на наш телеграм</h2>
                            <a target="_blank" href="https://t.me/valutix" class="button w-100 fs-5">Подписаться</a>
                        </div>
                        <div class="right">
                            <img src="/static/img/reg_tg2.png" alt="telegram">
                        </div>
                    </div>

                </div>

                <!-- Шаг 1 -->
                <form class="col-12 col-md-5 mx-auto z-index-2 step_1" v-if="step === 1" @submit.prevent='checkMail()'>
                    <h1>Создать аккаунт</h1>
                    <div class="mb-2">
                        <label for="enter_mail" class="form-label" type="button">Электронная почта</label>
                    </div>
                        <div class="mb-3">
                            <input placeholder="Введите e-mail" type="email" class="form-control shadow-none" id="enter_mail" v-model="email" @focus="error = false" />
                            <div class="form-text text_warning" v-if="error">
                                {{ error }}
                            </div>
                        </div>

                    <div class="mb-4 position-relative">
                        <label for="referer_code" class="form-label">Реферальный код (необязательно)</label>
                        <input autocomplete="off" type="number" class="form-control shadow-none" id="referer_code" v-model="referer_code" @input="checkReferal">
                        <div class="form-text text_warning" v-if="errorRef">Неверный реферальный код</div>
                    </div>
                    <div class="mb-4">
                        <button class="button w-100 mw-100" :disabled="(email === '' && phone === '') || errorRef === true || agree === false" type="submit">
                            Создать аккаунт
                        </button>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input shadow-none" type="checkbox" value="" id="flexCheckDefault" checked v-model="agree">
                        <label class="form-check-label" for="flexCheckDefault">
                            Создавая аккаунт, я принимаю
                            <router-link to="/terms_of_use">Условия предоставления услуг</router-link>,
                            <router-link to="/aml-kyc">Информацию о рисках и соответствии нормативным требованиям</router-link>
                            и
                            <router-link to="/privacy-police">Заявление о политике конфиденциальности valutix</router-link>
                        </label>
                    </div>
                    <span class="d-block text-center">Уже есть аккаунт? 
                        <router-link @click.native="closeOffcanvas" exact to="/login">Войти</router-link>
                    </span>
                </form>

                <!-- Шаг 2 -->
                <form class="col-12 col-md-5 mx-auto z-index-2 step_2" v-if="step === 2"  @submit.prevent="checkCode()">
                    <h1>Введите код из <br> электронной почты</h1>
                    <div class="mb-4"><span class="me-2">Мы отправили код на {{ email }}</span><a href="#"
                            @click="step = 1">Изменить</a></div>
                    <div class="pincode mb-4 position-relative">
                        <input v-for="(item, index) in pinInputs" :key="index" type="number" v-model="pinInputs[index]"
                            @input="handleInput(index)" ref="inputs" maxlength="1">
                        <div class="form-text text_warning" v-if="error"> {{ error }}</div>
                    </div>
                    <button class="button w-100 mw-100 next_2" :disabled="!pinInputs.every(input => input.length > 0)" type="submit">Далее</button>
                    <span>Ничего не получили? <a class="cursor-pointer" @click="checkMail">Отправить еще раз</a> {{ i >
                        0 ? '(' + i + ')' : '' }}</span>
                </form>

                <!-- Шаг 2.1 -->
                <form class="col-12 col-md-5 mx-auto z-index-2 step_1" v-if="step === 2.1" @submit.prevent='checkPhone()'>
                    <h1>Подтвердите <br> телефон</h1>
                    <div class="mb-2">
                        <label for="enter_phone" class="form-label" type="button">Для защиты аккаунта введите и подтвердите номер телефона</label>
                    </div>
                    <div class="mb-3">
                        <!-- <input placeholder="Введите номер" type="tel" class="form-control shadow-none" id="enter_phone" v-model="phone" @focus="error = false" /> -->
                        <div class="w-100">
                            <!-- <vue-tel-input :autoFormat="false" :styleClasses="'form-control shadow-none'" id="enter_phone" v-model="phone" :dropdownOptions="dropdownOptions" :inputOptions="inputOptions" @focus="error = false">
                                <template slot="arrow-icon">
                                    <span></span>
                                </template>
                            </vue-tel-input> -->
                            <MazPhoneNumberInput
                            v-model="phone"
                            @focus="error = false"
                            @update="results = $event"
                            show-code-on-list
                            show-flag
                            fetch-country
                            no-example
                            color="primary"
                            valid-color="primary"
                            :size="'sm'"
                            :preferred-countries="['RU', 'UZ', 'KZ']"
                            :translations="{
                              countrySelectorLabel: 'Код страны',
                              countrySelectorError: 'Ошибка',
                              phoneNumberLabel: 'Номер телефона',
                              example: 'Пример: '
                              }"
                          />
                        </div>
                        <div class="form-text text_warning" v-if="error">
                            {{ error }}
                        </div>
                    </div>
                    <div class="mb-4">
                        <button class="button w-100 mw-100" :disabled="phone === ''" type="submit">
                            Подтвердить
                        </button>
                    </div>
                </form>

                <!-- Шаг 2.2 -->
                <form class="col-12 col-md-5 mx-auto z-index-2 step_2" v-if="step === 2.2"  @submit.prevent="checkCode()">
                    <h1>Введите код из <br> входящего номера</h1>
                    <div class="mb-4"><span class="me-2">Мы звоним на {{ phone }}</span><a href="#"
                            @click="step = 2.1">Изменить</a></div>
                    <div class="pincode mb-4 position-relative">
                        <input v-for="(item, index) in pinInputs" :key="index" type="number" v-model="pinInputs[index]"
                            @input="handleInput(index)" ref="inputs" maxlength="1">
                        <div class="form-text text_warning" v-if="error"> {{ error }}</div>
                    </div>
                    <button class="button w-100 mw-100 next_2" :disabled="!pinInputs.every(input => input.length > 0)" type="submit">Далее</button>
                    <span>Ничего не получили? <a class="cursor-pointer" @click="checkPhone">Отправить еще раз</a> {{ i >
                        0 ? '(' + i + ')' : '' }}</span>
                </form>

                <!-- Шаг 3 -->
                <div class="col-12 col-md-5 mx-auto z-index-2 step_3" v-if="step === 3">
                    <h1 class="mb-4">Создайте пароль</h1>
                    <div class="mb-4 position-relative">
                        <div class="input-group">
                            <input type="password" autocomplete="new-password" v-model="password"
                                class="form-control border-0 shadow-none">
                            <span class="input-group-text bg-white">
                                <img src="/static/img/remove_red_eye.svg" class="cursor-pointer"
                                    @click="showPassword($event.target)">
                            </span>
                        </div>
                        <div class="form-text text_warning" v-if="error">{{ error }}</div>
                    </div>
                    <div class="mb-4">
                        <span class="fs_16 ul_title">Ваш пароль должен содержать:</span>
                        <ul>
                            <li :class="{ 'text_warning': !isLengthValid }">Длина от 8 до 32 символов</li>
                            <li :class="{ 'text_warning': !hasLowercaseLetter }">Хотя бы одну строчную букву</li>
                            <li :class="{ 'text_warning': !hasUppercaseLetter }">Хотя бы одну прописную букву</li>
                            <li :class="{ 'text_warning': !hasDigit }">Хотя бы одну цифру</li>
                        </ul>
                    </div>
                    <div class="mb-4 position-relative" v-if="isPasswordValid">
                        <label for="basic-url" class="form-label">Повторите пароль</label>
                        <div class="input-group">
                            <input type="password" class="form-control border-0 shadow-none" v-model="password_repeat">
                            <span class="input-group-text bg-white">
                                <img src="/static/img/remove_red_eye.svg" class="cursor-pointer"
                                    @click="showPassword($event.target)">
                            </span>
                        </div>
                        <span class="form-text text_warning fs_13"
                            v-if="isPasswordValid && (password !== password_repeat)">Пароли не совпадают</span>
                    </div>
                    <button class="next_btn button w-100 mw-100" :disabled="!isShowNext"
                        @click="register">Зарегистрироваться</button>
                </div>

            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "Registration",
    data() {
        return {
            email: '',
            phone: '',
            referer_code: '',
            password: '',
            password_repeat: '',
            step: 1,
            error: false,
            errorPincode: false,
            errorRef: false,
            agree: true,
            results: false,
            i: 0,
            pinInputs: ['', '', '', '', '', ''],
            dropdownOptions: {
                disabled: false,
                showDialCodeInSelection: false,
                showDialCodeInList: true,
                showSearchBox: false,
                showFlags: true,
            },
            inputOptions: {
                placeholder: 'Номер телефона',
                showDialCode: true,
                styleClasses: 'mt-0 p-all-5px',
            },
        };
    },
    computed: {
        // Вычисляемое свойство для объединения значений из всех инпутов
        pincode() {
            return this.pinInputs.join('');
        },
        isLengthValid() {
            return this.password.length >= 8 && this.password.length <= 32;
        },
        hasLowercaseLetter() {
            return /[a-z]/.test(this.password);
        },
        hasUppercaseLetter() {
            return /[A-Z]/.test(this.password);
        },
        hasDigit() {
            return /\d/.test(this.password);
        },
        isPasswordValid() {
            return this.isLengthValid && this.hasLowercaseLetter && this.hasUppercaseLetter && this.hasDigit ? true : false;
        },
        isShowNext() {
            return this.isPasswordValid && (this.password === this.password_repeat) ? true : false;
        },
    },
    methods: {
        handleInput(index) {
            if (this.pinInputs[index].length > 1) {
                // Если введено более одного символа, переносим остаток в следующие инпуты
                const overflow = this.pinInputs[index].slice(1); // Получаем остаток строки
                this.pinInputs[index] = this.pinInputs[index][0]; // Оставляем в текущем инпуте только первую цифру
                for (let i = 0; i < overflow.length && (index + i + 1) < this.pinInputs.length; i++) {
                    this.$set(this.pinInputs, index + i + 1, overflow[i]);
                }
            }

            // Перемещаем фокус на следующий инпут, если текущий заполнен
            if (this.pinInputs[index].length === 1 && index < this.pinInputs.length - 1) {
                this.$refs.inputs[index + 1].focus();
            }
        },
        showPassword(eventTarget) {
            let myInput = $(eventTarget).closest('.input-group').find('.form-control');
            myInput.attr('type', function (index, currentType) {
                return currentType === 'password' ? 'text' : 'password';
            });
        },

        //Проверка почты
        checkMail() {
            this.i++;
            let data = { email: this.email }
            axios.post(this.$api_address + '/users/email_verify', data, {
            }).then(response => {
                    this.step = 2;
                    this.error = false;
                    this.pinInputs = ['', '', '', '', '', ''];
            }).catch((error) => {
                console.log(error.response);
                const errorData = error.response?.data;
                this.error = errorData?.errors?.email[0] || 'Email занят';
            });
        },

        checkPhone() {
            this.i++;
            let data = { phone: this.phone, country_code: (this.results.countryCode || 'RU') }
            axios.post(this.$api_address + '/users/phone_verify', data, {
                validateStatus: function (status) {
                    return status < 500;
                }
            }).then(response => {
                if (response.data.result === true) {
                    this.step = 2.2;
                    this.error = false;
                    this.pinInputs = ['', '', '', ''];
                } else {
                    this.error = 'Номер занят';
                }
            }).catch(function (error) {
                console.log(error.response);
            });
        },

        checkReferal() {
            if (this.referer_code == '' || this.referer_code == null) {
                this.errorRef = false;
                return;
            }
            let data = { referral_code: this.referer_code }
            axios.post(this.$api_address + '/users/check_referral', data, {
                validateStatus: function (status) {
                    return status < 500;
                }
            }).then(response => {
                if (response.data.result) {
                    this.errorRef = false;
                }
                else {
                    this.errorRef = true;
                }
            }).catch(function (error) {
                console.log(error);
                this.errorRef = true;
            });
        },

        //Проверка кода
        checkCode() {
            let data = {
                code: this.pincode,
                type: this.email && this.phone ? "phone" : "email",
                value: this.email && this.phone ? this.phone : this.email,
            }
            axios.post(this.$api_address + '/users/check_code', data, {
                validateStatus: function (status) {
                    return status < 500;
                }
            }).then(response => {
                if(response.data.result === true) {
                    this.step = this.email && this.phone ? 3 : 2.1;
                    this.error = false;
                }
                else {
                    this.error = 'Неверный код';
                }
            }).catch(function (error) {
                this.error = 'Неверный код';
            });
        },

        //Регистрация
        register() {
            let data = {
                nickname: this.email ? this.email : this.phone,
                password: this.password,
                password_repeat: this.password_repeat
            }
            if (this.referer_code !== '') {
                data.referer_code = this.referer_code;
            }
            if(this.phone) {
                data.phone_number = this.phone;
            }
            if(this.email) {
                data.email = this.email;
            }
            axios.post(this.$api_address + '/users', data, {
                validateStatus: function (status) {
                    return status < 500; // Resolve only if the status code is less than 500
                }
            })
                .then(response => {
                    if (response.data) {
                        if (this.$metrika !== undefined) {
                            this.$metrika.reachGoal('registration_success');
                        }
                        localStorage.setItem('user_token', response.data);
                        this.getUserInfo();
                    } else {
                        if (response.data.errors.password) {
                            this.error = true;
                        }
                        if (response.data.errors.password_repeat) {
                            this.error = true;
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    this.error = true;
                });
        },
        getUserInfo() {
            axios.get(this.$api_address + '/users/token', {
                
                validateStatus: function (status) {
                    return status < 500;
                },
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem('user_token')}`,
                },
            })
                .then((response) => {
                    if(response.data.data) {
                        localStorage.setItem('user_ID', response.data.data.id);
                        localStorage.setItem('status_id', response.data.data.status.id);
                        this.$emit('login_user');
                    }
                })
                .catch((error) => {
                    console.error("Error fetching crypto:", error);
                });
        }
    },
}

</script>

<!-- <style src="vue-tel-input/dist/vue-tel-input.css"></style> -->

<style scoped>
h1,
.h1 {
    font-size: 30.5px;
    font-weight: 600;
    margin-bottom: 17px;
    text-transform: unset;
}

a {
    font-size: inherit;
    color: #69E278;
}

h2,
.h2 {
    font-size: 25px;
    font-weight: 600;
}

.fs_16 {
    font-size: 16px;
}

.list_title {
    margin-bottom: 16px;
}

.registration_section {
    padding: 74px 0 84px;
    background: left 200px bottom 0 no-repeat url(/public/static/img/gradient.png);
    position: relative;
}

.registration_section::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 54%;
    background-color: #35634F;
    z-index: 0;
}

.registration_section .top {
    margin-bottom: 125px;
}

button:disabled {
    opacity: 0.3;
}

.form-check-input {
    width: 17px;
    height: 17px;
    padding: 2px;
    margin-top: 7px;
    border-radius: 50%;
    border: 2px solid #fff;
    background: transparent;
    cursor: pointer;
}

.form-check-input:checked[type=checkbox] {
    background-image: url(/public/static/img/ellipse.svg);
    background-repeat: no-repeat;
    background-position: center;
}

.registration_section ul li {
    font-weight: 400;
    display: flex;
    align-items: center;
}

.registration_section .top ul li:before {
    content: url(/public/static/img/ellipse.svg);
    margin-right: 10px;
}

.registration_section .step_3 ul li {
    color: #74ED79;
}

.registration_section .top ul li:not(:last-child) {
    margin-bottom: 10px;
}

.registration_section .step_1 ul li:before {
    content: url(/public/static/img/ellipse.svg);
    margin-right: 10px;
    margin-bottom: 2px;
    display: block;
}

.form-check {
    margin-bottom: 44px;
}

.form-check-label {
    font-size: 13px;
}

.pincode {
    text-align: center;
    justify-content: space-between;
    display: flex;
    gap: 10px;
}

.pincode input[type="number"] {
    display: inline-block;
    border: none;
    border-radius: 5px;
    width: 68px;
    height: 68px;
    line-height: 50px;
    text-align: center;
    color: #212121;
    font-size: 30.5px;
    font-style: normal;
    font-weight: 400;
    padding: 16px 0px;
    outline: none;
    background-color: #fff;
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
    appearance: textfield;
}

.pincode input[type="number"]::-webkit-outer-spin-button,
.pincode input[type="number"]::-webkit-inner-spin-button {
    display: none;
}

.pincode input[type="number"]:focus {
    border: 2px solid #1dd216;
}

.next_2 {
    margin-bottom: 156px;
}

.text_warning {
    color: #FF6D6D !important;
}

.fs_13 {
    font-size: 13px;
}

.form-text {
    font-size: 13px;

}

.next_btn {
    margin-bottom: 110px;
}

@media(max-width: 1024px) {
    .pincode input[type="number"] {
        width: 50px;
        height: 50px;
    }
}



@media(max-width: 768px) {
    .registration_section {
        padding: 50px 0;
    }

    .registration_section::after {
        display: none;
    }
}

@media(max-width: 425px) {
    h1 {
        margin-bottom: 57px;
        font-size: 25px;
    }

    .registration_section {
        padding: 30px 26px 50px;
        background: left -200px bottom -150px no-repeat url(/public/static/img/gradient.png);
    }

    .form-check {
        margin-bottom: 144px;
    }

    .step_3 ul li:not(:last-child) {
        margin-bottom: 4px;
    }

    .step_3 .ul_title {
        margin-bottom: 4px;
    }

    .pincode input[type="number"] {
        width: 35px;
        height: 50px;
    }

}
</style>