<template>
    <!-- Покупка -->
    <div v-if="operationType === 'Покупка'" class='history-item' :class="{'history-item--open': isOpen }">
        <div class='history-item-wrapper'>
            <div class='history-item-ico'>
                <img src="/static/img/history-icons/transaction.svg" width="23" height="23">
            </div>
            <div class='history-item-action'>{{ operationType }}</div>
            <div class='history-item-desc u-color-white'>
                <span>
                    {{ cryptoAmount }} {{ currencyFrom.code }}
                </span>
            </div>
            <div :class="data.operation.status == 'Отмена' ? 'u-color-red' : data.operation.status == 'Успех'  ? 'u-color-green' : 'u-color-white'" class='history-item-date u-color-white'>
                <span v-if="data.operation.status != 'Успех'">{{ data.operation.status }}</span>
                <span v-else>{{ moment(data.operation.updated_at).format("DD-MM-YYYY H:mm") }}</span>
            </div>
            <div  class='history-item-toggle-ico' @click="toggle">
                <img src='/static/img/ico/ico-accordeon.svg'/>
            </div>
        </div>

        <div class='history-item-info'>
            <div class='history-item-info-left'>
                <span>ID заявки:</span>
                <span v-if="data.operation.status == 'В процессе'">Время создания:</span>
                <span v-if="data.operation.status == 'Успех'">Баланс до:</span>
                <span v-if="data.operation.status == 'Успех'">Баланс после:</span>
                <span v-else-if="data.operation.status != 'Успех' && data.operation.status != 'В процессе'">Операция отменена:</span>
                <span v-if="data.operation.status != 'Отмена'">Сумма покупки:</span>
                <!-- <button v-if="data.operation.status == 'В процессе'" class="btn btn-outline-danger p-1" @click="cancelBuy(data.operation.id)">Отменить операцию</button> -->
            </div>
            <div class='history-item-info-right'>
                <!-- ID заявки: -->
                <span>{{data.operation.outer_id}}</span>
                <!-- Время создания заявки -->
                <span v-if="data.operation.status == 'В процессе'">{{ moment(data.operation.created_at).format("DD-MM-YYYY HH:mm:s") }}</span>
                <!-- Баланс до: -->
                <span v-if="data.operation.status == 'Успех'">{{ (data.balances.before / currencyFrom.delimiter).toFixed(fixedNumberFrom) }} {{ currencyFrom.code }}</span>
                <!-- Баланс после: -->
                <span v-if="data.operation.status == 'Успех'">{{ (data.balances.after / currencyFrom.delimiter).toFixed(fixedNumberFrom)}} {{ currencyFrom.code }}</span>
                <!-- Операция отменена: -->
                <span v-else-if="data.operation.status != 'Успех' && data.operation.status != 'В процессе'">{{moment(data.operation.updated_at).format("DD-MM-YYYY HH:mm")}}</span>
                <!-- Сумма покупки: -->
                <span v-if="data.operation.status != 'Отмена'">{{ new Intl.NumberFormat('ru-RU').format((data.operation.currency_amount / currencyTo.delimiter).toFixed(fixedNumberTo))}} {{ currencyTo.code }}</span>
            </div>
        </div>
    </div>

    <!-- Продажа -->
    <div v-else-if="operationType === 'Продажа'" class='history-item' :class="{'history-item--open': isOpen }">
        <div class='history-item-wrapper'>
            <div class='history-item-ico'>
                <img src="/static/img/history-icons/payout.svg" width="23" height="23">
            </div>
            <div class='history-item-action'>{{ operationType }}</div>
            <div class='history-item-desc u-color-white'>
                <span>
                    {{ cryptoAmount }} {{ currencyTo.code }}
                </span>
            </div>
            <div :class="data.operation.status == 'Отмена' ? 'u-color-red' : data.operation.status == 'Успех'  ? 'u-color-green' : 'u-color-white'" class='history-item-date u-color-white'>
                <span v-if="data.operation.status != 'Успех'">{{ data.operation.status }}</span>
                <span v-else>{{ moment(data.operation.updated_at).format("DD-MM-YYYY H:mm") }}</span>
            </div>
            <div class='history-item-toggle-ico' @click="toggle">
                <img src='/static/img/ico/ico-accordeon.svg' />
            </div>
        </div>

        <div class='history-item-info'>
            <div class='history-item-info-left'>
                <span>ID заявки:</span>
                <span v-if="data.operation.status == 'В процессе'">Время создания:</span>
                <span v-if="data.operation.status == 'Успех'">Баланс до:</span>
                <span v-if="data.operation.status == 'Успех'">Баланс после:</span>
                <span v-else-if="data.operation.status != 'Успех' && data.operation.status != 'В процессе' && data.operation.status != 'В ожидании'">Операция отменена:</span>
                <span>Карта продажи:</span>
                <span v-if="data.operation.status != 'Отмена'">Сумма продажи:</span>
                <span v-if="data.operation.status == 'Отмена'">Причина отмены</span>
                <button v-if="data.operation.status == 'В процессе'" class="btn btn-outline-danger p-1" @click="cancelSale(data.operation.id)" >Отменить операцию</button>
            </div>
            <div class='history-item-info-right'>
                <!-- ID заявки: -->
                <span>{{data.operation.outer_id}}</span>
                <!-- Время создания заявки: -->
                <span v-if="data.operation.status == 'В процессе'">{{ moment(data.operation.created_at).format("DD-MM-YYYY HH:mm") }}</span>
                <!-- Баланс до: -->
                <span v-if="data.operation.status == 'Успех'">
                    {{ (data.balances.before / currencyTo.delimiter).toFixed(fixedNumberTo) }} {{ currencyTo.code }}
                </span>
                <!-- Баланс после: -->
                <span v-if="data.operation.status == 'Успех'">
                    {{ (data.balances.after  / currencyTo.delimiter).toFixed(fixedNumberTo) }} {{ currencyTo.code }}
                </span>
                <!-- Операция отменена: -->
                <span v-if="data.operation.status == 'Отмена'">
                    {{ moment(data.operation.updated_at).format("DD-MM-YYYY HH:mm") }}
                </span>
                <!-- Карта продажи: -->
                <span>{{data.operation.card_number | format}}</span>
                <!-- Сумма продажи: -->
                <span v-if="data.operation.status != 'Отмена'">
                    {{ new Intl.NumberFormat('ru-RU').format((data.operation.currency_amount / currencyFrom.delimiter).toFixed(fixedNumberFrom)) }} {{ currencyFrom.code }}
                </span>
                <!-- Причина отмены -->
                <span v-if="data.operation.status == 'Отмена'">{{data.operation.cancellation_message}}</span>
            </div>
        </div>
    </div>

    <!-- Конвертация -->
    <div v-else-if="operationType === 'Конвертация'" class='history-item' :class="{'history-item--open': isOpen }">
        <div class='history-item-wrapper'>
            <div class='history-item-ico'>
                <img src="/static/img/history-icons/convertation.svg" width="23" height="23">
            </div>
            <div class='history-item-action u-color-white'>{{ operationType }}</div>
            <div class='history-item-desc u-color-white'>
                <span>
                    {{ currencyFrom.code }}
                    <img src="/static/img/ico/ico-history-convert-2.svg" width="44" height="8">
                    {{ currencyTo.code }}
                </span>
            </div>
            <div class='history-item-date u-color-green'><span>{{ moment(data.operation.updated_at).format("DD-MM-YYYY H:mm") }}</span></div>
            <div class='history-item-toggle-ico' @click="toggle"><img src='/static/img/ico/ico-accordeon.svg' /></div>
        </div>

        <div class='history-item-info'>
            <div class='history-item-info-left'>
                <span>ID заявки:</span>
                <span>Баланс {{ currencyFrom.code }} до</span>
                <span>Баланс {{ currencyFrom.code }} после</span>
                <span>Баланс {{ currencyTo.code }} до</span>
                <span>Баланс {{ currencyTo.code }} после</span>
                <button v-if="data.operation.status == 'В ожидании'" class="btn btn-outline-danger p-1">Отменить операцию</button>
            </div>
            <div class='history-item-info-right'>
                <!-- ID заявки: -->
                <span>{{data.operation.id}}</span>
                <!-- Баланс до -->
                <span>{{ (data.balances_from.before / currencyFrom.delimiter).toFixed(fixedNumberFrom) }} {{ currencyFrom.code }} </span>
                <!-- Баланс после -->
                <span>{{ (data.balances_from.after / currencyFrom.delimiter).toFixed(fixedNumberFrom) }} {{ currencyFrom.code }} </span>
                <!-- Баланс до -->
                <span>{{ (data.balances.before / currencyTo.delimiter).toFixed(fixedNumberTo) }} {{ currencyTo.code }}</span>
                <!-- Баланс после -->
                <span>{{ (data.balances.after / currencyTo.delimiter).toFixed(fixedNumberTo) }} {{ currencyTo.code }}</span>

            </div>
        </div>
    </div>

    <!-- Вывод -->
    <div v-else-if="operationType === 'Вывод'" class='history-item' :class="{'history-item--open': isOpen }">
        <div class='history-item-wrapper'>
            <div class='history-item-ico'>
                <img src="/static/img/history-icons/withdrawal.svg" width="23" height="23">
            </div>
            <div class='history-item-action'>
                {{ operationType }}
            </div>
            <div class='history-item-desc u-color-white'>
                <span>
                    {{ cryptoAmount }} {{ currencyFrom.code }}
                </span>
            </div>
            <div :class="data.operation.status == 'Отмена' ? 'u-color-red' : data.operation.status == 'Успех'  ? 'u-color-green' : 'u-color-white'" class='history-item-date u-color-white'>
                <span v-if="data.operation.status != 'Успех'">{{data.operation.status}}</span>
                <span v-else>{{ moment(data.operation.updated_at).format("DD-MM-YYYY H:mm") }}</span>
            </div>
            <div :class="data.operation.status == 'В процессе' ? 'u-color-green' : data.operation.status == 'Успех'  ? 'u-color-white' : 'u-color-red'" class='history-item-toggle-ico' @click="toggle">
                <img src='/static/img/ico/ico-accordeon.svg' />
            </div>
        </div>

        <div class='history-item-info'>
            <div class='history-item-info-left'>
                <span>ID заявки:</span>
                <span v-if="data.operation.status == 'В процессе'">Время создания:</span>
                <span v-if="data.operation.status != 'Отмена'">Баланс до:</span>
                <span v-if="data.operation.status != 'Отмена'">Баланс после:</span>
                <span v-if="data.operation.status != 'Отмена'">Комиссия:</span>
                <span v-if="data.operation.status == 'Отмена'">Операция отменена:</span>
                <span v-if="data.operation.status != 'Отмена'">Кошелек:</span>
                <span v-if="data.operation.status != 'Отмена'">К зачислению:</span>
                <span v-if="data.operation.status == 'Отмена'">Причина отмены</span>
                <button v-if="data.operation.status == 'В ожидании'" class="btn btn-outline-danger p-1" @click="cancelWithdrawal(data.operation.id)">Отменить операцию</button>
            </div>
            <div class='history-item-info-right'>
                <!-- ID заявки: -->
                <span>{{ data.operation.outer_id }}</span>
                <!-- Время создания заявки: -->
                <span v-if="data.operation.status == 'В процессе'">{{ moment(data.operation.created_at).format("DD-MM-YYYY HH:mm:s") }}</span>
                <!-- Баланс до: -->
                <span v-if="data.operation.status != 'Отмена'">
                    {{ (data.balances.before / currencyFrom.delimiter).toFixed(fixedNumberFrom)}} {{ currencyFrom.code }}
                </span>
                <!-- Баланс после: -->
                <span v-if="data.operation.status != 'Отмена'">
                    {{ (data.balances.after / currencyFrom.delimiter).toFixed(fixedNumberFrom) }} {{ currencyFrom.code }}
                </span>
                <!-- Комиссия: -->
                <span v-if="data.operation.status != 'Отмена'">
                    {{ data.operation?.wallet?.cryptonetwork?.commission / data.operation?.wallet?.cryptonetwork?.cryptocurrency?.delimiter }} {{ currencyFrom.code }}
                </span>
                <!-- Операция отменена: -->
                <span v-if="data.operation.status == 'Отмена'">{{moment(data.operation.updated_at).format("DD-MM-YYYY H:mm")}}</span>
                <!-- Кошелек: -->
                <span v-if="data.operation.status != 'Отмена'">{{data.operation?.wallet?.wallet_hash}}</span>
                <!-- К зачислению: -->
                <span v-if="data.operation.status != 'Отмена'">
                    {{ cryptoAmount - (data.operation?.wallet?.cryptonetwork?.commission / data.operation?.wallet?.cryptonetwork?.cryptocurrency?.delimiter) }} {{ currencyFrom.code }}
                </span>
                <!-- Причина отмены -->
                <span v-if="data.operation.status == 'Отмена'">{{data.operation.cancellation_message}}</span>
            </div>
        </div>
    </div>

    <!-- Депозит -->
    <div v-else-if="operationType === 'Депозит'" class='history-item' v-bind:class="{'history-item--open': isOpen }">
        <div class='history-item-wrapper'>
            <div class='history-item-ico'>
                <img src="/static/img/history-icons/deposit.svg" alt="депозит" width="23" height="23">
            </div>
            <div class='history-item-action'>{{ operationType }}</div>
            <div class='history-item-desc u-color-white'>
                <span v-if="data.operation.actual_amount">
                    {{ (data.operation.actual_amount  / currencyFrom.delimiter).toFixed(fixedNumberFrom) }} {{currencyFrom.code }}
                </span>
                <span v-else>{{ (data.operation.proposed_amount / currencyFrom.delimiter).toFixed(fixedNumberFrom) }} {{ currencyFrom.code }}</span>
            </div>
            <div :class="data.operation.status == 'Отмена' ? 'u-color-red' : data.operation.status == 'Успех'  ? 'u-color-green' : 'u-color-white'" class='history-item-date u-color-white'><span v-if="data.operation.status != 'Успех'">{{data.operation.status}}</span><span v-else>{{ moment(data.operation.updated_at).format("DD-MM-YYYY H:mm") }}</span></div>
            <div class='history-item-toggle-ico' @click="toggle">
                <img src='/static/img/ico/ico-accordeon.svg' />
            </div>
        </div>

        <div class='history-item-info'>
            <div class='history-item-info-left'>
                <span>ID заявки:</span>
                <span v-if="data.operation.status == 'В процессе'||data.operation.status == 'Подтвержден'">Время создания:</span>
                <span v-if="data.operation.status == 'Успех'">Баланс до:</span>
                <span v-if="data.operation.status == 'Успех'">Баланс после:</span>
                <span v-else-if="data.operation.status != 'Успех' && data.operation.status != 'В процессе'">Операция отменена:</span>
                <button v-if="data.operation.status == 'В процессе'" @click="cancelDeposit(data.operation.id)" class="btn btn-outline-danger p-1">Отменить операцию</button>
            </div>
            <div class='history-item-info-right'>
                <span>{{data.operation.outer_id}}</span>
                <span v-if="data.operation.status == 'В процессе'||data.operation.status == 'Подтвержден'">{{ moment(data.operation.created_at).format("DD-MM-YYYY HH:mm:s") }}</span>
                <span v-if="data.operation.status == 'Успех'">{{ (data.balances.before / currencyFrom.delimiter).toFixed(fixedNumberFrom) }} {{ currencyFrom.code }}</span>
                <span v-if="data.operation.status == 'Успех'">{{ (data.balances.after / currencyFrom.delimiter).toFixed(fixedNumberFrom) }} {{ currencyFrom.code }}</span>
                <span v-else-if="data.operation.status != 'Успех' && data.operation.status != 'В процессе'">{{ moment(data.operation.updated_at).format("DD-MM-YYYY H:mm") }}</span>
            </div>
        </div>
    </div>
   
</template>


<script>
import moment from "moment";
export default {
    props: ['data', 'crypto', 'currencies'],
    data () {
        return {
            isOpen: false,
            moment: moment,
        }
    },
    filters: {
        format: val => `${val}`.replace(/(\d)(?=(\d{4})+([^\d]|$))/g, '$1 '),
    },
    methods: {
        toggle() {
            this.isOpen = !this.isOpen;
        },
        //Отмены операций
        delete(id, address) {
            axios.delete(this.$api_address + `/operations/${address}/` + id, {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem('user_token')}`
                },
                data: { cancellation_message: 'Отмена пользователем' }
            }).then(response => {
                eventBus.$emit('errorBus', 'Операция успешно отменена', true);
            }).catch((error) => {
                eventBus.$emit('errorBus', 'Что-то пошло не так, попробуйте снова');
            });
        },
        cancelSale(id) {
            this.delete(id, 'payouts');
        },
        // cancelBuy(id) {
        //     this.delete(id, 'transactions');
        // },
        cancelWithdrawal(id) {
            this.delete(id, 'withdrawals');
        },
        cancelDeposit(id) {
            this.delete(id, 'deposits');
        }
    },
    computed: {
        operationType() {
            const name = this.data.payment_method?.payment_operation?.name.toLowerCase() || '';
            if (!name) return 'Без названия';
            if (name.includes('продажа')) return 'Продажа';
            if (name.includes('вывод')) return 'Вывод';
            if (name.includes('покупка')) return 'Покупка';
            if (name.includes('депозит')) return 'Депозит';
            if (name.includes('конвертация')) return 'Конвертация';
            if (name.includes('ссылке')) return 'Оплата по ссылке';
            if (name.includes('инвойс')) return 'Инвойс';
            return '';
        },
        currencyFrom() {
            return this.data.payment_method?.currency_from || { code: '', delimiter: 1 };
        },
        currencyTo() {
            return this.data.payment_method?.currency_to || { code: '', delimiter: 1 };
        },
        fixedNumberFrom() {
            return String(this.data?.payment_method?.currency_from?.delimiter).split('0').length - 1 || 0;
        },
        cryptoAmount() {
            return ((parseFloat(this.data.operation?.cryptocurrency_amount)) / (this.data.payment_method?.currency_from.delimiter))?.toFixed(this.fixedNumberFrom);          
        },
        fixedNumberTo() {
            return String(this.data.payment_method?.currency_to?.delimiter).split('0').length - 1;
        },
    }
}
</script>
<style scoped>
    .btn-outline-danger {
        color: #fff;
        border-color: #FF6D6D;
        font-size: 16px;
        width: fit-content;
    }

    .btn-outline-danger:hover {
        background-color: #FF6D6D;
    }

    @media(max-width: 768px) {
        .btn-outline-danger {
            font-size: 12px;
        }
    }
</style>
