<template>
    <div class='col-12 col-md-5 mb-4 ver'>
        <div  class='o-text-primary u-font-weight-600 mb-2'>
            <span v-if="user && user.status?.id !== 3">Верификация </span>
            <span v-if="user && user.status?.id === 3">Профиль </span>
            <img v-if="user && user.status?.id == 2" src="/static/img/verification/ver_step_2.svg" width="16" height="16">
            <img v-else-if="user && user.status?.id == 3" src="/static/img/verification/ver_step_3.svg" width="16" height="16">
            <img v-else src="/static/img/verification/ver_step_other.svg" width="16" height="17">
        </div>
        <div class='u-bg-secondary p-3 u-border-radius size_fix d-flex flex-column' v-if="user && user.status?.id == 1">
            <div class='mb-3 f15'>
                <span>Необходима верификация</span>
            </div>
            <div class='lk__list'>
                <p>Для совершения сделок по покупке и продаже криптовалюты на суммы свыше 100 USDT или эквивалента в других валютах необходимо пройти процедуру верификации документов</p>
            </div>
            <div class='lk__list mt-auto'>
                <button class='button button--full-width ver_btn' @click="showModal">Верификация</button>
            </div>
        </div>
        <div class='u-bg-secondary p-3 u-border-radius size_fix d-flex flex-column' v-else-if="user && user.status?.id == 2">
            <div class='mb-3 f15'>
                <span>Данные проверяются</span>
            </div>
            <div class='lk__list'>
                <p>Срок верификации от 30 до 60 минут</p>
            </div>
            <div class='lk__list h-100'>
                <div class="w-100 h-100 d-flex justify-content-center align-items-center">
                    <div class="spinner-border text-white" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                </div>
            </div>
        </div>
        <div class='u-bg-secondary p-3 u-border-radius size_fix f14 user_block overflow-hidden' v-else-if="user && user.status?.id == 3">
            <div class='mb-4 text-start overflow-auto h-100 text-break'>
                <b v-if="user.full_name">Пользователь:</b>
                <span v-if="user.full_name">{{user.full_name}}</span>
                <b v-if="user.email">Email:</b>
                <span v-if="user.email">{{user.email}}</span>
                <b v-if="user.phone_number">Телефон:</b>
                <span>{{user.phone_number || 'обратитесь в службу поддержки для актуализации номера телефона'}}</span>
                <b v-if="user.referral_code">Реферальный код:</b>
                <span v-if="user.referral_code">{{user.referral_code}}</span>
                <b v-if="user.created_at">Дата регистрации:</b>
                <span v-if="user.created_at">{{moment(user.created_at).format("DD-MM-YYYY H:mm")}}</span>
            </div>
        </div>
        <div class='u-bg-secondary p-3 rounded size_fix d-flex flex-column' v-else-if="user && user.status?.id == 4">
            <div class='mb-3 fs-6'>
                <span v-if="user.verification_error_text">Верификация не пройдена по причине: "{{user.verification_error_text}}"</span>
                <span v-else>Верификация не пройдена</span>
            </div>
            <div class='lk__list mt-auto'>
                <button class='button button--full-width ver_btn' @click="showModal">Верификация</button>
            </div>
        </div>
    </div>
</template>

<script>
 import moment from "moment";
export default {
    name: 'VerificationBoard',
    props: ['user'],
    data() {
        return {
            moment: moment,
        }
    },
    methods: {
        showModal() {
            this.$emit('showModal');
        }
    }
}
</script>

<style scoped>
    .lk__list p {
        font-size: 12px;
        font-family: Montserrat;
        Line-height: 17px;
    }
    .lk__list span {
        font-size: 15px;
    }

    .f14, .f14 span {
        font-size: 14px;
    }

    .f15, .f15 span {
        font-size: 15px;
    }
    .f17 .f17 span {
        font-size: 17px;
    }
    .ver_btn {
        height: 40px;
        font-size: 16px;
    }
    .user_block span{
        display: block;
    }

    .no_min_height {
        min-height: unset !important;
    }
</style>