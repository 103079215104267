<template>
    <div class="container">
       
        <div class="row" id="history-id">
            <div class="col-12">
                <div class="my-5">
                    <div class="history d-flex justify-content-center align-items-center" v-if="loading">
                        <div class="spinner-border text-success" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    <HistoryComponent
                        v-else
                        :elements="elements"
                        :loading="loading"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import HistoryComponent from "@/components/HistoryComponent.vue";
    export default {
        components: {
            HistoryComponent,
        },
        data() {
            return {
                loading: false,
                elements: null,
            };
        },
        methods: {
            async getHistory() {
                this.loading = true;
                await axios
                .get(this.$api_address + "/user_operation_history", {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem('user_token')}`,
                },
                })
                .then((response) => {
                    this.elements = response.data.data.sort((a, b) => b.id - a.id);
                })
                .catch((error) => {
                    console.error("Error fetching history:", error);
                }).finally(() => {
                    this.loading = false;
                });
            },
        },
        created() {
            setTimeout(() => {
                const historyBlock = document.getElementById('history-id');
                if (historyBlock) {
                    historyBlock.scrollIntoView({ behavior: 'smooth' });
                }
            }, 0);
            this.getHistory();
            eventBus.$on("historyUpdate", () => {
                this.getHistory();
            });
        },
        
    }
</script>