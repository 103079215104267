<template>
    <transition name='modal-fade'>
        <div class='modal fade new_custom_modal' id='myCard' aria-hidden='true' tabindex='-1' >
            <div class='modal-dialog modal-dialog-centered modal-dialog-scrollable'>
                <div class='modal-content no-transition rounded overflow-hidden'>
                    <div class='modal-header'>
                        <h4 class='modal-title'>Мои карты</h4>
                        <button class='btn-close btn-close-white shadow-none' type='button' data-bs-dismiss='modal' aria-label='Close'></button>
                    </div>
                    <div class="modal-body">
                        <div class='cardBlock'>
                            <div class='cardList'>
                                <card :key='data.id' v-for='data in cards' :data='data' ></card>
                            </div>
                        </div>
                    </div>
                    <button class='lk__cart-right w-100' data-bs-dismiss="modal" @click='showAddCard'>
                        <div class='lk__cart-add'>+</div>
                        <span id='addCardButton' class='u-font-size-16'>Добавить карту</span>
                    </button>
                </div>
            </div>
            <add-card v-show='forModal.isAddCardVisible' @close='closeAddCard' />
        </div>
    </transition>
</template>

<script>
import Card from './Card.vue';
import AddCard from './AddCard.vue';
export default {
    props: ['cards'],
    components: {
        Card,
        AddCard,
    },
    data() {
        return {
            forModal: {
                isAddCardVisible: false
            },
        }
    },
    methods: {
        showAddCard() {
            this.$emit('showAddCard');
        },
        closeAddCard() {
            this.forModal.isAddCardVisible = false;
        },
        close(event) {
            this.$emit('close');
        },
    },
}
</script>


