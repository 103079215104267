<template>
    <transition name='modal-fade'>
    <div class="modal fade new_custom_modal" id="saleCryptoModal" aria-hidden="true" style='display: block; opacity: 1; background: rgba(29, 28, 43, 0.9);' tabindex="-1">
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header d-flex align-items-center justify-content-between flex-wrap">
                            <h5 class="modal-title order-1">Продажа {{crypto.currency?.code}}</h5>

                            <div class="mx-0 mx-md-auto order-3 order-md-2 col-12 col-md-4 px-0 py-3 py-md-0 d-flex align-items-center">
                                <span :class="step > 0 ? 'operation_steps_active' : 'operation_steps'">1</span>
                                <img width="49" height="2" src="/static/img/buy/line.svg" />
                                <span :class="step > 1 ? 'operation_steps_active' : 'operation_steps'">2</span>
                                <img width="49" height="2" src="/static/img/buy/line.svg" />
                                <span :class="step > 2 ? 'operation_steps_active' : 'operation_steps'">3</span>
                            </div>

                            <button v-if="step == 2" class='d-flex flex-row align-items-center flex-nowrap text-white order-2 order-md-3' type='button' @click='prev'>
                                <img src="/img/ico/white-arrow.svg" class="me-1"> 
                                <span>Назад</span>
                            </button>
                            <button v-else class="btn-close btn-close-white shadow-none order-2 order-md-3 z-index-2" type="button" data-bs-dismiss="modal" aria-label="Close" @click="close"></button>
                </div>

                <div class="error-block-text" v-if="error"><p>{{error}}</p></div>

                <!-- Шаг 1 -->
                <div class="modal-body" v-if="step == 1">
                    <div class="custom-modal__text mb-3 text-start">Куда вывести средства?</div>
                    <card-for-pop-up :cards="cards" :method.sync="method" @new_card="newCard"></card-for-pop-up>
                    <span v-if="cards.length == 0" class="mb-2">Добавьте карты, чтобы купить криптовалюту</span>
                    <button :disabled='this.method ? false : true' class='button button--full-width mb-3' @click='firstStep()'>Выбрать</button>
                </div>

                <!-- Шаг 2 -->
                <div class="modal-body" v-if="step == 2">
                    <div class="custom-modal__tab o-tab my-2">Текущий курс: 1 {{crypto.currency?.code}} = {{currencyCourse}} {{currency}}</div>
                    <div class="custom-modal__wrapper mb-sm-2 mb-4 align-items-center">
                        <div class="custom-modal__wrapper-left custom-modal__text text-start">Сколько продать {{crypto.currency?.code}}?</div>
                        <div class="custom-modal__wrapper-right">
                            <div class="input-group">
                                <input type="number" class="form-control form-control-lg border-0 shadow-none" @keyup='upd("rub")' v-model="form.amount" :aria-label="`Сумма продажи ${crypto.currency?.code}`" id="crypto1_amount_sell">
                                <button @click="saleAll" class="input-group-text bg-white border-0 convAllBtn" v-if="this.form.amount != this.balance">Продать все</button>
                            </div>
                            <!-- <input class="o-input" style="text-align: center" v-model="form.amount" @keyup='upd("rub")' value="0" type="number" />
                            <div @click="saleAll" v-if="this.form.amount != this.balance" class="position-relative">
                                <span style="font-size: 14px; color: #35634F; position: absolute; top: -34px; right: 5px; cursor: pointer;">Продать все</span>
                            </div> -->
                            <div class="error-block-text" v-show="errorInput != ''"><p>{{errorInput}}</p></div>
                        </div>
                    </div>
                    <div class="custom-modal__wrapper mb-sm-2 mb-4 align-items-center">
                        <div class="custom-modal__wrapper-left custom-modal__text text-start">Вы получите {{currency}}</div>
                        <div class="custom-modal__wrapper-right">
                            <input class="form-control form-control-lg border-0 shadow-none" v-model="form.amountCurrency" @keyup='upd("crypto")' value="0" type="number" />
                        </div>
                    </div>
                    <div class="custom-modal__wrapper mb-sm-2 mb-4">
                        <div class="custom-modal__wrapper-left custom-modal__text text-start u-font-weight-600">Текущий баланс:</div>
                        <div class="custom-modal__wrapper-right u-font-size-14"><span>{{new Intl.NumberFormat('ru-RU').format(parseFloat(balance))}} {{crypto.currency?.code}}</span></div>
                    </div>
                    <div class="custom-modal__wrapper mb-sm-2 mb-4">
                        <div class="custom-modal__wrapper-left custom-modal__text text-start">Остаток баланса:</div>
                        <div class="custom-modal__wrapper-right u-font-size-14 u-font-weight-600"><span>{{new Intl.NumberFormat('ru-RU').format(parseFloat(form.ost))}} {{crypto.currency?.code}}</span></div>
                    </div>
                    <div class="custom-modal__wrapper">
                        <div class="custom-modal__wrapper-left custom-modal__text text-start"></div>
                        <!-- Если верифицирован -->
                        <div v-if="user?.status?.id == 3" class="custom-modal__wrapper-right u-font-size-14 u-font-weight-600">
                            <button :disabled="(errorInput || form.amount == 0 || !form.amount || balance <= 0 || form.ost < 0)" @click="secondStep" class="button button--full-width mb-3" >Продать</button>
                        </div>
                        <!-- Если не верифицирован -->
                        <div v-else class="custom-modal__wrapper-right u-font-size-14 u-font-weight-600">
                            <span style="color: rgb(172, 175, 255);">
                                Пройдите <span @click="showVerification" class='text-light cursor-pointer'>верификацию</span> для совершения продажи
                            </span>
                        </div>
                    </div>
                </div>

                <!-- Шаг 3 -->
                <div class='modal-body' v-if="step == 3">
                    <div style='text-align: left; margin-bottom: 20px'>
                        <span class='me-3' style='font-size: 16px'>Ваша заявка на продажу {{this.form.amount}} {{crypto.currency?.code}} отправлена</span>
                    </div>
                    <button class='button button--full-width mb-3' @click='lastStep()'>Новая продажа</button>
                </div>

                <div class="position-fixed w-100 h-100 d-flex justify-content-center align-items-center bg-white bg-opacity-50" v-if="loading">
                    <div class="spinner-border text-success" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                </div>

            </div>
        </div>
    </div>
</transition>
</template>

<script>
import CardForPopUp from "../Cards/CardForPopUp.vue";

export default {
    components: { CardForPopUp },
    props: ['balance', 'balances', 'cards', 'courses', 'crypto', 'user', 'courses_list', "methods", 'currencies'],
    data() {
        return {
            form : {
                amount: null,
                amountCurrency: null,
                ost: 0,
            },
            step: 1,
            error: '',
            errorInput: false,
            loading: false,
            method: false
        }
    },
    watch: {
        'form.amount'(newVal) {
            if (!newVal) return;
            if (newVal > 100000) this.form.amount = 100000;
            if (newVal < 0) this.form.amount = 0;
        }
    },
    computed: {
        currencyCourse() {
            let myCourse =  (this.courses_list?.find(item =>
                item.pair === `${this.currency}-${this.sale_method?.currency_to?.code}`
            ));
            return (myCourse?.rate / myCourse?.parser?.currency_to?.delimiter) || 0
        },

        country() {
            return this.method?.bank?.region?.code || this.method?.card_method?.region?.code || 'ru';
        },

        currency: function () {
            switch (this.country.toLowerCase()) {
                case 'kz':
                    return 'KZT';
                case 'uz':
                    return 'UZS';
                case 'ru':
                    return 'RUB';
                case "eur":
                    return "EUR";
                default:
                    return "RUB";
            }
        },
        sale_method() { 
            return this.methods?.find(
            (item) => item.payment_operation?.name === "Noros продажа" && item.currency_to?.code === this.crypto?.currency?.code
        )}
    },
    methods: {
        setErrors(response) {
            console.log('response', response);
            const errors = response?.data?.errors || {};
            const mess = response?.data?.message || 'Что-то пошло не так';
            let text = ''
            for (const field in errors) {
                text = errors[field][0];
                break;
            }
            eventBus.$emit('errorBus', text || mess);
        },
        showVerification() {
                this.$emit('showVerification');
            },
        saleAll() {
            this.form.amount = this.balance;
            this.upd('rub');
        },
        prev() {
          this.step--;
          this.form.amount = '';
          this.upd('rub');
          this.method = false;
        },
        close(event) {
            this.$emit('close');
            setTimeout(() => (Object.assign(this, this.$options.data())), 500);
        },
        sale(data) {
            this.$emit('sale', data);
        },
        upd(type) {
            setTimeout(() => {
                if(type == 'rub'){
                    this.form.amountCurrency = parseInt(this.form.amount * (1 + parseFloat(this.sale_method?.commission)) * this.currencyCourse * this.sale_method?.currency_from?.delimiter) / this.sale_method?.currency_from?.delimiter;
                } else {
                    this.form.amount = parseInt((this.form.amountCurrency / (1 + parseFloat(this.sale_method?.commission)) / this.currencyCourse) * this.sale_method?.currency_to?.delimiter) / this.sale_method?.currency_to?.delimiter;
                }
                this.form.ost = this.balance - (this.form.amount || 0);

                if (this.user.status.id === 3) {
                    const amount = Number(this.form.amount);
                    const currency = this.crypto?.currency?.code;

                    const limits = {
                        USDT: { min: 10, max: 100000 },
                        BTC: { min: 0.0005, max: 0.3 }
                    };

                    if (limits[currency]) {
                        if (amount > limits[currency].max) {
                            this.errorInput = `Максимальная сумма продажи ${limits[currency].max} ${currency}`;
                            return;
                        }
                        if (amount < limits[currency].min) {
                            this.errorInput = `Минимальная сумма продажи ${limits[currency].min} ${currency}`;
                            return;
                        }
                    }

                    this.errorInput = '';
                }
            }, 500);
        },
        firstStep() {
            this.step = 2;
        },

        // Второй шаг
        secondStep() {
            if (this.errorInput != '' || !this.form.amount) {
                return
            }
            this.loading = true;
            let data = {
                payment_method_id: this.sale_method?.id,
                card_id: this.method.id,
                amount: Number(this.form.amount),
            }
            axios
            .post(this.$api_address + '/operations/payouts', data, {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem('user_token')}`,
                },
            })
            .then(response => {
                    this.sale(response.data);
                    this.step = 3;
            })
            .catch((error) => {
                if (error.response?.status !== 401) {
                    this.setErrors(error.response);
                 }
                else {
                    eventBus.$emit('logout');
                }
            })
            .finally(() => {
                setTimeout(() => (this.loading = false), 100);
            });
        },
        lastStep() {
            Object.assign(this, this.$options.data());
        },
        newCard(){
            this.$emit('new_card');
        },
        // putCard(data) {
        //     this.form.card_id = data.id;
        //     this.country = data.bank?.region?.code || data.card_method?.region?.code;
        // }

    },
    created() {
        this.form.ost = this.balance;
    },
}
</script>
<style scoped>
.convAllBtn {
    font-size: 14px;
    color: #35634F;
}
</style>
