<template>
    <section class="enter_section vh-100">
        <div class="container">
            <div class="row">
                <div class="col-5 d-none d-md-block">

                    <div class="d-flex flex-column align-items-center flex-lg-row">
                        <div class="left w-100">
                            <h2>Подписывайся <br> на наш телеграм</h2>
                            <a target="_blank" href="https://t.me/valutix" class="button w-100 fs-5">Подписаться</a>
                        </div>
                        <div class="right">
                            <img src="/static/img/reg_tg2.png" alt="telegram">
                        </div>
                    </div>

                </div>
                <!-- Шаг 1 -->
                <form class="col-12 col-md-5 mx-auto z-index-2 step_1" autocomplete="on" @submit.prevent='enter'>
                    <h1 v-if="!fromRecovery">Войти</h1>
                    <h1 v-if="fromRecovery" class="mb-4 d-none d-sm-block">Пароль успешно изменен <br> Войдите</h1>
                    <h1 v-if="fromRecovery" class="mb-4 d-block d-sm-none">Пароль успешно изменен, войдите</h1>
                    <div class="d-flex gap-3 mb-2 enterTabs" role="tablist">
                        <label for="enter_mail" class="form-label hover_green active" data-bs-toggle="tab"
                            data-bs-target="#mail-tab-pane" type="button" role="tab" aria-controls="mail-tab-pane"
                            aria-selected="true">Электронная почта</label>
                        <label for="enter_phone" class="form-label hover_green" data-bs-toggle="tab"
                            data-bs-target="#phone-tab-pane" type="button" role="tab" aria-controls="phone-tab-pane"
                            aria-selected="true">Телефон</label>
                    </div>
                    <div class="tab-content mb-3">
                        <div class="tab-pane show active" id="mail-tab-pane" role="tabpanel" aria-labelledby="mail-tab"
                            tabindex="0">
                            <input placeholder="Введите e-mail" type="email" class="form-control shadow-none"
                                id="enter_mail" v-model="email" @focus="errorData.email = null, errorData.login = null" @input="phone = '', results = false"/>
                            <div class="form-text text-danger" v-if="errorData.email">
                                {{ errorData.email }}
                            </div>
                            <div class="form-text text-danger" v-if="errorGeneral">
                                {{ errorGeneral }}
                            </div>
                            <div class="form-text text-danger" v-if="errorData.login">
                                {{ errorData.login }}
                            </div>
                            
                        </div>
                        <div class="tab-pane" id="phone-tab-pane" role="tabpanel" aria-labelledby="phone-tab"
                            tabindex="0">
                            <!-- <input placeholder="Введите номер" type="tel" class="form-control shadow-none"
                                id="enter_phone" v-model="phone" @focus="errorData.phone = null , errorData.login = null" @input="email = ''"/> -->
                                <!-- <vue-tel-input @input="onPhoneInput" :styleClasses="'form-control shadow-none rounded'" id="enter_phone" v-model="phone" :dropdownOptions="dropdownOptions" :inputOptions="inputOptions" @focus="error = false">
                                    <template slot="arrow-icon">
                                        <span></span>
                                    </template>
                                </vue-tel-input> -->
                                    <MazPhoneNumberInput
                                      v-model="phone"
                                      @focus="errorData.phone = null , errorData.login = null" @input="email = ''"
                                      @update="results = $event"
                                      show-code-on-list
                                      show-flag
                                      fetch-country
                                      no-example
                                      border-radius=4
                                      color="primary"
                                      valid-color="primary"
                                      :size="'sm'"
                                      :preferred-countries="['RU', 'UZ', 'KZ']"
                                      :translations="{
                                        countrySelectorLabel: 'Код страны',
                                        countrySelectorError: 'Ошибка',
                                        phoneNumberLabel: 'Номер телефона',
                                        example: 'Пример: '
                                        }"
                                    />
                            <div class="form-text text-danger" v-if="errorData.phone">
                                {{ errorData.phone }}
                            </div>
                            <div class="form-text text-danger" v-if="errorData.login">
                                {{ errorData.login }}
                            </div>
                            <div class="form-text text-danger" v-if="errorGeneral">
                                {{ errorGeneral }}
                            </div>
                        </div>
                    </div>
                    <div class="mb-4 position-relative">
                        <label for="password" class="form-label">Пароль</label>
                        <div class="input-group">
                            <input name="password" type="password" v-model="password"
                                class="form-control border-0 shadow-none" @focus="errorData.password = null">
                            <span class="input-group-text bg-white">
                                <img src="/static/img/remove_red_eye.svg" class="cursor-pointer"
                                    @click="showPassword($event.target)">
                            </span>
                        </div>
                        <div class="form-text text-danger" v-if="errorData.password">
                            {{ errorData.password }}
                        </div>
                    </div>
                    <div class="enter-btn">
                        <button class="button w-100 mw-100" :disabled="password === '' || (email === '' && phone === '')" type="submit">Войти</button>
                    </div>

                    <div class="mb-3">
                        <span class="d-block text-center">
                            <router-link to="/recovery">Забыли пароль?</router-link>
                        </span>
                    </div>
                    <span class="d-block text-center">
                        Нет аккаунта? <router-link to="/registration">Создать аккаунт</router-link>
                    </span>
                </form>

            </div>
        </div>
    </section>
</template>

<script>
export default {
    props: ['fromRecovery'],
    name: "Login",
    data() {
        return {
            pinInputs: ['', '', '', '', '', ''],
            email: '',
            phone: '',
            password: '',
            errorGeneral: '',
            label: 'mail',
            errorData: {},
            results: false,
        };
    },
    computed: {
        // Вычисляемое свойство для объединения значений из всех инпутов
        isLengthValid() {
            return this.password.length >= 8 && this.password.length <= 32;
        },
        hasLowercaseLetter() {
            return /[a-z]/.test(this.password);
        },
        hasUppercaseLetter() {
            return /[A-Z]/.test(this.password);
        },
        hasDigit() {
            return /\d/.test(this.password);
        },
        isPasswordValid() {
            return this.isLengthValid && this.hasLowercaseLetter && this.hasUppercaseLetter && this.hasDigit;
        }
    },
    methods: {
        showPassword(eventTarget) {
            let myInput = $(eventTarget).closest('.input-group').find('.form-control');
            myInput.attr('type', function (index, currentType) {
                return currentType === 'password' ? 'text' : 'password';
            });
        },
        setErrors(response) {
            const errors = response?.data?.errors || {};
            this.errorData = {};

            // проходим по каждому полю с ошибками
            for (const field in errors) {
                this.errorData[field] = errors[field][0]; // сохраняем только первую ошибку
            }
        },
        onPhoneInput(val) {
            this.email = '';
            this.errorData.phone = null;
            this.errorData.login = null;
        },
        enter() {
            let data = {
                password: this.password,
                login: this.email ? this.email : this.phone
            }
            if(this.results.countryCode) {
                data.country_code = this.results.countryCode;
            }
            axios.post(this.$api_address + '/users/auth', data)
            .then(response => {
                    this.errorData = {};
                    localStorage.setItem('user_token', response.data);
                    this.getUserInfo();
            })
            .catch((error) => {
                    this.setErrors(error.response);
            });
        },
        getUserInfo() {
            axios.get(this.$api_address + '/users/token', {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem('user_token')}`,
                },
            })
            .then((response) => {
                    localStorage.setItem('user_ID', response.data.data.id);
                    localStorage.setItem('status_id', response.data.data.status.id);
                    this.$emit('login_user');
            })
            .catch((error) => {
                if (error.response?.status !== 401) {
                    this.errorGeneral = 'Не удалось войти в аккаунт';
                }
                else {
                    eventBus.$emit('errorBus', 'Ваша учётная запись заблокирована, обратитесь в поддержку');
                }
            });
    }
    },
}

</script>

<style scoped>
h1,
.h1 {
    font-size: 30.5px;
    font-weight: 600;
    margin-bottom: 17px;
    text-transform: unset;
}

a {
    font-size: inherit;
    color: #69E278;
    text-underline-offset: 5px;
}

h2,
.h2 {
    font-size: 25px;
    font-weight: 600;
}

.fs_16 {
    font-size: 16px;
}

.list_title {
    margin-bottom: 16px;
}

.enter_section {
    padding: 74px 0 84px;
    background: left 200px bottom 0 no-repeat url(/public/static/img/gradient.png);
    position: relative;
}

.enter_section::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 54%;
    background-color: #35634F;
    z-index: 0;
}

.enter_section .top {
    margin-bottom: 125px;
}

button:disabled {
    opacity: 0.3;
}

.enter-btn {
    margin-bottom: 78px;
}

.hover_green:hover {
    color: #69e278;
    opacity: 1 !important;
}

.enterTabs label:not(.active) {
    opacity: 0.3;
}

@media(max-width: 768px) {
    .enter_section {
        padding: 50px 0;
    }

    .enter_section::after {
        display: none;
    }
}

@media(max-width: 425px) {
    h1 {
        margin-bottom: 57px;
        font-size: 25px;
    }

    .enter_section {
        padding: 30px 26px 50px;
        background: left -200px bottom -150px no-repeat url(/public/static/img/gradient.png);
    }

    .enter-btn {
        margin-bottom: 200px;
    }
}
</style>