<template>
    <div class='lk__cart-item'>
        <div class="d-flex gap-3">
            <img width="30" height="21" class="banks-logo rounded" :src="imgTitle"/>
            <div class="text-block d-flex flex-column lh-1 gap-1" v-if="data.description">
                <span class='me-3 fs-6 text-green' v-if="formattedDescription">{{ formattedDescription }}</span>
                <span class='me-3 cursor-pointer fs-5 fw-500' @click="copyToClipboard" v-if="formattedCardNumber">
                    {{ isCopied ? 'Скопировано!' : formattedCardNumber }}
                </span>
                <span class='me-3 fs-6 fw-light' v-if="data.bank.bank_name">
                    {{data?.card_method?.name == 'СБП' ? data.card_method.name+' / ' : ''}} 
                    {{ data.bank.bank_name }}
                </span>
            </div>
        </div>
        <button class='card_delete' @click='card_delete(data.id)'>
            <img width="17" height="17" src='/img/ico/ico-danger.svg' />
        </button>
    </div>
</template>

<script>
import noImg from '/public/img/banks/no_title.png';
export default {
    props: ['data'],
    data() {
        return {
            isCopied: false
        }
    },
    computed: {
        imgTitle() {
            if (!this.data?.bank?.region?.code) return noImg;
            try {
                return require(`/public/img/banks/flag/${this.data.bank.region.code.toUpperCase()}.svg`);
            } catch (e) {
                return noImg;
            }
        },
        formattedCardNumber() {
            const cardNumber = `${this.data.requisites}`;
            if(this.data?.card_method?.name == 'СБП') return this.data.requisites;
            if (cardNumber.length > 20) {
                return cardNumber.slice(0, 7) + ' ... ' + cardNumber.slice(-7);
            } else {
                return this.formatCardNumber(cardNumber);
            }
        },
        formattedDescription() {
            const cardDescription = `${this.data.description}`;
            if (cardDescription.length > 20) {
                return cardDescription.slice(0, 20) + ' ... ';
            } else {
                return cardDescription;
            }
        }
    },
    methods: {
        formatCardNumber(val) {
            return `${val}`.replace(/(\d{4})(?=\d)/g, '$1 ');
        },
        card_delete(id) {
            eventBus.$emit('card_delete', id);
        },
        copyToClipboard() {
            navigator.clipboard.writeText(this.data?.requisites).then(() => {
                this.isCopied = true;
                setTimeout(() => {
                    this.isCopied = false;
                }, 1000);
            }).catch(err => {
                console.error('Failed to copy: ', err);
            });
        }
    }
}
</script>

<style scoped>
.fs-7 {
    font-size: 13px;
}
@media (max-width:500px) {
    .fs-7 {
        font-size: 10px;
    }
}
</style>