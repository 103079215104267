<template>
    <section class="courses-block" id="courses_block">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg d-flex justify-content-between">
                    <div class="o-text-primary u-font-weight-600 mb-2">Курсы валют</div>
                    <span class="courses-line-last-child fw-300 fz-16 courses_line">Обновлено: {{ moment(BTC2USDT_upd).format("DD-MM-YYYY HH:mm") || 'сегодня'}}</span>
                </div>
            </div>
            <div class="row green_bg courses_wrap brs-5 m-0 align-items-center">
                <div class="col-12 col-lg-3 courses_wrap_item">
                    <span class="fz-16">1 BTC = {{ new Intl.NumberFormat('ru-RU').format(parseFloat(BTC2USDT).toFixed()) }} USDT</span>
                </div>
                <div class="col-12 col-lg-3 courses_wrap_item">
                    <span class="fz-16">1 USDT = {{ new Intl.NumberFormat('ru-RU').format(USDT2RUB) }} RUB</span>
                </div>
                <div class="col-12 col-lg-3 courses_wrap_item">
                    <span class="fz-16">1 USDT = {{ new Intl.NumberFormat('ru-RU').format(USDT2EUR) }} EUR</span>
                </div>
                <div class="col-12 col-lg text-end px-0">
                    <button class="btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#collapseCourses" aria-expanded="false" aria-controls="collapseCourses">
                        <span class="arrow">
                            <img src="/static/img/ico/ico-accordeon.svg">
                        </span>
                        <span>Подробнее</span>
                    </button>
                </div>
            </div>

        </div>
        <div class="collapse" id="collapseCourses" style="background:#212121">
            <div class="container">
                <div class="row green_border m-0 py-3">

                    <div class="col-12 col-lg-3 d-flex flex-column gap-2">
                        <div class="d-flex flex-column gap-1">
                            <span class="fz-16 courses_title">BTC <img src="/static/img/ico/ico-history-convert-2.svg" width="44" height="8"> RUB</span>
                            <span class="text-success fz-14">Покупка <img src="/static/img/courses/buy.svg"> {{ new Intl.NumberFormat('ru-RU').format(parseFloat(BTC2USDT * USDT2RUB).toFixed()) }} RUB</span>
                            <span class="text-danger fz-14">Продажа <img src="/static/img/courses/sell.svg"> {{ new Intl.NumberFormat('ru-RU').format(parseFloat(BTC2USDT * RUB2USDT).toFixed()) }} RUB</span>
                        </div>
                        <div class="d-flex flex-column gap-1">
                            <span class="fz-16 courses_title">BTC  <img src="/static/img/ico/ico-history-convert-2.svg" width="44" height="8"> KZT</span>
                            <span class="text-success fz-14">Покупка <img src="/static/img/courses/buy.svg"> {{ new Intl.NumberFormat('ru-RU').format(parseFloat(BTC2USDT * USDT2KZT).toFixed()) }} KZT</span>
                            <span class="text-danger fz-14">Продажа <img src="/static/img/courses/sell.svg"> {{ new Intl.NumberFormat('ru-RU').format(parseFloat(BTC2USDT * KZT2USDT).toFixed()) }} KZT</span>
                        </div>
                        <div class="d-flex flex-column gap-1">
                            <span class="fz-16 courses_title">BTC <img src="/static/img/ico/ico-history-convert-2.svg" width="44" height="8"> UZS</span>
                            <span class="text-success fz-14">Покупка <img src="/static/img/courses/buy.svg"> {{ new Intl.NumberFormat('ru-RU').format(parseFloat(BTC2USDT * USDT2UZS).toFixed()) }} UZS</span>
                            <span class="text-danger fz-14">Продажа <img src="/static/img/courses/sell.svg"> {{ new Intl.NumberFormat('ru-RU').format(parseFloat(BTC2USDT * UZS2USDT).toFixed()) }} UZS</span>
                        </div>
                    </div>

                    <div class="col-12 col-lg-3 d-flex flex-column gap-2">
                        <div class="d-flex flex-column gap-1">
                            <span class="fz-16 courses_title">USDT <img src="/static/img/ico/ico-history-convert-2.svg" width="44" height="8"> RUB</span>
                            <span class="text-success fz-14">Покупка <img src="/static/img/courses/buy.svg"> {{ new Intl.NumberFormat('ru-RU').format(USDT2RUB) }} RUB</span>
                            <span class="text-danger fz-14">Продажа <img src="/static/img/courses/sell.svg"> {{ new Intl.NumberFormat('ru-RU').format(RUB2USDT) }} RUB</span>
                        </div>
                        <div class="d-flex flex-column gap-1">
                            <span class="fz-16 courses_title">USDT <img src="/static/img/ico/ico-history-convert-2.svg" width="44" height="8"> KZT</span>
                            <span class="text-success fz-14">Покупка <img src="/static/img/courses/buy.svg"> {{ new Intl.NumberFormat('ru-RU').format(USDT2KZT) }} KZT</span>
                            <span class="text-danger fz-14">Продажа <img src="/static/img/courses/sell.svg"> {{ new Intl.NumberFormat('ru-RU').format(KZT2USDT) }} KZT</span>
                        </div>
                        <div class="d-flex flex-column gap-1">
                            <span class="fz-16 courses_title">USDT <img src="/static/img/ico/ico-history-convert-2.svg" width="44" height="8"> UZS</span>
                            <span class="text-success fz-14">Покупка <img src="/static/img/courses/buy.svg"> {{ new Intl.NumberFormat('ru-RU').format(USDT2UZS) }} UZS</span>
                            <span class="text-danger fz-14">Продажа <img src="/static/img/courses/sell.svg"> {{ new Intl.NumberFormat('ru-RU').format(UZS2USDT) }} UZS</span>
                        </div>
                    </div>

                    <div class="col-12 col-lg-3 d-flex flex-column gap-2">
                        <div class="d-flex flex-column gap-1">
                            <span class="fz-16 courses_title">USDT <img src="/static/img/ico/ico-history-convert-2.svg" width="44" height="8"> EUR</span>
                            <span class="text-success fz-14">Покупка <img src="/static/img/courses/buy.svg"> {{ new Intl.NumberFormat('ru-RU').format(USDT2EUR) }} EUR</span>
                            <span class="text-danger fz-14">Продажа <img src="/static/img/courses/sell.svg"> {{ new Intl.NumberFormat('ru-RU').format(EUR2USDT) }} EUR</span>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>

</template>
<script>
import moment from "moment";
export default {
    props: ['courses'],
    filters: {
        format: val => `${val}`.replace(/(\d)(?=(\d{4})+([^\d]|$))/g,'$1 '),
    },
    data() {
        return {
            moment: moment,
        }
    },
    methods: {
        getCourse(pair) {
            let course = this.courses?.find(item => item.pair == pair);
            return (course?.rate / course?.parser?.currency_to?.delimiter) || 0;
        },
    },
    computed: {

        BTC2USDT() {
            return this.getCourse(`BTC-USDT`);
        },
        //Покупка
        USDT2KZT() {
            return this.getCourse(`USDT-KZT`);

        },
        USDT2UZS() {
            return this.getCourse(`USDT-UZS`);
        },
        USDT2RUB() {
            return this.getCourse(`USDT-RUB`);
        },

        USDT2EUR() {
            return this.getCourse(`USDT-EUR`);
        },

        //Продажа
        RUB2USDT() {
            return this.getCourse(`RUB-USDT`);
        },

        KZT2USDT() {
            return this.getCourse(`KZT-USDT`);
        },

        UZS2USDT() {
            return this.getCourse(`UZS-USDT`);
        },

        EUR2USDT() {
            return this.getCourse(`EUR-USDT`);
        },

        BTC2USDT_upd() {
            return this.courses?.find(item => item.pair == `BTC-USDT`)?.updated_at;
        },
    },
}
</script>

<style scoped>

.fz-14 {
    font-size: 14px;
}
.fz-16 {
    font-size: 16px;
}
.green_border {
    border: 1px solid #35634F;
    border-top: 0;
    border-radius: 0 0 5px 5px;
    transform: translateY(-5px);
}
.fw-300 {
    font-weight: 300;
}
.green_bg {
    background-color: #35634F;
}
.brs-5 {
    border-radius: 5px;
 }
.courses-block {
    padding-top: 45px;
    position: relative;
    z-index: 99;
}

.btn-primary {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    gap: 5px;
    background: #4AA485;
    min-width: 268px;
    margin-left: auto;
    outline: 0;
    border: 0;
}

.btn-primary span{
    display: block;
}
.btn-primary:hover {
    background: #4AA485;
}

.btn-primary[aria-expanded = "true"] .arrow {
    transform: rotate(180deg);
}
.courses_title {
    display: flex;
    align-items: center;
    gap: 5px;
}

.text-success {
    color: #74ED79 !important;
}

.text-danger {
    color: #FF6D6D !important;
}

.courses_line {
    font-size: 16px;
}

@media(max-width:992px) {
    .btn-primary {
        width: 100%;    
    }
    .courses_wrap {
        gap: 10px;
    }
    .courses_wrap .courses_wrap_item:first-child {
        padding-top: 20px;
    }
    .courses_wrap .courses_wrap_item:nth-child(3) {
        padding-bottom: 10px;
    }
    .green_border {
        gap: 5px;
    }
}
@media(max-width: 375px) {
    .courses_line {
        font-size: 12px;
        font-weight: 300;
    }
}
</style>
