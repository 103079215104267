<template>
    <transition name='modal-fade'>
        <div class='modal fade new_custom_modal' id='addWallet' style='display: block; opacity: 1; background: rgba(29, 28, 43, 0.9);' aria-hidden='true' tabindex='-1'>
            <div class='modal-dialog modal-md modal-dialog-centered'>
                <div class='modal-content'>
                    <div class='modal-header'>
                        <h5 class='modal-title'>Добавить кошелек</h5>
                        <button class='btn-close btn-close-white shadow-none' type='button' data-bs-dismiss='modal' @click='close' aria-label='Close'></button>
                    </div>
                    <div class='modal-body'>
                        <div class='custom-modal__text mb-3 o-text-primary text-start'>
                            Валюта кошелька
                            <div class='d-flex my-2 flex-wrap justify-content-between align-content-between align-items-center'>
                                <div @click="clearNetworks" :key="index" v-for="(data, index) in cryptos"  style="min-width: 30%">
                                    <label  class='control control-radio'>
                                        {{ data.currency.name }}
                                        <input :id="data?.currency?.name" type='radio' v-model='crypto' name='radio' :value="data?.currency?.code"/>
                                        <div class='control_indicator'></div>
                                    </label>
                                </div>
                            </div>

                        </div>
                        <input id="add_wallet_value" class='o-input mb-2' type='text' v-model='value' placeholder='Адрес кошелька' />
                        <select class='o-input mb-2 form-select shadow-none' name='web_wallet' v-model='network' required=''>
                            <option disabled='' selected='' value="0">Выберите сеть...</option>
                            <option v-for='net in networksList' :value="net.id">
                                {{ net.name }}
                            </option>
                        </select>
                        <input class='o-input mb-2' type='text' v-model='description' name='desc_wallet' placeholder='Описание кошелька' />
                        <button class='button button--full-width mb-3' @click="add" :disabled="!network || !value || !description">
                            Добавить кошелек
                        </button>
                    </div>
                    <div class="position-fixed w-100 h-100 d-flex justify-content-center align-items-center bg-white bg-opacity-50" v-if="loading">
                        <div class="spinner-border text-success" role="status">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>

export default {
    props: ['title', 'cryptos', 'networks'],
    data() {
        return {
            network: 0,
            value: '',
            description: '',
            loading: false,
        }
    },

computed: {
    crypto() {
        return this.cryptos.find(c => c.currency.code.toUpperCase() === 'USDT')?.currency?.code || this.cryptos[0]?.currency?.code || 'USDT'
    },
    networksList() {
       return this.networks.filter(n => n.cryptocurrency?.code === this.crypto);
    }
},
    methods: {
        clearNetworks() {
          this.network = 0;
        },
        close() {
            this.$emit('close');
            setTimeout(() => (Object.assign(this, this.$options.data())), 500);
        },
        setErrors(response) {
            const errors = response?.data?.errors || {};
            // проходим по каждому полю с ошибками
            let message;
            for (const field in errors) {
                message = errors[field][0]; // сохраняем только первую ошибку
            }
            let text = message || response?.data?.message || 'Что-то пошло не так';
            eventBus.$emit('errorBus', text);
        },
        async add() {
            this.loading = true;
            let data = {
                wallet_hash: this.value,
                description: this.description,
                network_id: this.network,
            }
            await axios
                .post(this.$api_address+'/wallets', data, {
                    headers: {
                        "Authorization": `Bearer ${localStorage.getItem('user_token')}`,
                    },
                })
                .then(response => {
                    if(response.data.data) {
                        if(this.$metrika !== undefined) {
                            this.$metrika.reachGoal('adding_wallet_success');
                        }
                        eventBus.$emit('add_wallet', response.data.data);
                        this.close();
                    }
                })
                .catch((error) => {
                    if (error.response?.status !== 401) {
                        this.setErrors(error.response);
                    }  else {
                        eventBus.$emit('logout');
                    }
                })
                .finally(() => {
                    setTimeout(() => (this.loading = false), 100);
                });

        },
       
    },
}
</script>