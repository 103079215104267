<template>
    <div class='col-12 col-md-7 mb-4'>
    <div id="conversion-id">
        <div class='o-text-primary u-font-weight-600 mb-2'>Конвертация</div>
        <div class='u-bg-secondary h-100 u-border-radius'>
            <div class='lk__convert-wrapper'>
                <button class='lk__convert-ico' @click="swap"><img src='/img/ico/ico-convert--big.svg' /></button>
                <div class='lk__convert gap-2'>
                    <img :src="`/img/ico/ico-${crypto1?.currency?.name?.toLowerCase()}--color.png`"
                        :class="animation ? 'coin-enter-active' : ''" width="32" height="32" />
                    <select @change="changeCrypto" class="form-select shadow-none border-0 w-auto"
                        v-model="crypto1">
                        <option v-show="crypto2?.id !== item.id" v-for="(item, index) in crypto" :key="index"
                            :value="item">
                            {{ item.currency?.code }}
                        </option>
                    </select>
                    <div class="input-group">
                        <input type="number" class="form-control border-0 shadow-none" @keyup="calc(1)" v-model="amountFrom" placeholder='Введите сумму' :aria-label="`Сумма конвертации ${crypto1?.currency?.code}`" id="crypto1_amount_conversation">
                        <button @click="convAllTop" class="input-group-text bg-white border-0 convAllBtn">Всё</button>
                    </div>
                </div>
            </div>
            <div class='lk__convert-wrapper'>
                <div class='lk__convert gap-2'>
                    <img :src="`/img/ico/ico-${crypto2?.currency?.name?.toLowerCase()}--color.png`" width="32"
                        height="32" :class="animation ? 'coin-enter-active' : ''" />
                    <select @change="changeCrypto"  class="form-select shadow-none border-0 w-auto"
                        v-model="crypto2">
                        <option v-show="crypto1?.id !== item.id" v-for="(item, index) in crypto" :key="index"
                            :value="item">
                            {{ item.currency?.code }}
                        </option>
                    </select>
                    <div class="input-group">
                        <input type="number" class="form-control border-0 shadow-none" @keyup="calc(2)" v-model="amountTo" placeholder='Введите сумму' :aria-label="`Сумма конвертации ${crypto2?.currency?.code}`" id="crypto2_amount_conversation">
                        <button @click="convAllbottom" class="input-group-text bg-white border-0 convAllBtn">Всё</button>
                    </div>
                </div>
            </div>
            <div class='lk__convert-wrapper'>
                <div class='lk__convert'>
                    <div class='lk__convert-right'>Комиссия системы за конвертацию — {{ findCommission * 100 }}%</div>
                    <button @click="send"
                        class='button lk__convert-left' :disabled="!(amountTo > 0) && !(amountFrom > 0)">Обменять</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
export default {
    props: ['courses', 'crypto', 'balances', 'methods'],
    data() {
        return {
            amountFrom: 0,
            amountTo: 0,
            comission: 0.006,
            crypto1: [],
            crypto2: [],
            animation: false,
        }
    },
    watch: {
        crypto: {
            handler(newCrypto) {
                this.crypto1 = newCrypto?.[0] || null;
                this.crypto2 = newCrypto?.[1] || null;
                if (this.crypto1 && this.crypto1.currency?.delimiter) {
                this.amountFrom = (0).toFixed(this.fixedNumber(this.crypto1.currency.delimiter));
                }
                if (this.crypto2 && this.crypto2.currency?.delimiter) {
                    this.amountTo = (0).toFixed(this.fixedNumber(this.crypto2.currency.delimiter));
                }
            },
            immediate: true // Запускаем при первой загрузке
        },
        amountFrom() {
            if (!this.amountFrom) return
            if (this.amountFrom < 0) this.amountFrom = 0;
        },
        amountTo() {
            if (!this.amountTo) return
            if (this.amountTo < 0) this.amountTo = 0;
        }
    },
    computed: {
        maxAmount() {
            return this.balances.find(b => b.currency?.code == this.crypto1?.currency?.code).amount
        },
        findCourse() {
            return this.courses?.find(course => course.pair === `${this.crypto1?.currency?.code}-${this.crypto2?.currency?.code}`)?.rate || 1;
        },
        method() {
            return this.methods?.find(m =>
                m.currency_from?.code == this.crypto1?.currency?.code &&
                m.currency_to?.code == this.crypto2?.currency?.code &&
                 m.payment_operation?.name === "Конвертация"
            );
        },
        findCommission() {
            return this.method?.commission || this.comission;
        }
    },
    methods: {
        fixedNumber(number) {
            return String(number).split('0').length - 1 || 0;
        },
        changeCrypto() {
            this.animation = true;
            this.calc(1);
            setTimeout(() => (this.animation = false), 1000);
        },
        swap() {
            [this.crypto1, this.crypto2] = [this.crypto2, this.crypto1];
            this.amountFrom = this.amountTo;
            this.$nextTick(() => this.calc(1));
        },
        calc(pos) {
    if (pos === 1) {
        if (!this.amountFrom) {
            this.amountTo = (0).toFixed(this.fixedNumber(this.crypto2.currency.delimiter));
            return;
        }
        const t = this.amountFrom * this.findCourse;
        const comm = t * this.findCommission;
        this.amountTo = ((t - comm) / this.crypto2.currency.delimiter).toFixed(this.fixedNumber(this.crypto2.currency.delimiter));
    } else if (pos === 2) {
        if (!this.amountTo) {
            this.amountFrom = (0).toFixed(this.fixedNumber(this.crypto1.currency.delimiter));
            return;
        }
        // Сначала вычисляем, затем округляем:
        this.amountFrom = ((this.amountTo * this.crypto2.currency.delimiter) / 
                           (this.findCourse * (1 - this.findCommission)))
                           .toFixed(this.fixedNumber(this.crypto1.currency.delimiter));
    }
        },
        setErrors(response) {
            const errors = response?.data?.errors || {};
            let errorData
            // проходим по каждому полю с ошибками
            for (const field in errors) {
                errorData = errors[field][0]; // сохраняем только первую ошибку
            }
            eventBus.$emit('errorBus', errorData);
        },
        send() {
            if (!this.method?.id) {
                eventBus.$emit('errorBus', 'Конвертация этой пары временно недоступна');
                return;
            }
            let data = {
                payment_method_id: this.method?.id,
                amount: parseFloat(this.amountFrom),
            };
            axios
                .post(this.$api_address + '/operations/conversions', data, {
                    headers: {
                        "Authorization": `Bearer ${localStorage.getItem('user_token')}`,
                    },
                }).then(response => {
                        this.amountFrom = parseFloat(0)
                        this.amountTo = parseFloat(0)
                        if (this.$metrika !== undefined) {
                            this.$metrika.reachGoal('convert_success');
                        }
                        this.$emit('convert', data);
                        eventBus.$emit('errorBus', 'Конвертация прошла успешно!', true);
                }).catch(error => {
                    if (error.response?.status !== 401) {
                        this.setErrors(error.response);
                    }
                    else {
                        eventBus.$emit('logout');
                    }
                })
        },

        convAllTop() {
            const balance = this.balances?.find(b => b.currency.code === this.crypto1.currency.code)?.value;
            if (!balance) return;

            this.amountFrom = (balance / this.crypto1?.currency?.delimiter);
            this.calc(1);
        },
        convAllbottom() {
            const balance = this.balances?.find(b => b.currency.code === this.crypto2.currency.code)?.value;
            if (!balance) return;

            this.amountTo = (balance / this.crypto2?.currency?.delimiter)
            this.calc(2);
        }
    },
}
</script>
<style scoped>
.convAllBtn {
    font-size: 14px;
    color: #35634F;
}

@keyframes coin-flip {
    from {
        transform: rotateY(0deg);
    }

    to {
        transform: rotateY(360deg);
    }
}

.coin-enter-active {
    animation: coin-flip 1s infinite;
}
</style>