import { defineStore } from 'pinia';
import { API_ADDRESS } from '@/config';
import { eventBus } from '@/eventBus.js';

export const useTransactionStore = defineStore('transaction', {
  state: () => ({
    link: '',
    transactionId: null,
  }),
  actions: {
    async fetchTransaction(payment_method_id, amount) {
      payment_method_id = parseFloat(payment_method_id);
      amount = parseFloat(amount);
      try {
        const response = await fetch(`${API_ADDRESS}/operations/noros_transactions`, {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": `Bearer ${localStorage.getItem('user_token')}`
          },
          body: JSON.stringify({ payment_method_id, amount }),
          method: "POST",
        });
        const result = await response.json();
        if (response.ok) {
          console.log(result);
          this.link = result.link;
          this.transactionId = result.id;
        } else {
          if (response.status === 401) {
            eventBus.$emit('logout');
          } else {
          this.link = '';
          this.transactionId = null;
          const errors = result.errors || {};
          let errorData = false;
          for (const field in errors) {
            errorData = errors[field][0]; // сохраняем только первую ошибку
          }
          const message = errorData || result.message || 'Операция не удалась';
          eventBus.$emit('errorBus', message);
        }
      }
      } catch (e) {
        console.error('Ошибка:', e);
      }
    },
  },
});
