<template>
    <fragment>
        <div class="row mt-4 mb-3">
            <div class="col d-flex align-items-end">
                <h3 class="mb-0">Счет №{{invoice.user_id}}-{{invoice.id}}</h3>
                <span class="ms-5">{{ invoice.status == 'Активен' ? this.lastPaymentStatus : invoice.status }}</span>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-12 col-sm-4 col-lg-2">
                <span>Описание</span>
            </div>
            <div class="col-12 col-sm-8 col-lg-10 ps-3">
                <div class="col">
                    <span>{{invoice.description}}</span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 pt-2 ">
                <h1 class="mb-0">{{ invoice.commission_type == 'Плательщик' ? parseFloat(invoice.amount) + parseFloat(invoice.commission) : invoice.amount }} {{invoice.currency.code}}</h1>
            </div>
        </div>
        <div class="col-12">
            <div class="row">
                <div class="col-12 col-sm-4">
                    <span>Способ оплаты</span>
                </div>
                <div class="col-12 col-sm-8 d-flex align-items-center">
                    <img v-if="selected_method && selected_method.logo" :src="selected_method.logo" width="100px">
                    <img v-if="selected_method && !selected_method.logo" :src="'/img/ico/ico-' + crypto.find(c => c.id == selected_method.crypto_id).title.toLowerCase() + '--color.png'" width="20px">
                    <img v-if="!selected_method" :src="'/img/ico/ico-' + crypto.find(c => c.id == invoice.payments[invoice.payments.length - 1].operation.crypto_id).title.toLowerCase() + '--color.png'" width="20px">
                    <span v-if="selected_method && !selected_method.logo">{{crypto.find(c => c.id == selected_method.crypto_id).code}}</span>
                    <span v-if="!selected_method">{{crypto.find(c => c.id == invoice.payments[invoice.payments.length - 1].operation.crypto_id).code}}</span>
                    <span class="ms-3" style="font-size: 14px; font-weight: 100" v-if="selected_method.logo == null || selected_method.logo == undefined">{{selected_method.title}}</span>
                </div>
            </div>
        </div>
        <div class="row mb-2 pt-2">
            <div class="col-12 col-sm-4 col-lg-2">
                <span>Оплаченная сумма</span>
            </div>
            <div class="col-12 col-sm-8 col-lg-10 ps-3">
                <div class="col">
                    <span class="fw-bold" v-if="payment.currency_amount">{{payment.commission_type == 'Плательщик' ? parseInt(payment.currency_amount/(1 - 0.005)) : payment.currency_amount}} {{currency}}</span>
                    <span class="fw-bold" v-if="payment.proposed_amount">{{payment.commission_type == 'Плательщик' ? parseInt(payment.proposed_amount/(1 - 0.005)) : payment.proposed_amount}} {{crypto.find(c => c.id == payment.crypto_id).code}}</span>
                </div>
            </div>
        </div>
        <div v-if="invoice.status == 'Успех' || lastPaymentStatus == 'Успех'" class="row pt-2 pb-3">
            <div class="col-12">
                Получить чек на Email
                <div class="d-flex align-items-center ">
                    <input class="inputAmount w-50 mt-0" type="text" v-model="email">
                    <button @click="sentMail" class="button ms-3" style="height: 40px; width:200px;">Получить чек</button>
                </div>
            </div>
        </div>
        <div v-if="invoice.status == 'Успех' || lastPaymentStatus == 'Успех'" class="col-12 col-sm-9 mb-5 mt-2">
            <div class="row">
                <div @click="clickWA" class="col pb-2 cursor-pointer">
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1522_7746)">
                            <path d="M0.683431 15.8086C0.682681 18.4972 1.39064 21.1224 2.73682 23.4363L0.554688 31.3423L8.70826 29.2209C10.9634 30.4391 13.4902 31.0775 16.0579 31.0776H16.0646C24.5411 31.0776 31.4411 24.2332 31.4447 15.8206C31.4463 11.7441 29.8479 7.91076 26.9438 5.02679C24.0402 2.14307 20.1785 0.554107 16.064 0.552246C7.58657 0.552246 0.687056 7.39628 0.683556 15.8086" fill="url(#paint0_linear_1522_7746)"/>
                            <path d="M0.133743 15.8035C0.132868 18.5889 0.866205 21.308 2.26038 23.7048L0 31.8942L8.44593 29.6967C10.7731 30.9558 13.3932 31.6196 16.0593 31.6206H16.0662C24.8467 31.6206 31.9946 24.53 31.9983 15.8162C31.9998 11.5932 30.3439 7.62208 27.3361 4.63479C24.3279 1.64788 20.3281 0.00173643 16.0662 0C7.28412 0 0.137243 7.08961 0.133743 15.8035ZM5.16348 23.292L4.84812 22.7953C3.52244 20.7036 2.82273 18.2865 2.82373 15.8045C2.8266 8.56174 8.76679 2.66915 16.0712 2.66915C19.6085 2.67064 22.9328 4.03895 25.4332 6.52155C27.9334 9.0044 29.3092 12.3049 29.3083 15.8152C29.3051 23.058 23.3648 28.9513 16.0662 28.9513H16.0609C13.6844 28.9501 11.3537 28.3168 9.32101 27.12L8.83729 26.8353L3.8253 28.1393L5.16348 23.2919V23.292Z" fill="url(#paint1_linear_1522_7746)"/>
                            <path d="M12.0862 9.19662C11.788 8.53888 11.4741 8.52561 11.1905 8.51407C10.9583 8.50415 10.6928 8.5049 10.4275 8.5049C10.162 8.5049 9.73069 8.604 9.36608 8.99904C9.0011 9.39445 7.97266 10.35 7.97266 12.2934C7.97266 14.237 9.39921 16.1152 9.59807 16.379C9.79719 16.6423 12.3521 20.7582 16.3983 22.3415C19.7612 23.6574 20.4455 23.3957 21.1753 23.3297C21.9053 23.264 23.5307 22.3744 23.8623 21.452C24.1942 20.5297 24.1942 19.7391 24.0947 19.5739C23.9952 19.4093 23.7297 19.3105 23.3316 19.113C22.9334 18.9154 20.9762 17.9598 20.6114 17.8279C20.2464 17.6962 19.981 17.6305 19.7155 18.026C19.4501 18.4209 18.6877 19.3105 18.4554 19.5739C18.2232 19.838 17.9909 19.8708 17.5929 19.6733C17.1945 19.4751 15.9125 19.0584 14.3914 17.7128C13.208 16.6658 12.409 15.3727 12.1768 14.9772C11.9446 14.5823 12.1519 14.3682 12.3516 14.1714C12.5304 13.9944 12.7498 13.7101 12.949 13.4795C13.1475 13.2488 13.2138 13.0842 13.3465 12.8208C13.4794 12.5571 13.4129 12.3264 13.3135 12.1288C13.2138 11.9313 12.4402 9.97764 12.0862 9.19662Z" fill="white"/>
                        </g>
                        <defs>
                            <linearGradient id="paint0_linear_1522_7746" x1="1545.06" y1="3079.56" x2="1545.06" y2="0.552246" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#1FAF38"/>
                                <stop offset="1" stop-color="#60D669"/>
                            </linearGradient>
                            <linearGradient id="paint1_linear_1522_7746" x1="1599.92" y1="3189.42" x2="1599.92" y2="0" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#F9F9F9"/>
                                <stop offset="1" stop-color="white"/>
                            </linearGradient>
                            <clipPath id="clip0_1522_7746">
                                <rect width="32" height="32" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                    <span class="ms-2 fw-lighter">Отправить в WhatsApp</span>
                </div>
                <div @click="clickTg" class="col pb-2 cursor-pointer">
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1522_7742)">
                            <path d="M16 0C11.7575 0 7.685 1.68675 4.6875 4.68625C1.68691 7.68694 0.000831175 11.7565 0 16C0 20.2417 1.6875 24.3142 4.6875 27.3137C7.685 30.3132 11.7575 32 16 32C20.2425 32 24.315 30.3132 27.3125 27.3137C30.3125 24.3142 32 20.2417 32 16C32 11.7583 30.3125 7.68575 27.3125 4.68625C24.315 1.68675 20.2425 0 16 0Z" fill="url(#paint0_linear_1522_7742)"/>
                            <path d="M7.24116 15.8311C11.9062 13.7991 15.0162 12.4593 16.5712 11.8121C21.0162 9.96381 21.9387 9.64281 22.5412 9.63193C22.6737 9.62981 22.9687 9.66256 23.1612 9.81818C23.3212 9.94943 23.3662 10.1269 23.3887 10.2516C23.4087 10.3761 23.4362 10.6598 23.4137 10.8813C23.1737 13.4113 22.1312 19.5508 21.6012 22.3846C21.3787 23.5836 20.9362 23.9856 20.5087 24.0248C19.5787 24.1103 18.8737 23.4108 17.9737 22.8211C16.5662 21.8978 15.7712 21.3233 14.4037 20.4226C12.8237 19.3816 13.8487 18.8093 14.7487 17.8743C14.9837 17.6296 19.0787 13.9058 19.1562 13.5681C19.1662 13.5258 19.1762 13.3683 19.0812 13.2853C18.9887 13.2021 18.8512 13.2306 18.7512 13.2531C18.6087 13.2851 16.3612 14.7721 12.0012 17.7138C11.3637 18.1523 10.7862 18.3661 10.2662 18.3548C9.69616 18.3426 8.59616 18.0318 7.77866 17.7663C6.77866 17.4406 5.98116 17.2683 6.05116 16.7151C6.08616 16.4271 6.48366 16.1323 7.24116 15.8311Z" fill="white"/>
                        </g>
                        <defs>
                            <linearGradient id="paint0_linear_1522_7742" x1="16" y1="0" x2="16" y2="32" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#2AABEE"/>
                                <stop offset="1" stop-color="#229ED9"/>
                            </linearGradient>
                            <clipPath id="clip0_1522_7742">
                                <rect width="32" height="32" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                    <span class="ms-2 fw-lighter">Отправить в Telegram</span>
                </div>
            </div>
        </div>
        <div v-if="invoice.status == 'Отмена' || lastPaymentStatus == 'Отмена'" class="row mb-5 mt-2">
            <!-- <div class='custom-file mb-3 mt-3'>
                <input id='js-file-1' style='cursor: pointer' slot='' @input="addFile" class='image-file js-file custom-file__input' type='file' name='uploadFile'/>
                <label class='custom-file__label' for='js-file-1'>
                    <i class='custom-file__icon'></i>
                    <span class='custom-file__text' style='text-decoration: underline;'>
                        <svg width="26" style="margin-right: 10px" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.3001 14.8569V3.03613M13.3001 14.8569L17.4668 10.9166M13.3001 14.8569L9.13346 10.9166M23.7168 16.827L23.0699 19.2749C22.9573 19.7011 22.6972 20.0795 22.331 20.3499C21.9649 20.6203 21.5137 20.7672 21.0491 20.7672H5.55117C5.0866 20.7672 4.63538 20.6203 4.26922 20.3499C3.90307 20.0795 3.643 19.7011 3.53034 19.2749L2.88346 16.827" stroke="#4AA485" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        {{file == '' ? 'Загрузить чек перевода' : file.name}}</span>
                </label>
            </div> -->
            <div @click="clickSupport" class="col-12 col-sm-6">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1522_7742)">
                        <path d="M16 0C11.7575 0 7.685 1.68675 4.6875 4.68625C1.68691 7.68694 0.000831175 11.7565 0 16C0 20.2417 1.6875 24.3142 4.6875 27.3137C7.685 30.3132 11.7575 32 16 32C20.2425 32 24.315 30.3132 27.3125 27.3137C30.3125 24.3142 32 20.2417 32 16C32 11.7583 30.3125 7.68575 27.3125 4.68625C24.315 1.68675 20.2425 0 16 0Z" fill="url(#paint0_linear_1522_7742)"/>
                        <path d="M7.24116 15.8311C11.9062 13.7991 15.0162 12.4593 16.5712 11.8121C21.0162 9.96381 21.9387 9.64281 22.5412 9.63193C22.6737 9.62981 22.9687 9.66256 23.1612 9.81818C23.3212 9.94943 23.3662 10.1269 23.3887 10.2516C23.4087 10.3761 23.4362 10.6598 23.4137 10.8813C23.1737 13.4113 22.1312 19.5508 21.6012 22.3846C21.3787 23.5836 20.9362 23.9856 20.5087 24.0248C19.5787 24.1103 18.8737 23.4108 17.9737 22.8211C16.5662 21.8978 15.7712 21.3233 14.4037 20.4226C12.8237 19.3816 13.8487 18.8093 14.7487 17.8743C14.9837 17.6296 19.0787 13.9058 19.1562 13.5681C19.1662 13.5258 19.1762 13.3683 19.0812 13.2853C18.9887 13.2021 18.8512 13.2306 18.7512 13.2531C18.6087 13.2851 16.3612 14.7721 12.0012 17.7138C11.3637 18.1523 10.7862 18.3661 10.2662 18.3548C9.69616 18.3426 8.59616 18.0318 7.77866 17.7663C6.77866 17.4406 5.98116 17.2683 6.05116 16.7151C6.08616 16.4271 6.48366 16.1323 7.24116 15.8311Z" fill="white"/>
                    </g>
                    <defs>
                        <linearGradient id="paint0_linear_1522_7742" x1="16" y1="0" x2="16" y2="32" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#2AABEE"/>
                            <stop offset="1" stop-color="#229ED9"/>
                        </linearGradient>
                        <clipPath id="clip0_1522_7742">
                            <rect width="32" height="32" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
                <span class="ms-2 fw-lighter cursor-pointer">Связаться с поддержкой</span>
            </div>
        </div>
        <div v-if="invoice.status == 'Отмена'  || lastPaymentStatus == 'Отмена'" class="row mb-5 mt-2">
            <div class="col-12 col-sm-6">
                <p>Если Вы произвели оплату, но видете статус счета “Не оплачен”, пожалуйста свяжитесь с поддержкой. Ваш платеж будет зачислен</p>
            </div>
        </div>
    </fragment>
</template>

<script>
export default {
    props: ['invoice', 'crypto', 'crypto_id', 'selected_method', "payment", 'payment_status', 'country', 'lastPaymentStatus'],
    data () {
        return {
            selectedPaymentMethod: '',
            crypto_id: 0,
            email: '',
            file: '',
            maxSize: 5242880,
        }
    },
    computed: {
        payment_check: function () {
          let text = "Счет №" + this.invoice.user_id + "-" + this.invoice.id + "" +
              "%0AОписание: " + this.invoice.description + "" +
              "%0AСумма: " + (this.payment.currency_amount ? this.payment.currency_amount : this.payment.proposed_amount) + " " 
              + (this.payment.currency_amount ? this.currency : this.crypto.find(c => c.id == this.payment.crypto_id).code);
          return text;
        },
        currency : function () {
            if(this.crypto_id == 0) {
                if(this.country == 'kz') {
                    return 'KZT'
                } else {
                    if(this.country == 'uz') {
                        return 'UZS'
                    } else {
                        return 'RUB'
                    }
                }
            } else {
                return this.crypto[this.crypto_id].code
            }
        }
    },
    methods: {
        addFile(event){
            if((event.target.files[0].type == 'image/png' || event.target.files[0].type == 'image/jpeg' || event.target.files[0].type == 'application/pdf')
                && event.target.files[0].size < this.maxSize){
                this.file = event.target.files[0];
                let formData = new FormData();
                formData.append("proof", this.file);
                formData.append("token", this.invoice.token);
                formData.append("_method", 'PATCH');
                if(this.selected_method.crypto_id) {

                    axios.post(this.$api_address + '/deposits/' + this.payment.id + '/proof', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                        this.payment = response.data.data;
                        alert('Файл успешно отправлен');
                    });
                } else {
                    axios.post(this.$api_address + "/transactions/" + this.payment.id + '/proof', formData,{
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                        this.payment = response.data.data;
                        alert('Файл успешно отправлен');
                    });
                }
            } else {
                alert('Неверный формат или размер файла превышает 5Мб');
                this.file = '';
            }
        },
        selectPaymentMethod(bank, key) {
            this.selectedPaymentMethod = bank;
            this.crypto_id = key;
            this.$emit('select_method',{selectedPaymentMethod: this.selectedPaymentMethod, crypto_id: this.crypto_id})
        },
        clickTg(){
            window.open('https://telegram.me/share/url?url=' + this.payment_check, '_blank');
        },
        clickWA(){
            window.open('https://wa.me/?text=' +  this.payment_check,'_blank');
        },
        clickSupport(){
            window.open('https://t.me/valutix_bot', '_blank');
        },
        sentMail() {
            if(this.email == '') {
                alert('Введите email для отправки чека');
                return;
            }
            axios.post(this.$api_address + '/checks/send_check', { email: this.email, check_id: this.invoice.outer_id})
                .then(response => (alert('Чек успешно отправлен')));
        },
    }
}
</script>
