<template>
    <div class='row'>
        <div class='col-12 mb-5' id="operation-history-id">
            <div class='o-text-primary u-font-weight-600 mb-3'>История операций</div>
            <div class='d-flex gap-2 mb-3 overflow-auto'>
                <button class="btn flex-shrink-0 u-font-size-14" :class="btn.active ? 'bg_lightgreen text-white' : 'bg-white text-black'" 
                    v-for="btn in filterBtns" @click="setActiveButton(btn)" :key="btn.id">
                    {{btn.name}}
                </button>
            </div>
            <div class='history'>
                <history-item :key="index" v-for="(data, index) in paginatedElements" :data='data' :crypto="crypto" :currencies="currencies"></history-item>
            </div>
            <div class="history_pagen-block">
                <pagination2
                    v-if="filterdPagesCount > 10"
                    v-model="page"
                    :records="filterdPagesCount"
                    @paginate="clickCallback"
                    :per-page="perPage"
                    :options="{
                         chunk: 4,
                         edgeNavigation: true,
                         texts: {
                            first: '1',
                            last: totalPages.toString(),
                         }
                        }"
                    
                />
            </div>
        </div>
    </div>
</template>
<script>
import HistoryItem from './HistoryItemComponent.vue';
import AppSpinner from "./General/AppSpinner.vue";
import Pagination2 from 'vue-pagination-2';
export default {
    props: ['elements', 'crypto', 'currencies'],
    components: {
        HistoryItem,
        AppSpinner,
        Pagination2,

    },
    filters: {
        format: val => `${val}`.replace(/(\d)(?=(\d{4})+([^\d]|$))/g,
            '$1 '),
    },
    data () {
        return {
            page: 1,
            perPage: 10,
            selectedFilter: 'Все',
            filterBtns: [
                {id: 1, name: 'Все', active: true},
                {id: 2, name: 'Покупки', active: false},
                {id: 3, name: 'Продажи', active: false},
                {id: 4, name: 'Выводы', active: false},
                {id: 5, name: 'Депозиты', active: false},
                {id: 6, name: 'Конвертации', active: false},
                // {id: 9, name: 'Наличные', active: false},
                // {id: 8, name: 'Инвойсы', active: false},
                // {id: 7, name: 'Оплаты', active: false},
            ],
        }
    },
    methods: {
        clickCallback (page)  {
            this.page = page
        },
        setActiveButton(selectedBtn) {
            this.filterBtns.forEach(btn => btn.active = false);
            selectedBtn.active = true;
            this.selectedFilter = selectedBtn.name; // Запоминаем активный фильтр
            console.log(this.selectedFilter.toLowerCase().slice(0, -1));
        }
    },
    computed: {
        filteredElements() {
            if (!Array.isArray(this.elements)) return [];
            const list = this.selectedFilter === 'Все'
                ? this.elements
                : this.elements.filter(item =>
                    item.payment_method?.payment_operation?.name
                        .toLowerCase()
                        .includes(this.selectedFilter.toLowerCase().slice(0, -1))
                );

            const mapById = new Map();

            for (const item of list) {
                const id = item.operation.id;
                if (!mapById.has(id)) {
                    mapById.set(id, { ...item });
                } else {
                    const existing = mapById.get(id);
                    existing.balances_from = item.balances;
                }
            }

            return Array.from(mapById.values());
        },
        filterdPagesCount() {
            if (this.selectedFilter === 'Все') {
                return Object.values(this.elements).length;
            }
            return this.filteredElements.length
        },

        paginatedElements() {
            const start = (this.page - 1) * this.perPage;
            const end = start + this.perPage;
            return this.filteredElements.slice(start, end);
        },
        totalPages() {
            return Math.ceil(this.filterdPagesCount / this.perPage);
        },
    },
}
</script>
