<template>
    <div class='col-12 mb-4' id='cardsList'>
        <div class='o-text-primary u-font-weight-600 mb-2'>Банковские карты</div>
        <div class='u-bg-secondary rounded overflow-hidden'>
            <div class='lk__cart'>
                <div class='lk__cart-left'>
                    <card :key='index' v-for='(data, index) in cards.slice(0, 2)' :data='data'></card>
                    <span v-if="cards_count == 0">Добавьте карты, чтобы купить, продать криптовалюту</span>
                    <div v-if="cards_count > 2">
                        <span class='showAllText' data-bs-toggle="modal" data-bs-target="#myCard">
                            Показать все({{ cards_count }})
                        </span>
                    </div>
                </div>
                <button class='lk__cart-right' @click='showAddCard'>
                    <div class='lk__cart-add'>+</div>
                    <span id='addCardButton' class='u-font-size-16'>Добавить карту</span>
                </button>
                <card-list v-show='forModal.isCardListVisible' :cards='cards' @close='closeCardList' @showAddCard="showAddCard"/>
                <add-card v-show='forModal.isAddCardVisible' @close='closeAddCard'/>
            </div>
        </div>
    </div>
</template>

<script>
import Card from './Card.vue';
import CardList from './CardList.vue';
import AddCard from './AddCard.vue';
export default {
    components: {
        AddCard,
        Card,
        CardList,
    },
    props: ['cards', 'cards_count', 'flag'],
    data() {
        return {
            forModal: {
                isCardListVisible: false,
                isAddCardVisible: false
            },
        };
    },

    watch: {
        flag: {
            handler() {
                if (this.flag === true) {
                    this.forModal.isAddCardVisible = true;
                }
            }
        }
    },

    methods: {
        showCardList(modal) {
            this.forModal.isCardListVisible = true;
        },
        closeCardList() {
            this.forModal.isCardListVisible = false;
        },
        showAddCard() {
            if (this.$metrika !== undefined) {
                this.$metrika.reachGoal('add_card');
            }
            this.forModal.isAddCardVisible = true;
            document.body.style.overflow = 'hidden';
        },
        closeAddCard() {
            this.forModal.isAddCardVisible = false;
            document.body.style.overflow = 'auto';
            this.$emit('close');
        },
    },
}
</script>


