<template>
    <transition name="modal-fade">
        <div class="modal fade new_custom_modal" id="buyCryptoModal" aria-hidden="true"
            style="display: block; opacity: 1; background: rgba(29, 28, 43, 0.9)" tabindex="-1">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header d-flex align-items-center justify-content-between flex-wrap">
                        <h5 class="modal-title order-1 fs-4">Купить {{ crypto.currency?.code }}</h5>
                        <button class="btn-close btn-close-white shadow-none order-2 order-md-3 z-index-2" type="button"
                            @click="close"></button>
                    </div>

                    <template v-if="status !== 'Успех' && status !== 'Отмена'">
                        <div class="modal-body">
                            <div class="d-flex flex-wrap gap-lg-5 gap-3 align-items-center mb-5">
                                <div class="input-wrapper">
                                    <h3 class="mb-3 fs-4">Введите сумму покупки</h3>
                                    <div class="input-group mb-1">
                                        <input v-model="amount" id="buy_amount_input" type="number"
                                            class="form-control shadow-none border-0" placeholder="Количество"
                                            aria-label="Сумма депозита">
                                        <span class="input-group-text bg-white border-0"
                                            id="basic-addon2">{{ crypto.currency?.code }}</span>
                                    </div>
                                    <div class="d-flex justify-content-between">
                                        <small :class="amount < minAmount ? 'text-danger fw-bold' : ''">min 10
                                            {{ crypto.currency?.code }}</small>
                                        <small :class="amount > maxAmount ? 'text-danger fw-bold' : ''">max 200 000
                                            {{ crypto.currency?.code }}</small>
                                    </div>
                                </div>
                                <balance-card :crypto="crypto"></balance-card>
                            </div>
                            <h3 class="mb-4 fs-4">Выберите способ покупки</h3>
                            <methods-for-pop-up :methods="methods" :method.sync="method" :crypto="crypto" :action="'Noros покупка'"></methods-for-pop-up>
                        </div>
                        <div class="rounded bg-white p-4 d-flex justify-content-between gap-3 text-dark flex-wrap">
                            <div class="d-flex flex-column gap-lg-5 gap-3 mb-4 mb-md-0">
                                <div>
                                    <h6 class="d-block text-dark fs-5 fw-bold mb-2">Сумма покупки</h6>
                                    <span class="amount fs-3 fw-bold text-break">
                                        {{ amount || 0 }} {{ crypto.currency?.code }}
                                    </span>
                                </div>
                                <div>
                                    <p class="mb-2">Текущий курс: 1 {{ crypto.currency?.code }} = {{ course }}
                                        {{ method?.currency_to?.code }}</p>
                                    <p class="mb-0">Комиссия платежной системы: {{ commission || 0 }}
                                        {{ method?.currency_to?.code }}</p>
                                </div>
                            </div>
                            <div class="d-flex flex-column justify-content-between gap-lg-5 gap-3 button_wrapper">
                                <div>
                                    <h6 class="d-block text-dark fs-5 fw-bold mb-2">Сумма к оплате</h6>
                                    <span class="amount fs-3 fw-bold text-break">
                                        {{ resultAmount }} {{ method?.currency_to?.code }}
                                    </span>

                                </div>
                                <div>
                                    <button class="button mw-100" @click="buy()"
                                        :disabled="!method || !amount || amount < minAmount || amount > maxAmount">
                                        Перейти к оплате
                                    </button>
                                </div>
                            </div>
                        </div>
                    </template>

                    <!-- Успех -->
                    <div class="modal-body" v-if="status == 'Успех'">
                        <div style="text-align: left; margin-bottom: 20px">
                            <span class="me-3" style="font-size: 16px">
                                Покупка {{ finalAmount || amount }} {{ crypto.currency?.code }} произошла успешно
                            </span>
                        </div>
                        <button class="button button--full-width mb-3" @click="close()"
                            v-if="this.$route.name == 'pay-link'">
                            Хорошо
                        </button>
                    </div>

                    <!-- Неудача -->
                    <div class="modal-body" v-if="status == 'Отмена'">
                        <div style="text-align: left; margin-bottom: 20px">
                            <span class="me-3" style="font-size: 16px">
                                Покупка {{ amount }} {{ crypto.currency?.code }} завершилась неудачей
                            </span>
                        </div>
                        <button class="button button--full-width mb-3" @click="close()"
                            v-if="this.$route.name == 'pay-link'">
                            Хорошо
                        </button>
                    </div>

                    <div class="position-fixed w-100 h-100 d-flex justify-content-center align-items-center bg-white bg-opacity-50" v-if="loading">
                        <div class="spinner-border text-success" role="status">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                    </div>

                </div>
            </div>
        </div>
    </transition>
</template>
<script>
import { mapState, mapActions } from 'pinia';
import { useTransactionStore } from '@/stores/useTransactionStore';
import MethodsForPopUp from "../MethodsForPopUp.vue";
import CircleProgress from "../CircleProgress.vue";
import BalanceCard from "../BalanceCard.vue";

export default {
    components: { MethodsForPopUp, CircleProgress, BalanceCard },
    props: ["crypto", "courses_list", 'methods', 'user'],
    data() {
        return {
            amount: null,
            status: "",
            method: false,
            loading: false,
            minAmount: 10,
            finalAmount: 0
        };
    },
    computed: {
        ...mapState(useTransactionStore, ['link', 'transactionId']),

        fixedNumber() {
            return this.method?.currency_to?.delimiter
                ? (this.method.currency_to.delimiter.toString().split('0').length - 1) || 0
                : 0;
        },

        maxAmount() {
                return this.user?.status?.title === 'Верифицированный' ? 200000 : 100;
        },

        course() {
            let myCourse = this.courses_list?.find(item =>
                item.pair === `${this.method?.currency_from?.code}-${this.method?.currency_to?.code}`
            );
            return (myCourse?.rate / myCourse?.parser?.currency_to?.delimiter) || 0
        },

        commission() {
            const commission = +(this.method?.commission) || 0;
            const fiatAmount = this.fiat_amount || 0;
            const delimiter = parseFloat(this.method?.currency_to?.delimiter) || 1;
            return parseInt((commission * fiatAmount) * delimiter) / delimiter;
        },

        fiat_amount() {
            return (this.amount * this.course) || 0;
        },

        resultAmount() {
            const delimiter = this.method?.currency_to?.delimiter || 1;
            return (parseInt(this.amount * (1 + Number(this.method?.commission)) * this.course * delimiter) / delimiter) || 0;
        },
    },
    watch: {
        amount() {
            if (!this.amount) return
            if (this.amount > this.maxAmount) this.amount = this.maxAmount;
            if (this.amount < 0) this.amount = 0;
        },
    },
    methods: {
        ...mapActions(useTransactionStore, ['fetchTransaction']),

        close() {
            this.$emit("close");
            this.$emit("close_pay_links");
            this.amount = null;
            this.status = "";
            this.method = false;
            this.loading = false;
            this.finalAmount = 0;
        },

        // putMethod(data) {
        //     this.method = data.data;
        // },
        
        async buy() {
            this.loading = true;
            await this.fetchTransaction(this.method.id, this.amount);
            if(this.link && this.link !== '') {
                window.open(this.link);
            }
            else {
                setTimeout(() => (this.loading = false), 1000);
            }
        }
    },
    created() {
        eventBus.$on('transactionUpdate', (pusherInfo) => {
            console.log(pusherInfo.id, this.transactionId);
            if (this.transactionId == pusherInfo.id) {
                this.loading = false;
                this.status = pusherInfo.status;
                this.finalAmount = pusherInfo.amount;
            }
        });
    },

};
</script>

<style scoped>
.custom-file__text {
    color: #4AA485;
    text-decoration: underline;
    text-underline-offset: 4px;
    display: flex;
    align-items: flex-end;
    gap: 4px;
}

.custom-file__text span {
    width: 270px;
    text-overflow: ellipsis;
    overflow: hidden;
    text-wrap: nowrap;
}

.method_card {
    width: 240px;
    height: 125px;
    background-color: #fff;
    color: #000;
}

@media (max-width: 428px) {
    .input-wrapper {
        width: 100%;
    }

    .balance_card {
        max-width: 100%;
    }

    .button,
    .button_wrapper {
        max-width: unset;
        width: 100%;
    }
}
</style>
