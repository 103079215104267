var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mb-5",attrs:{"id":"operation-history-id"}},[_c('div',{staticClass:"o-text-primary u-font-weight-600 mb-3"},[_vm._v("История операций")]),_c('div',{staticClass:"d-flex gap-2 mb-3 overflow-auto"},_vm._l((_vm.filterBtns),function(btn){return _c('button',{key:btn.id,staticClass:"btn flex-shrink-0 u-font-size-14",class:btn.active ? 'bg_lightgreen text-white' : 'bg-white text-black',on:{"click":function($event){return _vm.setActiveButton(btn)}}},[_vm._v(" "+_vm._s(btn.name)+" ")])}),0),_c('div',{staticClass:"history"},_vm._l((_vm.paginatedElements),function(data,index){return _c('history-item',{key:index,attrs:{"data":data,"crypto":_vm.crypto,"currencies":_vm.currencies}})}),1),_c('div',{staticClass:"history_pagen-block"},[(_vm.filterdPagesCount > 10)?_c('pagination2',{attrs:{"records":_vm.filterdPagesCount,"per-page":_vm.perPage,"options":{
                     chunk: 4,
                     edgeNavigation: true,
                     texts: {
                        first: '1',
                        last: _vm.totalPages.toString(),
                     }
                    }},on:{"paginate":_vm.clickCallback},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }