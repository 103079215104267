<template>
    <fragment>
        <div class="rounded border_darkgreen overflow-hidden w-100">
            <div class='lk__asset-wrapper'>
                <!-- Балансы -->
                <div class='lk__asset'>
                    <img class='me-3' :src="'/static/img/ico/ico-' + crypto.currency?.name?.toLowerCase() + '--color.png'" width='32px' />
                    <div class="lk__asset-block text-break">
                        <span class="d-flex gap-2 xs-wrap">
                            <span>{{ parseFloat(myValue).toFixed(fixedNumber) }}</span> <span>{{ crypto.currency?.code }}</span></span>
                        <div class="d-flex flex-column flex-sm-row align-items-sm-center flex-wrap">
                            <span :class="['underheader_text me-2']">(${{ cryptoCourse.toFixed(2) }})</span>
                            <!-- <span> Курс {{ myCourse }} </span>
                            <span> Делиметр {{ course?.parser?.currency_to?.delimiter }} </span> -->
                            <!-- <pre>{{ course }}</pre> -->
                            <div :class="['d-flex align-items-center gap-1', frozen_myValue ? '' : 'opacity-0']">
                                <img src="/img/lock.svg" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Заморожено для операций">
                                <span class="fw-light fs-6">{{ frozen_myValue }} {{ crypto.currency?.code }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Кнопка операций -->
                <button class="lk_operations" type="button" data-bs-toggle="collapse"
                    :data-bs-target="'#collapse-' + crypto.id" aria-expanded="false"
                    :aria-controls="'collapse-' + crypto.id">
                    <img width="30" height="31" src="/static/img/operation-arrow.svg" alt="arrow">
                    <span>Операции</span>
                </button>
                <buy-crypto v-if="crypto.currency?.code === 'USDT'" v-show='isBuyVisible'
                    @new_card="newCard" @buy='buyTether' @close='closeBuy' 
                    :for_buy="for_buy.crypto_id == crypto.id ? for_buy : false" :user="user" :cards="cards"
                    :courses="courses" :crypto="crypto" :courses_list="courses_list" :bank_list="bank_list"
                    :currencies="currencies" :methods="methods" :balances="balances"/>
                <sale-crypto @showVerification="showVerification"
                    v-if="crypto.currency?.code === 'USDT'" v-show='isSaleVisible'
                    @new_card="newCard" @sale="saleTether" @close='closeSale' :user="user" :cards="cards" :balance="myValue"
                    :courses="courses" :crypto="crypto" :courses_list="courses_list" :bank_list="bank_list"
                    :currencies="currencies" :methods="methods" :balances="balances"/>
                <conclusion-crypto @showVerification="showVerification" v-show='isConcVisible' @new_wallet="newWallet"
                    @concl="conclTether" :user="user" @close='closeConclusion' :wallets="wallets" :balance="myValue"
                    :courses="courses" :crypto="crypto" :methods="methods"/>
                <deposit-crypto v-show='isDepositVisible' @deposit="deposit" @close='closeDeposit'
                    :for_deposit="for_deposit.crypto_id == crypto.id ? for_deposit : false" :balance="myValue"
                    :courses="courses" :crypto="crypto" :methods="methods" :course="course" :myCourse="myCourse"/>
            </div>
            <div class='collapse' :id="'collapse-' + crypto.id">
                <div class="lk__buttons-block">
                    <!-- Купить -->
                    <button v-if="crypto.currency?.code == 'USDT'" @click='showBuy'>
                        <img width="23" height="23" src="/static/img/crypto-elements/buy-new.svg"
                            :alt="`Покупка ${crypto.currency.name || 'криптовалюты'}`">
                        <span>Купить</span>
                    </button>
                    <!-- Продать -->
                    <button v-if="crypto.currency?.code == 'USDT'" @click='showSale'>
                        <img width="23" height="23" src="/static/img/crypto-elements/sell-new.svg"
                            :alt="`Продажа ${crypto.currency.name || 'криптовалюты'}`">
                        <span>Продать</span>
                    </button>
                    <!-- Депозит -->
                    <button @click='showDeposit'>
                        <img width="23" height="23" src="/static/img/crypto-elements/deposit-crypto.svg"
                            :alt="`Депозит для ${crypto.currency.name || 'криптовалюты'}`">
                        <span>Депозит</span>
                    </button>
                    <!-- Вывод -->
                    <button @click="showConclusion">
                        <img width="23" height="23" src="/static/img/crypto-elements/withdrawal-crypto.svg"
                            :alt="`Вывод ${crypto.currency.name || 'криптовалюты'}`">
                        <span>Вывод</span>
                    </button>
                </div>
            </div>
        </div>
    </fragment>
</template>
<script>
import BuyCrypto from './BuyCrypto.vue';
import SaleCrypto from './SaleCrypto.vue';
import ConclusionCrypto from "./ConclusionCrypto.vue";
import DepositCrypto from "./DepositCrypto.vue";
import AppSpinner from "../General/AppSpinner.vue";
export default {
    props: ['courses', 'crypto', 'balances', 'cards', 'wallets', 'user_load', 'user', 'courses_list', 'for_buy', 'for_deposit', "bank_list", "currencies", "methods"],
    components: {
        BuyCrypto,
        SaleCrypto,
        ConclusionCrypto,
        DepositCrypto,
        AppSpinner,
    },
    data() {
        return {
            isBuyVisible: false,
            isSaleVisible: false,
            isConcVisible: false,
            isDepositVisible: false,
            isOperVisible: false,
        }
    },
    computed: {
        fixedNumber() {
            return String(this.crypto.currency.delimiter).split('0').length - 1;
        },
        course() {
            return this.courses_list?.find(c => c.pair == `${this.crypto.currency?.code}-USDT`);
        },
        myCourse() {
            return this.crypto?.currency?.code === 'USDT' ? 1 : this.course?.rate;
        },
        cryptoCourse() {
            return this.crypto?.currency?.code === 'USDT' ? this.myValue : Number(this.myValue * (this.myCourse / this.course?.parser?.currency_from?.delimiter) / this.course?.parser?.currency_from?.delimiter);
        },
        myValue() {
            return (this.crypto.value / this.crypto?.currency?.delimiter) || 0;
        },
        frozen_myValue() {
            return (this.crypto.blocked_value / this.crypto?.currency?.delimiter) || 0;
        },
    },
    methods: {

        showVerification() {
            this.$emit('showVerification');
        },
        showOperations() {
            if (this.isOperVisible == true) {
                this.isOperVisible = false;
            } else {
                this.isOperVisible = true;
            }
        },
        newCard() {
            this.$emit('new_card');
        },
        newWallet() {
            this.$emit('new_wallet');
        },
        buyTether(data) {
            this.$emit('buy', data);
        },
        saleTether(data) {
            this.$emit('sale', data);
        },
        conclTether(data) {
            this.$emit('concl', data);
        },
        deposit() {
            this.$emit('deposit');
        },
        showBuy() {
            if (this.$metrika !== undefined) {
                this.$metrika.reachGoal(this.crypto.currency?.code.toLowerCase() + '_buy');
            }
            this.isBuyVisible = true;
            document.body.style.overflow = 'hidden';
        },
        closeBuy() {
            this.isBuyVisible = false;
            document.body.style.overflow = 'auto';
        },
        showSale() {
            if (this.$metrika !== undefined) {
                this.$metrika.reachGoal(this.crypto.currency?.code.toLowerCase() + '_sell');
            }
            this.isSaleVisible = true;
            document.body.style.overflow = 'hidden';
        },
        closeSale() {
            this.isSaleVisible = false;
            document.body.style.overflow = 'auto';
        },
        showConclusion() {
            if (this.$metrika !== undefined) {
                this.$metrika.reachGoal(this.crypto.currency?.code.toLowerCase() + '_withdrawal');
            }
            this.isConcVisible = true;
            document.body.style.overflow = 'hidden';
        },
        closeConclusion() {
            this.isConcVisible = false;
            document.body.style.overflow = 'auto';
        },
        showDeposit() {
            if (this.$metrika !== undefined) {
                this.$metrika.reachGoal(this.crypto.currency?.code.toLowerCase() + '_deposit');
            }
            this.isDepositVisible = true;
            document.body.style.overflow = 'hidden';
        },
        closeDeposit() {
            this.isDepositVisible = false;
            document.body.style.overflow = 'auto';
        },
        listenChange(data) {
            this.amount = data;
            this.$emit('buy1');
        },
    },
}
</script>
<style scoped>
@media (max-width: 340px) {
    .xs-wrap {
        flex-direction: column;
        gap: 0 !important;
    }
}

</style>
