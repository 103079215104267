<template>
        <div class="modalCards mb-3">
            <div @click="putMethod(data)" v-for="(data, index) in action_methods" :key="index" :id="data.name"
            :class="['rounded cursor-pointer method_card desktop', data == method ? 'active' : 'bg-white']">
                <div
                    class="bg-transparent d-flex flex-row flex-md-column gap-2 align-items-center justify-content-center p-3 h-100">
                    <img class="object-fit-contain custom-modal__select-card-ico" :src="data.logo" width="32px" v-if="data.logo" />
                    <span :class="data == method ? 'text-white' : 'text-dark'" class="fs-6 text-dark" v-if="data.name">{{ data.name }}</span>
                </div>
            </div>

                <div class="rounded cursor-pointer method_card active mobile" @click="selector = !selector">
                    <div class="bg-transparent d-flex gap-2 align-items-center justify-content-center p-3 h-100">
                        <img class="object-fit-contain custom-modal__select-card-ico" :src="method?.logo" width="32px"
                            v-if="method?.logo" />
                        <span class="fs-6 text-dark text-white" v-if="method?.name">{{ method?.name }}</span>
                        <img src="/img/selector.svg" class="selector" :class="selector ? 'reverse' : ''">
                    </div>
                </div>
                <template v-if="selector">
                    <div @click="putMethod(data)" v-for="(data, index) in action_methods.filter(m => m.id !== method?.id)" :key="index"
                        :class="['rounded cursor-pointer method_card mobile', data == method ? 'active' : 'bg-white']">
                        <div
                            class="bg-transparent d-flex flex-row flex-md-column gap-2 align-items-center justify-content-center p-3 h-100">
                            <img class="object-fit-contain custom-modal__select-card-ico" :src="data.logo" width="32px" v-if="data.logo" />
                            <span :class="data == method ? 'text-white' : 'text-dark'" class="fs-6 text-dark" v-if="data.name">{{ data.name }}</span>
                        </div>
                    </div>
                </template>
        </div>
</template>

<script>
export default {
    props: ['methods', 'parentMethod', 'crypto', 'action', 'method'],
    data() {
        return {
            // method: '',
            selector: false
        }
    },
    methods: {
        putMethod(data) {
            // this.method = data;
            // this.$emit('putMethod', { data });
            // console.log('data', data);
            this.$emit("update:method", data);
        },
        initMethodIfNeeded(methods) {
            if (!this.method && methods.length) {
                this.putMethod(methods[0]);
            }
        },

    },
    computed: {
        action_methods() {
            return this.methods?.filter(
            (item) =>
                item.payment_operation?.name === this.action &&
                item.currency_from?.code === this.crypto?.currency?.code
            ) || [];
        },
    },
    watch: {
        action_methods(newVal) {
            this.initMethodIfNeeded(newVal);
        },
        method: {
            immediate: true,
            handler() {
                this.initMethodIfNeeded(this.action_methods);
            }
        }
    }
    
}
</script>

<style scoped>
.modalCards {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(4, 1fr);
}

.active {
    background-color: #4AA485;
}

.method_card {
    min-height: 125px;
}

.mobile {
    display: none;
}

@media(max-width:992px) {
    .modalCards {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media(max-width:428px) {
    .modalCards {
        grid-template-columns: repeat(1, 1fr);
    }

    .method_card {
        min-height: unset;
    }

    .mobile {
        display: block;
    }

    .desktop {
        display: none;
    }

    .selector {
        display: block;
    }

    .selector.reverse {
        transform: rotate(180deg);
    }
}
</style>
