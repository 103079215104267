<template>
    <transition name='modal-fade'>
    <div class="modal fade new_custom_modal" style='display: block; opacity: 1; background: rgba(29, 28, 43, 0.9);' id="sale" aria-hidden="true" tabindex="-1">
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Вывод {{crypto.currency?.code}}</h5>
                    <button class="btn-close btn-close-white shadow-none" type="button" data-bs-dismiss="modal" @click="close" aria-label="Close"></button>
                </div>
                <div class="modal-body" v-if="step == 1">
                    <div class="custom-modal__text mb-2 text-start">Куда вывести средства?</div>
                    <div class='custom-modal__select-card mb-4'>
                        <wallet-for-pop-up :wallets="wallets" :crypto="crypto" @new_wallet="newWallet" :method.sync="method"></wallet-for-pop-up>
                        <span v-if="wallets.length == 0">Добавьте кошельки, чтобы совершить вывод</span>
                    </div>
                    <div class="custom-modal__wrapper mb-sm-2 mb-4 align-items-center">
                        <div class="custom-modal__wrapper-left custom-modal__text text-start">Сколько вывести? {{crypto.currency?.code}}</div>
                        <div class="custom-modal__wrapper-right">
                            <div class="input-group">
                                <input type="number" class="form-control form-control-lg border-0 shadow-none" @keyup='upd' v-model="form.amount" :aria-label="`Сумма вывода ${crypto.currency?.code}`" id="crypto1_amount_conclusion">
                                <button @click="saleAll" class="input-group-text bg-white border-0 convAllBtn" v-if="this.form.amount != (this.balance - this.commission)">
                                    Вывести все
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="custom-modal__wrapper mb-sm-2 mb-4">
                        <div class="custom-modal__wrapper-left custom-modal__text text-start u-font-weight-600">Текущий баланс:</div>
                        <div class="custom-modal__wrapper-right u-font-size-14"><span>{{ balance }} {{crypto.currency?.code}}</span></div>
                    </div>
                    <div class="custom-modal__wrapper mb-sm-2 mb-4">
                        <div class="custom-modal__wrapper-left custom-modal__text text-start u-font-weight-600">Комиссия сети:</div>
                        <div class="custom-modal__wrapper-right u-font-size-14 u-font-weight-600"><span>{{commission}} {{crypto.currency?.code}}</span></div>
                    </div>
                    <div class="custom-modal__wrapper mb-sm-2 mb-4">
                        <div class="custom-modal__wrapper-left custom-modal__text text-start">Остаток баланса:</div>
                        <div class="custom-modal__wrapper-right u-font-size-14 u-font-weight-600">
                            <span>{{ form.ost || balance}} {{crypto.currency?.code}}</span>
                        </div>
                    </div>
                    <div class="custom-modal__wrapper">
                        <div class="custom-modal__wrapper-left custom-modal__text text-start"></div>
                        <div v-if="user?.status?.id == 3" class="custom-modal__wrapper-right u-font-size-14 u-font-weight-600">
                            <button @click="secondStep" class="button button--full-width mb-3" :disabled="form.amount == 0 || !form.amount || !conclusion_method" >Отправить заявку на вывод</button>
                        </div>
                        <div v-else class="custom-modal__wrapper-right u-font-size-14 u-font-weight-600">
                            <span style="color: rgb(172, 175, 255);">
                                Пройдите <span class="cursor-pointer text-light" @click="showVerification">верификацию</span>, чтобы совершать выводы
                            </span>
                        </div>
                    </div>
                    <div class="custom-modal__wrapper mb-sm-2 mb-4">
                        <p style="font-size: 12px;margin-top: 10px;">Заявка будет обработана в течение 15 минут. Если средства не придут, обратитесь
                            в поддержку. Наши операторы работают 24/7. Время ответа — до 1 часа.</p>
                    </div>
                </div>
                <div class='modal-body' v-else>
                    <div style='text-align: left; margin-bottom: 20px'>
                        <span class='me-3' style='font-size: 16px'>Ваша заявка на вывод {{this.form.amount}} {{crypto.currency?.code}} отправлена</span>
                    </div>
                    <button class='button button--full-width mb-3' @click='lastStep()'>Новый вывод</button>
                </div>
                <div class="position-fixed w-100 h-100 d-flex justify-content-center align-items-center bg-white bg-opacity-50" v-if="loading">
                    <div class="spinner-border text-success" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                </div>
            </div>
        </div>
    </div>
    </transition>
</template>

<script>
import { method } from "lodash";
import WalletForPopUp from "../Wallets/WalletForPopUp.vue";

export default {
    components: {WalletForPopUp},
    props: ['balance', 'wallets', 'courses', 'crypto', 'user', 'methods'],
    data() {
        return {
            form : {
                bank: '',
                amount: null,
                file: '',
                ost: null,
                giv: 0,
            },
            step: 1,
            loading: false,
            method: false
        }
    },
    computed: {
        conclusion_method() {
            return this.methods?.find(
                (item) => item.payment_operation?.name === "Вывод" && item.currency_from?.code === this.crypto?.currency?.code
            );
        },
        fixedNumber() {
            return String(this.crypto.currency.delimiter).split('0').length - 1 || 0;
        },
        commission() {
            return Number(this.method?.cryptonetwork?.commission / this.method?.cryptonetwork?.cryptocurrency?.delimiter) || 0;
        },
    },
    watch: {
        'form.amount'(newVal) {
            if (!newVal) return;
            if (newVal > this.balance - this.commission) this.form.amount = (this.balance - this.commission);
            if (newVal < 0) this.form.amount = 0;
        },
        method(newVal) {
            if (newVal !== false) {
                this.upd();
            }
        }
    },
    methods: {
        setErrors(response) {
            const errors = response?.data?.errors || {};
            let errorData;
            for (const field in errors) {
                errorData = errors[field][0]; // сохраняем только первую ошибку
            }
            eventBus.$emit('errorBus', errorData);
        },
        showVerification() {
                this.$emit('showVerification');
            },
        saleAll() {
            this.form.amount = this.balance - this.commission;
            this.upd();
        },
        close(event) {
            this.$emit('close');
            setTimeout(() => (Object.assign(this, this.$options.data())), 500);
        },
        newWallet(){
            this.$emit('new_wallet');
        },
        conclusion(data) {
            this.$emit('concl', data);
        },
        upd() {
            // setTimeout(() => {
                // let amount = parseFloat(this.form.amount);
                // this.form.amount = parseFloat(amount);
                console.log(this.form.amount);
                console.log(this.commission);
                console.log(this.fixedNumber);
                if(!this.form.amount) 
                {
                    this.form.ost = this.balance.toFixed(this.fixedNumber);
                }
                else {
                    this.form.ost = (this.balance - ((parseFloat(this.form.amount)) + this.commission)).toFixed(this.fixedNumber);
                }
            // }, 1000);
        },
        firstStep() {
            this.step = 2;
        },
        secondStep() {
            this.loading = true;
            if(!this.conclusion_method?.id) {
                eventBus.$emit('errorBus', 'Отсутствует подходящий метод вывода');
                this.loading = false;
                return
            }
            let data = {
                amount: parseFloat(this.form.amount),
                cryptocurrency_id: this.crypto.currency.id,
                wallet_id: this.method.id,
                payment_method_id: this.conclusion_method?.id
            }
            axios.post(this.$api_address +'/operations/withdrawals', data, {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem('user_token')}`,
                },
            })
            .then(response => {
                    this.conclusion(response.data);
                    this.step = 2;
            }).catch(error => {
                if (error.response?.status !== 401) {
                    this.setErrors(error.response);
                }
                else {
                    eventBus.$emit('logout');
                }
            })
            .finally(() => {
                setTimeout(() => (this.loading = false), 10);
            });
        },
        lastStep() {
            Object.assign(this, this.$options.data());
        },

    }
}
   
</script>
