<template>
  <div class="rounded p-3 d-flex flex-column justify-content-between balance_card">
    <h6 class="mb-1 fw-normal d-block">Доступный баланс</h6>
    <b class="fs-5">{{ balance }} {{ crypto.currency?.code }}</b>
</div>
</template>

<script>
export default {
    props: ['crypto'],
    computed: {
      balance() {
        return parseFloat(this.crypto.value / this.crypto.currency.delimiter).toFixed(this.fixedNumber);
      },
      fixedNumber() {
          return String(this.crypto.currency.delimiter).split('0').length - 1;
      },
    }
  }
</script>

<style scoped>
.balance_card {
  max-width: 230px;
  width: 100%;
  height: 110px;
  background-color: #212121;
  background-image: url(/public/static/img/balance_gradient.png);
  background-size: cover;
  background-position: center;
}
</style>